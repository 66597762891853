import React, { Component } from "react";
import { getAllNews, getContentPage, getHighperformance } from "../../data/api";
import AppHighperformanceNews from "../../components/module/app-highperformance-news/AppHighperformanceNews";
import AppHighperformanceLinks from "../../components/module/app-highperformance-links/AppHighperformanceLinks";
import AppGewinnerreiseWertung from "../../components/module/app-gewinnerreise-wertung/AppGewinnerreiseWertung";
import AppGewinnerreiseRanking from "../../components/module/app-gewinnerreise-ranking/AppGewinnerreiseRanking";
import AppGewinnerreisePerformance
  from "../../components/module/app-gewinnerreise-performance/AppGewinnerreisePerformance";
import AppGewinnerreiseHeader from "../../components/module/app-gewinnerreise-header/AppGewinnerreiseHeader";
import axios from "axios";
import { AppContext } from "../../data/ContextProvider";
import { CONTENT_SPIELREGELN } from "../../data/constants";
import t from "../../helpers/t9n";

class Gewinnerreise extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isHeaderOpen: true,
      loading: true,
    }
  }

  componentDidMount() {
    axios.all([getHighperformance(), getAllNews(), getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)])
      .then(axios.spread((highperf, news, page) => {

        this.context.setCurrentPlace(highperf.current_place)

        this.setState({
          data: highperf,
          text: page.text,
          news: news.news,
          loading: false
        })
      }))
  }

  handleHeader = () => {
    this.setState({
      isHeaderOpen: !this.state.isHeaderOpen
    })
  }

  render() {

    if (this.state.loading)
      return t("gloabl.loading")

    const isRanking = this.props.view === "ranking" || this.props.view === "wertung"

    return (
      <>
        <div className={"content app gewinnerreise"}>

          <AppGewinnerreiseHeader handleHeader={this.handleHeader} isHeaderOpen={this.state.isHeaderOpen}
                                  text={this.state.text}
                                  isRanking={isRanking} />


          <AppGewinnerreisePerformance handleHeader={this.handleHeader} isHeaderOpen={this.state.isHeaderOpen}
                                       performance={this.state.data} />

          {this.props.view === "ranking" &&
          <AppGewinnerreiseRanking data={this.state.data}
                                   isPointsMode={this.state.data.performance_mode === "points"} />
          }

          {/*{this.props.view === "wertung" &&*/}
          {/*<AppGewinnerreiseWertung data={this.state.data.evaluation_criteria} />*/}
          {/*}*/}

          {this.props.view === "home" &&
          <AppHighperformanceNews data={this.state.news} />
          }
          <AppHighperformanceLinks data={this.state.news} />

        </div>
      </>
    );
  }
}

Gewinnerreise.contextType = AppContext

export default Gewinnerreise;
