import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getAssetSlider } from "../../../data/api";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";

class AppPraemienClubHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      headerSlider: []
    }
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading)
      return "loading.."

    const text = this.props.text

    return (
      <>
        {this.state.headerSlider.length > 0 &&
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        }
        
        <div
          className={`app-header__section praemienclub-header__section
          ${this.props.isHeaderOpen ? "app-header__section--open" : ""}
          ${this.props.isRanking ? "praemienclub-header__section--ranking" : ""}`}>
          <video loop autoPlay>
            <source
              src={`${process.env.PUBLIC_URL}/videos/PraemienshopClub/anim--praemien-club-bg.mp4`}
              type="video/mp4" />Your browser does not support the video tag. Please upgrade your browser.
          </video>
          <div className={"app-header__content"}>
            <div className={"app-header__left"}>
              <span className={"app-header__heading"}>
                <div>
                  <span>
                    <Icon icon={"app-praemienshopclub"} />
                  </span>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: text.p33_header_content_headline
                    }}>
                  </h1>
                  {/*<h1>{text.p33_header_content_headline}</h1>*/}
                </div>
                <h2>{text.p33_header_content_subline}</h2>
              </span>

              <p
                dangerouslySetInnerHTML={{
                  __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                }}>
              </p>

              <span className={"app-header__actions"}>
                {this.props.isHeaderOpen ?
                  <Button type={"link"} text={"Infos schließen"} textColor={"white"} icon={"arrow"}
                          onClick={() => this.props.handleHeader()} />
                  :
                  <Button type={"link"} text={"Weitere Infos"} textColor={"white"} icon={"arrow"}
                          onClick={() => this.props.handleHeader()} />
                }
                <Button text={"Die Spielregeln"} backgroundColor={"bg-white"} textColor={"black"} type={"primary"}
                        isClubButton={true}
                        onClick={() => {
                          this.props.history.push(`/${window.currentLang}/rules`)
                        }} />
              </span>
            </div>

          </div>
        </div>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}

AppPraemienClubHeader.propTypes = {
  handleHeader: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
};
AppPraemienClubHeader.defaultProps = {
  isHeaderOpen: false,
};

AppPraemienClubHeader.contextType = AppContext

export default withRouter(AppPraemienClubHeader);
