import React, { Component } from "react";

import { AppContext } from "../../data/ContextProvider";

import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import CardSlider from "../../components/module/card-slider/CardSlider";
import AppNeukundenHeader from "./AppNeukundenHeader";
import AppNeukundenPerformance from "./AppNeukundenPerformance";

import { getContentPage, getNewcontractsRankings } from "../../data/api";
import { CONTENT_SPIELREGELN } from "../../data/constants";

import t from "../../helpers/t9n";

class Neukunden extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      isHeaderOpen: true
    }
  }

  async componentDidMount() {
    try {
      const rankings = await getNewcontractsRankings()
      const page = await getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)

      this.setState({
        data: rankings.performance,
        pageText: page.text,
        loading: false
      })
    } catch (error) {
      console.log(error)
    }
  }


  handleHeader = () => {
    this.setState({
      isHeaderOpen: !this.state.isHeaderOpen
    })
  }

  render() {

    if (this.state.loading) {
      return t("global.loading")
    }

    return (
      <>
        <div className={"content app neukundenjaeger"}>

          <AppNeukundenHeader text={this.state.pageText} handleHeader={this.handleHeader}
                              isHeaderOpen={this.state.isHeaderOpen} />
          <AppNeukundenPerformance handleHeader={this.handleHeader} isHeaderOpen={this.state.isHeaderOpen}
                                   performance={this.state.data} />
          <SectionRecommendations label={t("app.award.highlights")} />

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>
        </div>
      </>
    );
  }
}

Neukunden.contextType = AppContext
export default Neukunden;
