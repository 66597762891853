import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SectionPromise from "../../../pages/TeamTrophy/SectionPromise";
import SectionCountdown from "../../../pages/TeamTrophy/SectionCountdown";
import CardSlider from "../card-slider/CardSlider";
import t from "../../../helpers/t9n";

class AppTeamTrophyHome extends Component {

	constructor(props) {
		super(props);
		this.state = {

		}
	}

	render() {
		return (
			<>
				<SectionPromise
					end={this.props.end}
					bonus={this.props.bonus}
				/>
				<SectionCountdown
					start={this.props.start}
					end={this.props.end}
				/>
				<div className={"section__card-slider"}>
					<CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
				</div>
			</>
		);
	}
}

export default withRouter(AppTeamTrophyHome);
