import React, { Component } from "react";

import Textfield from "../../../components/atom/textfield/Textfield";
import Selectfield from "../../../components/atom/selectfield/Selectfield";
import TextfieldGroup from "../../../components/atom/textfield-group/TextfieldGroup";
import Button from "../../../components/atom/button/Button";
import ShippingBox from "../../../components/element/shipping-box/ShippingBox";
import {
  getAddresses,
  updateAddress,
  loadCurrentUser,
  updateUser,
} from "../../../data/api";
import t, { countriesOptions } from "../../../helpers/t9n";

class Shipping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addresses: [],
      deliveryAddress: null,
      isAddressEditMode: false,
    };
  }

  toggleAddressEditMode = () => {
    this.setState({ isAddressEditMode: !this.state.isAddressEditMode });
  };

  handleUpdateProfile = async (user) => {
    try {
      await updateUser(user);
      this.loadData();
    } catch (error) {
      console.log(error);
    }
  };

  doUpdateAddress = async (address) => {
    try {
      await updateAddress(address.id, address);
      this.loadData();
    } catch (error) {
      console.log(error);
    }
  };

  handleUpdateAddress = () => {
    const address = this.state.deliveryAddress;
    // user object
    if (address.personnel_number) {
      this.handleUpdateProfile(address);
    } else {
      this.doUpdateAddress(address);
    }
  };

  handleChooseAddress = (address) => {
    this.setState({ deliveryAddress: address });
  };

  //final accept address for shipping
  handleSelectDeliveryAddress = () => {
    this.props.handleAddress(this.state.deliveryAddress);
    this.props.stepFunc(2);
  };

  handleChange = (e) => {
    if (e.target) {
      const deliveryAddress = this.state.deliveryAddress;
      deliveryAddress[e.target.name] = e.target.value;
      this.setState({ deliveryAddress });
    }
  };

  handleAddressButtonAction = (e) => {
    e.preventDefault();

    if (this.state.isAddressEditMode) {
      this.handleUpdateAddress();
    } else {
      this.toggleAddressEditMode();
    }
  };

  loadData = async () => {
    const user = await loadCurrentUser();
    const addresses = await getAddresses();

    addresses.unshift(user.user);

    this.setState({
      isAddressEditMode: false,
      deliveryAddress: addresses[0],
      addresses: addresses,
      user: user.user,
      loading: false,
    });
  };

  async componentDidMount() {
    this.loadData();
  }

  render() {
    const isAddressEditMode = this.state.isAddressEditMode;

    if (this.state.loading) {
      return "loading";
    } else {

      const countries = countriesOptions()
      const selectedCountry = countries[this.state.deliveryAddress.country_code || "DE"]

      return (
        <>
          <div
            className={`checkout__right checkout__right--shipping ${
              isAddressEditMode ? "checkout__left--focus" : ""
            }`}
          >
            <ShippingBox
              addresses={this.state.addresses}
              handleSelectAddress={this.handleChooseAddress}
              handleAddress={this.handleSelectDeliveryAddress}
            />
          </div>

          <div
            className={`checkout__left checkout__left--shipping ${
              isAddressEditMode ? "checkout__left--focus" : ""
            }`}
          >
            <div className="checkout__form">
              <span className="checkout__form-header">
                {t("checkout.form.adress")}
              </span>
              <div className="login__columns">
                <form
                  name="registration-form"
                  onSubmit={(e) => this.handleAddressButtonAction(e)}
                >
                  <div className="login__row">
                    <div className="login__col">
                      <Selectfield
                        label={t("app.salutation")}
                        options={{
                          herr: t("app.mr"),
                          frau: t("app.ms"),
                        }}
                        name="salutation"
                        isRequired={true}
                        placeholder={t("app.salutation")}
                        defaultValue={
                          this.state.deliveryAddress.salutation
                            ? this.state.deliveryAddress.salutation.toLowerCase()
                            : "herr"
                        }
                        state={`${isAddressEditMode ? "active" : "inactive"}`}
                        onSelect={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield
                        label={t("app.firstname")}
                        isRequired={true}
                        isControlled={true}
                        placeholder={t("app.firstname.placeholder")}
                        value={this.state.deliveryAddress.first_name}
                        isDisabled={!isAddressEditMode}
                        name={"first_name"}
                        handleChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div className={"login__col"}>
                      <Textfield
                        label={t("app.lastname")}
                        placeholder={t("app.lastname.placeholder")}
                        isRequired={true}
                        isControlled={true}
                        value={this.state.deliveryAddress.last_name}
                        name={"last_name"}
                        isDisabled={!isAddressEditMode}
                        handleChange={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield
                        label={t("app.street")}
                        placeholder={t("app.street.placeholder")}
                        name={"street"}
                        isRequired={true}
                        isControlled={true}
                        value={this.state.deliveryAddress.street}
                        isDisabled={!isAddressEditMode}
                        handleChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div className={"login__col"}>
                      <TextfieldGroup>
                        <Textfield
                          label={t("app.plz")}
                          isRequired={true}
                          isControlled={true}
                          placeholder={t("app.plz.placeholder")}
                          name={"zip"}
                          isDisabled={!isAddressEditMode}
                          value={this.state.deliveryAddress.zip}
                          handleChange={(e) => this.handleChange(e)}
                        />
                      </TextfieldGroup>
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield
                        label={t("app.city")}
                        isRequired={true}
                        isControlled={true}
                        placeholder={t("app.city.placeholder")}
                        name={"city"}
                        isDisabled={!isAddressEditMode}
                        value={this.state.deliveryAddress.city}
                        handleChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div className={"login__col"}>
                      <Selectfield
                        label={t("app.country")}
                        options={countries}
                        defaultValue={selectedCountry}
                        onSelect={(e) => this.handleChange(e)}
                        isRequired={true}
                        placeholder={t("app.country")}
                        name="country_code"
                        state={`${isAddressEditMode ? "active" : "inactive"}`}
                      />
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield
                        label={t("app.email")}
                        placeholder={t("app.email.placeholder")}
                        isRequired={true}
                        isControlled={true}
                        value={this.state.deliveryAddress.email}
                        isDisabled={!isAddressEditMode}
                        handleChange={(e) => this.handleChange(e)}
                        name={"email"}
                      />
                    </div>
                    <div className={"login__col"}>
                      <Textfield
                        label={t("app.phone")}
                        isControlled={true}
                        placeholder={t("app.phone.placeholder")}
                        value={this.state.deliveryAddress.phone}
                        isDisabled={!isAddressEditMode}
                        handleChange={(e) => this.handleChange(e)}
                        name={"phone"}
                      />
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield
                        label={t("app.customer")}
                        placeholder={t("app.customer.placeholder")}
                        name={"company"}
                        isDisabled={!isAddressEditMode}
                        isControlled={true}
                        value={this.state.deliveryAddress.company}
                        handleChange={(e) => this.handleChange(e)}
                      />
                    </div>
                    <div className={"login__col"}>
                      <div
                        className={
                          "login__row m-t-auto checkout__shipping-actions"
                        }
                      >
                        <Button
                          type={"secondary"}
                          text={`${
                            isAddressEditMode
                              ? t("checkout.adress.button.safe")
                              : t("checkout.adress.button.change")
                          }`}
                        />

                        {isAddressEditMode && (
                          <Button
                            type="danger"
                            text={t("checkout.adress.button.cancel")}
                            onClick={(e) => this.toggleAddressEditMode(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default Shipping;
