import React, { Component } from "react";
import Button from "../../atom/button/Button";
import ProgressCircle from "../../element/progress-circle/ProgressCircle";
import { dateDiff } from '../../../helpers/utils'
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
import Icon from "../../atom/icons/Icon";
import { AppContext } from "../../../data/ContextProvider";
import { withRouter } from "react-router-dom";
import { getAssetSlider } from "../../../data/api";

class AppPraemienClubPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startNr: 0,
      endNr: ~~this.props.performance.percent,
      data: this.props.performance,
      dateEndDiff: dateDiff(this.props.performance.end),
      dateStartToEndDiff: dateDiff(this.props.performance.end, new Date(this.props.performance.start)),
      reachedGoal: false,
      headerSlider: []
    }
    this.timerHandle = null
    this.loaderHandle = null

    this.loaderIndex = 0;
    this.endingNumber = 0;
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
      loading: false
    })
  }

  componentDidMount() {
    let loadingBarValues = []
    if (this.percent)
      if (Math.ceil(Math.log10(this.state.endNr + 1)) >= 3) {
        //3 digits = over 100
        const hundrets = (this.state.endNr / 100)
        for (let i = 1; i <= hundrets; i++) {
          loadingBarValues.push(100)
        }
        loadingBarValues.push(Math.floor(this.state.endNr - (Math.floor(hundrets) * 100)))
        this.loadUp(loadingBarValues, 0)
      } else {
        this.loadUp([this.state.endNr], 0)
      }

      this.loadData()
  }

  loadUp = (values, count = 0) => {
    this.loader.style.width = count + "%";

    if (this.loaderIndex > 0) {
      this.percent.innerHTML = (count + (this.loaderIndex * 100)) + "%";
    } else {
      this.percent.innerHTML = count + "%";
    }

    this.endingNumber = values[this.loaderIndex]

    if (count < this.endingNumber) {
      this.loaderHandle = setTimeout(() => this.loadUp(values, count + 1), 20)
    } else {

      if (typeof values[this.loaderIndex + 1] !== 'undefined') {
        this.loader.style.width = 0 + "%";
        this.setState({
          reachedGoal: true
        })
        this.loaderIndex += 1;
        this.loadUp(values, 0)
      }
    }
  }

  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle)
    this.loaderHandle && clearTimeout(this.loaderHandle)
  }

  countUp = (count) => {
    this.percent.innerHTML = count + "%";

    if (count < this.state.endNr) {
      this.timerHandle = setTimeout(() => this.countUp(count + 1), 10)
    }
  }

  render() {
    const data = this.state.data

    return (
      <div className={`highperformance-performance__section praemienclub-performance__section`}>
        <div className={"app-header__actions app-header__actions--mobile"}>
          <Button type={"secondary"}
                  text={`${this.props.isHeaderOpen ? "Weniger Informationen" : "Mehr Informationen"}`}
                  textColor={"black"} backgroundColor={"bg-white"}
                  isClubButton={true}
                  onClick={() => this.props.handleHeader()} />
        </div>
        <div className={"highperformance-performance__content"}>
          <div className={"praemienclub-header__center"}>
            <div className={"praemienclub-header__center-inner"}>
              <div className={"praemienclub-header__center-header"}>
                <span className={"highperformance-header__circle"}>
                  <Icon icon={"3-stars-gold"} />
                </span>
                <span className={"highperformance-header__circle"}>
                  <Icon icon={"3-stars-gold"} />
                </span>
                <span className={"highperformance-header__circle"}>
                  <Icon icon={"3-stars-gold"} />
                </span>
                <h3>Ihr aktueller Premium-Rang</h3>
              </div>
              <div className={"praemienclub-header__center-rank"}>
                <Icon icon={"star"} />
                <h1>{data.current_place}</h1>
                <Icon icon={"star"} />
              </div>
            </div>
            <div className={"praemienclub-header__center-actions"}>
              <Button isClubButton={true} type={"club-link"} backgroundColor={"bg-white"} textColor={"black"}
                      isGold={true}
                      text={"Wertungskriterien"} onClick={() => {
                this.props.history.push(`/${window.currentLang}/wertungskriterien`)
              }} />
              {/*{!this.props.isPerformanceHidden &&*/}
              <Button isClubButton={true} type={"club-link"} backgroundColor={"bg-white"} textColor={"black"}
                      isGold={true}
                      text={"Ranking anzeigen"} onClick={() => {
                this.props.history.push(`/${window.currentLang}/ranking`)
              }} />
              {/*}*/}
            </div>
          </div>

          {/*<div className={"praemienclub-header__right"}>*/}
          {/*  <div className={"praemienclub-header__information--mobile"}>*/}
          {/*    <h3>Hallo {this.context.getNameOfUser()}</h3>*/}
          {/*    <p>Willkommen im Highperformance-Club, dem Sales-Elite-Zirkel unseres Unternehmens. Mitglied im exklusiven Club werden nur die Vertriebsmitarbeiter eines Jahres, die Ihre Ziele am erfolgreichsten erfüllen. Entsprechend Ihrer Performance treten Sie in einer Leistungsgruppe an. Die Besten aus jeder Gruppe erhalten dann die exklusive Eintrittskarte in den Highperformance-Club und gewinnen eine außergewöhnliche und einmalige Reise.</p>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*{!this.props.isPerformanceHidden &&*/}
          <div className={"highperformance-performance__box highperformance-performance__box--percent"}>
            <h3>Deine Zielerreichung</h3>
            <div className={"highperformance-performance__box-container"}>
              <div>
                <div
                  className={`highperformance-performance__loader ${this.state.reachedGoal ? "highperformance-performance__loader--second" : ""}`}>
                  <span ref={loader => (this.loader = loader)}
                        className={"highperformance-performance__loader-indicator"} />
                </div>
                <span className={"highperformance-performance__box-percent"} ref={percent => (this.percent = percent)}>
                  0%
                </span>

                {this.state.endNr >= 100 &&
                <span className={"neukunden-performance__congrats"}>
                  <UncontrolledLottie animationData={confetti} />
                </span>
                }
              </div>
              {/*{data.remaining_target > 0 ?*/}
              {/*  <p>Auf geht’s, Dir fehlen noch <b>{data.remaining_target} Verträge</b> zur nächsten Gewinnstufe.</p>*/}
              {/*  :*/}
              {/*  <p>Herzlich Glückwunsch, Du hast deine Zielvorgabe erreicht! Starke Leistung, weiter so!</p>*/}
              {/*}*/}
            </div>
          </div>
          {/*}*/}

          <div className={"highperformance-performance__box highperformance-performance__box--timer"}>
            <h3>Restliche Zeit bis zum Aktionsende</h3>
            <span>
              <ProgressCircle value={this.state.dateEndDiff.days} maxValue={this.state.dateStartToEndDiff.days}
                              isCountDown={true} text={"Tage"} />
              <ProgressCircle value={this.state.dateEndDiff.hours} maxValue={24} text={"Stunden"} />
              <ProgressCircle value={this.state.dateEndDiff.minutes} maxValue={60} text={"Minuten"} />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

AppPraemienClubPerformance.contextType = AppContext

export default withRouter(AppPraemienClubPerformance);
