import React from "react";
import { useParams } from "react-router-dom";
import PraemienshopClub from "./PraemienshopClub/PraemienshopClub";

const PraemienshopClubPage = (props) => {
  let { lang } = useParams();

  return (
    <>
      <PraemienshopClub lang={lang} view={props.view} />
    </>
  )
}

export default PraemienshopClubPage;
