import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import PropTypes from "prop-types";
import ballons from "../../../assets/lotties/promobooster/anim--ballons";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import Textfield from "../../atom/textfield/Textfield";
import { Link, withRouter } from "react-router-dom";
import { tryToLogInUser } from "../../../lib/lib";
import { checkPromoCode, getContentPage } from "../../../data/api";
import AppPromoBoosterSignup from "../app-promobooster-signup/AppPromoBoosterSignup";
import { CONTENT_SPIELREGELN } from "../../../data/constants";
import t from "../../../helpers/t9n";

class AppPromoBoosterLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      step: 1,
      codeToRedeem: "",
      codeError: ""
    }
  }

  async componentDidMount() {
    const page = await getContentPage(CONTENT_SPIELREGELN, this.context.getProjectData().id, this.context.getProjectData().app_id)
  }

  handleChange = (e) => {
    if (e.target) {
      const name = e.target.name
      const value = e.target.value
      const state = this.state
      state[name] = value
      this.setState({ ...state });
    }
  }

  handleSubmitLogin = async (e) => {
    e.preventDefault()

    let userData, loginError;

    try {
      userData = await tryToLogInUser(this.state.email, this.state.password)
    } catch (error) {
      loginError = error.data
    }

    if (userData) {
      if (this.state.error) {
        this.setState({ error: "" })
      }
      this.context.saveUserData(userData)

      //Get / Check User.address and redirect to SingUp or Booster
      this.props.handleAccess()

    } else if (loginError) {
      console.log(loginError)
      this.setState({
        error: loginError,
      })
    }
  }

  handlePromoCodeCheck = (e) => {
    e.preventDefault()
    const response = checkPromoCode(this.state.promo_code)

    response.then(res => {
      if (res.promocodes === true) {
        //Code is valid, redirect to Registration, save code
        this.setState({
          step: 2,
          codeToRedeem: this.state.promo_code
        })
      } else {
        this.setState({
          codeError: t("app.code.invalid")
        })
      }
    })
  }

  getLoginForm() {
    return (
      <form onSubmit={(e) => this.handleSubmitLogin(e)}>
          <Textfield label={t("login.input.username")}
                     errorMsg={this.state.error}
                     name="email"
                     placeholder={t("login.input.username")}
                     handleChange={(e) => this.handleChange(e)}
          />
          <Textfield label={t("login.input.password")}
                     errorMsg={this.state.error}
                     name="password"
                     placeholder={t("login.input.password")}
                     inputType={"password"}
                     handleChange={(e) => this.handleChange(e)}
          />
        {/*//TODO: Fix link and Step data */}
        <Link to={`/${window.currentLang}/login/3`} data={{ step: 3 }}>{t("app.forgot.password")}</Link>
        <Button type={"primary"} textColor={"white"} text={t("login.button")} />
      </form>
    )
  }

  getLogin() {
    return (
      <>
      <div className={"app-header__content"}>
        <div className={"promobooster-header__upper"}>
          <span className={"app-header__heading"}>
            <div>
              <span>
                <Icon icon={"app-promobooster"} />
              </span>
              <h1>{t("app.promo.booster")}</h1>
            </div>
            <h2>{t("app.code.collect")}</h2>
          </span>
        </div>
        <div className={"promobooster-header__lower"}>
          <div className={"app-header__left"}>
            <h3>{t("app.welcome.at.company")}</h3>
            <p>{t("app.code.collect.redeem")}</p>
            <span className={"app-header__actions"}>
              <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                      onClick={() => {
                        this.props.history.push(`/${window.currentLang}/rules`)
                      }} />
            </span>
          </div>
          <div className={"app-header__right"}>
            <div className={"promobooster-form__section"}>
              <div className={"promobooster-form__box"}>
                <h3>{t("app.register.via.code")}</h3>
                <p>{t("app.code.to.sign.up")}</p>

                <form>
                  <Textfield label={t("app.code")}
                             errorMsg={this.state.codeError}
                             name={"promo_code"}
                             handleChange={(e) => this.handleChange(e)} />
                  <Button type={"primary"} textColor={"white"} text={t("app.code.redeem")}
                          onClick={(e) => this.handlePromoCodeCheck(e)} />
                </form>
              </div>

              <div className={"promobooster-form__box"}>
                <h3>{t("app.already.customer")}</h3>
                {this.getLoginForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span className={"app-header__box"}>
        <UncontrolledLottie animationData={ballons} />
      </span>
    </>
    )
  }

  getCurrentStep() {
    const step = this.state.step

    switch (step) {
      case 1:
        return this.getLogin()
      case 2:
        return <AppPromoBoosterSignup promoCode={this.state.codeToRedeem} handleAccess={this.props.handleAccess} />
      default:
        break;
    }
  }

  render() {

    // Mobile Version below
    return (
      <>
      <div
        className={`app-header__section promobooster-header__section promobooster-header__section--${this.state.step} ${this.props.isHeaderOpen ? "app-header__section--open" : ""}`}>
        <video loop autoPlay>
          <source
            src={`${process.env.PUBLIC_URL}/videos/SalesAttack/anim--blue-house-bg.mp4`}
            type="video/mp4" />{t("app.video.no.support")}
        </video>
        {this.getCurrentStep()}
      </div>
        {this.state.step === 1 &&
        <>
          <div className={"app-header__actions app-header__actions--mobile"}>
            <Button type={"secondary"}
                    text={`${this.props.isHeaderOpen ? t("app.less.info") : t("app.more.info")}`}
                    textColor={"black"} backgroundColor={"bg-white"}
                    onClick={() => this.props.handleHeader()} />
          </div>
          <div className="promobooster-form__section promobooster-form__section--mobile">
            <div className={"promobooster-form__box"}>
              <h3>{t("app.register.via.code")}</h3>
              <p>{t("app.code.to.sign.up")}</p>

              <form>
                <Textfield label={t("app.code")} name={"promo_code"} handleChange={(e) => this.handleChange(e)} />
                <Button type={"primary"} textColor={"white"} text={t("app.code.redeem")}
                        onClick={(e) => this.handlePromoCodeCheck(e)} />
              </form>
            </div>

            <div className={"promobooster-form__box"}>
              <h3>{t("app.already.customer")}</h3>
              {this.getLoginForm()}
            </div>
          </div>
        </>
        }
      </>
    );
  }
}

AppPromoBoosterLogin.propTypes = {
  handleHeader: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
};
AppPromoBoosterLogin.defaultProps = {
  isHeaderOpen: false,
};

AppPromoBoosterLogin.contextType = AppContext

export default withRouter(AppPromoBoosterLogin);
