import React from "react";
import { useParams } from "react-router-dom";
import Gewinnerreise from "./Gewinnerreise/Gewinnerreise";

const GewinnerreisePage = (props) => {
  let { lang } = useParams();
  
  return (
    <>
      <Gewinnerreise lang={lang} view={props.view} />
    </>
  )
}

export default GewinnerreisePage;
