import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ProgressCircle from "../../element/progress-circle/ProgressCircle";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import clap from "../../../assets/lotties/schaffe-ich/anim--schaffe-ich-clap.json";
import { getAssetSlider, getContentPage } from "../../../data/api";
import { CONTENT_SPIELREGELN } from "../../../data/constants";
import t from "../../../helpers/t9n";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";

class AppSchaffeichHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      text: this.props.pageText,
      headerSlider: []
    }
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    return (
      <>
        {this.state.headerSlider.length > 0 &&
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        }
        
        <div
          className={`app-header__section schaffe-ich-header__section ${this.props.isHeaderOpen ? "app-header__section--open" : ""}`}>
          <div className={"app-header__content"}>
            <div className={"app-header__left"}>
              <span className={"app-header__heading"}>
                <div>
                  <span>
                    <Icon icon={"app-schaffe-ich"} />
                  </span>

                  <h1>{this.state.text.p33_header_content_headline}</h1>
                </div>
                <h2>{this.state.text.p33_header_content_subline}</h2>
              </span>

              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.text.p33_upper_content_text ? this.state.text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                }}>
              </p>

              <span className={"app-header__actions"}>
                {this.props.isHeaderOpen ?
                  <Button type={"link"} text={t("global.close.info")} textColor={"white"} icon={"arrow"}
                          onClick={() => this.props.handleHeader()} />
                  :
                  <Button type={"link"} text={t("global.open.info")} textColor={"white"} icon={"arrow"}
                          onClick={() => this.props.handleHeader()} />
                }
                <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                        onClick={() => {
                          this.props.history.push(`/${window.currentLang}/rules`)
                        }} />
              </span>


                <div className={"schaffe-ich-room__timer-wrapper"}>
                  <h2>{this.props.hasCampaignStarted ? t("app.promotional.period.ends") : t("app.time.choose.challenge")}</h2>
                  <div className={"salespush-performance__box salespush-performance__box--timer"}>
                    <span>
                      <ProgressCircle
                        value={this.props.dateEndDiff.days}
                        maxValue={this.props.dateStartToEndDiff.days}
                        isCountDown={true}
                        text={t("app.days")}
                        expired={this.props.dateExpired}
                      />
                      <ProgressCircle
                        value={this.props.dateEndDiff.hours}
                        maxValue={24}
                        text={t("app.hours")}
                        expired={this.props.dateExpired}
                      />
                      <ProgressCircle
                        value={this.props.dateEndDiff.minutes}
                        maxValue={60}
                        text={t("app.mins")}
                        expired={this.props.dateExpired}
                      />
                    </span>
                  </div>
                </div>


            </div>
          </div>
          <span className={"app-header__box"}>
            <UncontrolledLottie animationData={clap} />
          </span>
        </div>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}

AppSchaffeichHeader.propTypes = {
  handleHeader: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
};
AppSchaffeichHeader.defaultProps = {
  isHeaderOpen: false,
};

AppSchaffeichHeader.contextType = AppContext

export default withRouter(AppSchaffeichHeader);
