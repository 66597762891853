import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Icon from "../../atom/icons/Icon";
import { getUserStats, readPost } from "../../../data/api";
import { AppContext } from "../../../data/ContextProvider";

const NewsItem = props => {

  const [isActive, setIsActive] = useState(props.isOpen);
  const [isRead, setIsRead] = useState(props.isRead);

  const context = useContext(AppContext)

  function toggleNewsItem() {
    if (props.handleClick) {
      props.handleClick();
    }

    readPost(props.id).then((read) => {
      const stats = getUserStats().then((res) => {
        context.setStatsOfUser(res.stats)
      })
    })


    setIsActive(!isActive)

    if (!isRead) {
      setIsRead(true)
    }
  }

  useEffect(() => {
    setIsActive(props.isOpen)
  }, [props.isOpen, setIsActive]);

  return (
    <div
      className={`news-item ${isActive ? "news-item--active" : ""} ${isRead ? "news-item--read" : ""}
       ${props.additionalClass ? props.additionalClass : ""}`}>
      <span className={"news-item__header"} onClick={() => toggleNewsItem()}>
        {isRead ?
          <Icon icon={`email-action-read`} />
          :
          <Icon icon={`news-new`} />
        }
        <span className={"news-item__header-indicator"} />
        <div className={"news-item__header-content"}>
          {props.date}
          <span className={"news-item__header-label"}>
            {props.heading}
          </span>
        </div>
        <Icon icon={"arrow"} />

      </span>
      <div className={"news-item__content"}>
        <h4>{props.title}</h4>
        {props.children}
      </div>
    </div>
  );
}

NewsItem.propTypes = {
  heading: PropTypes.string,
  handleClick: PropTypes.func,
  isOpen: PropTypes.bool,
  isRead: PropTypes.bool,
  id: PropTypes.number,
};
NewsItem.defaultProps = {
  heading: "NewsItem",
  date: "02.02.2020",
  isOpen: false,
  isRead: false,
};

export default NewsItem
