import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import Icon from "../../components/atom/icons/Icon";
import Button from "../../components/atom/button/Button";
import UncontrolledLottie from "../../components/atom/lottie/UncontrolledLottie";
import hero from "../../assets/lotties/heros-place-hero/anim--herosplace-hero";

import t from "../../helpers/t9n";
import AppSliderHeader from "../../components/module/app-slider-header/AppSliderHeader";
import AppAkkordeonHeader from "../../components/module/app-akkordeon-header/AppAkkordeonHeader";
import { AppContext } from "../../data/ContextProvider";

class AppHerosPlaceHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      headerSlider: []
    }
  }

  render() {
    const text = this.props.text

    return (
      <>
      {this.props.headerSlider.length > 0 &&
        <AppSliderHeader headerSlider={this.props.headerSlider} />
      }

      <div className={`heros-place-header__section ${this.state.isOpen ? "heros-place-header__section--open" : ""}`}>
        <video loop autoPlay>
          <source
            src={`${process.env.PUBLIC_URL}/videos/HerosPlace/anim--red-dessert-bg.mp4`}
            type="video/mp4" />{t("app.video.no.support")}
        </video>
        <div className={"heros-place-header__content"}>
          <div className={"heros-place-header__left"}>
            <span className={"heros-place-header__heading"}>
              <div>
                <span>
                  <Icon icon={"app-herosplace"} />
                </span>
                <h1>{text.p33_header_content_headline}</h1>
              </div>
              <h2>{text.p33_header_content_subline}</h2>
            </span>

            <p
              dangerouslySetInnerHTML={{
                __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
              }}>
            </p>

            <span className={"heros-place-header__actions"}>
              <Button type={"link"} text={`${this.state.isOpen ? t("global.close.info") : t("global.open.info")}`}
                      textColor={"white"} icon={"arrow"} onClick={() => {
                this.setState({ isOpen: !this.state.isOpen })
              }} />
              <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                      onClick={() => {
                        this.props.history.push(`/${window.currentLang}/rules`)
                      }} />
            </span>
          </div>
          <span className={"heros-place-header__actions heros-place-header__actions--mobile"}>
            <Button type={"secondary"} text={`${this.state.isOpen ? t("app.less.info") : t("app.more.info")}`}
                    textColor={"black"} backgroundColor={"bg-white"}
                    onClick={() => {
                      this.setState({ isOpen: !this.state.isOpen })
                    }} />
          </span>
        </div>
        <span className={"heros-place-header__box"}>
          <UncontrolledLottie animationData={hero} />
        </span>
      </div>

      {this.context.user.welcome_tool_enabled &&
        <AppAkkordeonHeader />
      }
      </>
    );
  }
}

AppHerosPlaceHeader.contextType = AppContext
export default withRouter(AppHerosPlaceHeader)