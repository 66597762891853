import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import { API_HOST } from "../../../data/config";
import t from "../../../helpers/t9n";
import { getAssetSlider } from "../../../data/api";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";

class AppGewinnerreiseHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      headerSlider: []
    }
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    const text = this.props.text

    return (
      <>
        {this.state.headerSlider.length > 0 &&
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        }

        <div
          className={`app-header__section gewinnerreise-header__section
          ${this.props.isHeaderOpen ? "app-header__section--open" : ""}
          ${this.props.isRanking ? "gewinnerreise-header__section--ranking" : ""}`}>

          <div className={"app-header__content"}
              style={{ backgroundImage: `url(${API_HOST}/asset/${text["p33_header_img_1"]}?pid=${this.context.user.project_id})` }}>
            <div className={"app-header__left"}>
              <span className={"app-header__heading"}>
                <div>
                  <span>
                    <Icon icon={"app-gewinnerreise"} />
                  </span>
                  <h1>{text.p33_header_content_headline}</h1>
                </div>
                <h2>{text.p33_header_content_subline}</h2>
              </span>

              <div className={"app-header__left-text"}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                  }}>
                </p>
              </div>

              <span className={"app-header__actions"}>
                <span className={"app-gewinnerreise__background"}>
                  <Button type={"link"} text={`${this.props.isHeaderOpen ? t("global.close.info") : t("global.more.info")}`}
                          textColor={"white"} icon={"arrow"}
                          onClick={() => this.props.handleHeader()} />
                </span>
                <Button text={t("global.rules")}
                        type={"secondary"}
                        textColor={"black"} backgroundColor={"bg-white"}
                        isClubButton={true}
                        onClick={() => {
                          this.props.history.push(`/${window.currentLang}/rules`)
                        }} />
              </span>
            </div>

          </div>
        </div>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}

AppGewinnerreiseHeader.propTypes = {
  handleHeader: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
};
AppGewinnerreiseHeader.defaultProps = {
  isHeaderOpen: false,
};

AppGewinnerreiseHeader.contextType = AppContext

export default withRouter(AppGewinnerreiseHeader);
