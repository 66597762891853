import React, { Component } from "react";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import CardSlider from "../../components/module/card-slider/CardSlider";
import AppFeuerfreiHeader from "../../components/module/app-feuerfrei-header/AppFeuerfreiHeader";
import t from "../../helpers/t9n";

class Feuerfrei extends Component {
  render() {
    return (
      <>
        <div className={"content app jackpot feuerfrei"}>
          
          <AppFeuerfreiHeader />
          <SectionRecommendations label={"Prämien-Highlights"} />
          
          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>
        </div>
      </>
    );
  }
}

export default Feuerfrei;
