const t9n = {

  // ===== DE =====

  "de": {
    // Login
    "login.h1": "Herzlich Willkommen",
    "login.h2.subline": "IN IHREM KUNDENPORTAL",
    "login.h2.login": "Anmeldung",
    "login.label.username": "Benutzername",
    "login.input.username": "Benutzername",
    "login.label.password": "Passwort",
    "login.input.password": "Passwort",
    "login.button": "Login",
    "login.forgotten.password": "Haben Sie ihr Passwort vergessen?",
    "login.error.info": "Es ist etwas schief gelaufen. Bitte Überprüfen Sie Ihre eingegebene Daten oder wenden Sie sich an unseren Support.",
    "login.new.password": "Legen Sie ein Neues fest.",
    "login.contact.support": "Sie können sich nicht einloggen? Überprüfen Sie Ihre Zugangsdaten, die Sie von uns per Mail erhalten haben. Alternativ wenden Sie sich bitte an unseren Support:",
    "login.service.mail": "service@salesattack.de",
    "login.data.protection": "Datenschutz",
    "login.imprint": "Impressum",
    "login.contact": "Kontakt",
    "login.password_policy": "Bitte vergeben Sie ein neues Passwort. Dieses muss mindestens 8 Zeichen, Groß- und Kleinschreibung und mindestens eine Zahl beinhalten.",
    "login.error.mail": "Die E-Mail Adresse darf nicht leer sein",
    "login.error.password_policy": "Ihr gewähltes Passwort entspricht nicht den Sicherheitsbestimmungen. Ihr Passwort muss aus mindestens 8 Zeichen bestehen und Groß- und Kleinschreibung und mindestens eine Zahl beinhalten.",
    "login.error.password_mismatch":"Passwörter stimmen nicht überein!",
    "login.error.password_is_empty":"Das Passwort darf nicht leer sein",
    "login.password_reset.headline":"Passwort zurücksetzen",
    "login.password_reset.description": "Hier haben Sie die Möglichkeit Ihr Passwort zurückzusetzen. Sobald Sie ihre E-Mail Adresse bestätigen, werden Sie aufgefordert ein neues Passwort zu vergeben.",
    "login.password_reset.email_success": "Wir haben Ihnen einen Link zur Passwortänderung an Ihre E-Mail-Adresse geschickt.",
    "login.password_reset.success": "Super, das hat geklappt! Das Passwort wurde erfolgreich zurückgesetzt.",

    "login.userdata.headline": "Datenvervollständigung",
    "login.tos.headline": "Teilnahmebedingungen",

    // Global
    "global.close.info": "Infos schließen",
    "global.open.info": "Weitere Infos",
    "global.rules": "Die Spielregeln",
    "global.rules.short": "Spielregeln",
    "global.points.account": "Mein Punktekonto",
    "global.award.overview": "Prämienübersicht",
    "global.recommendation": "Unsere Empfehlungen",
    "global.loading": "Lädt...",
    "global.your.cart": "Ihr Warenkorb",
    "global.your.order": "Ihre Bestellung",
    "global.less.info": "Weniger Infos",
    "global.back_to_login": "Zurück zum Login",
    "global.next": "Weiter",
    "global.account.heading": "Punktestand",
    "global.account.date": "Datum",
    "global.account.desc": "Beschreibung",
    "global.account.points": "Punkte",
    "global.account.sum": "Summe im Zeitraum",
    "global.account.old.points": "Aktueller Kontostand",
    "global.empty.table": "Keine Einträge vorhanden",
    "globale.change.products": "Zu den Prämien wechseln",
    "global.cart.empty": "Ihr Warenkorb ist leer.",
    "global.wishlist.button": "Meine Wunschliste anzeigen",
    "global.button.go.to.contact": "Zum Kontaktformular",
    "global.badge.neuheit": "Neuheit",
    "global.badge.highlight": "Highlight",
    "global.badge.luxus": "Luxusprämie",
    "global.key.new": "NEU",
    "global.order.view": "Bestellübersicht",
    "global.order.button.view": "Zur Bestellübersicht",
    "global.back.to.homepage": "Zurück zur Startseite",
    "global.something.went.wrong": "Ups, es ist etwas schief gelaufen.",
    "global.confirmation.heading": "Vielen Dank, wir haben Ihre Bestellung erhalten.",
    "global.confirmation.text": "Sie erhalten in den nächsten Minuten eine Bestellbestätigung an die angegebene E-Mail-Adresse. Bei Fragen zu Ihrer Bestellung prüfen Sie bitte Ihren Bestellstatus unter „Konto / Bestellstatus“. Dort können Sie jederzeit den aktuellen Versand-Status Ihrer Bestellung einsehen. Wir wünschen Ihnen die Spaß mit Ihrer Prämie.",
    "global.no.orders.in.time": "Keine Bestellungen im Zeitraum vorhanden.",
    "global.no.orders": "Keine Bestellungen vorhanden.",
    "global.switch.to.products": "Zu den Prämien wechseln",
    "global.accordion.orders.heading": "Zeitraum-Filter",
    "global.flatpicker.start": "von",
    "global.flatpicker.end": "bis",
    "global.flatpicker.date": "Datum wählen..",
    "global.flatpicker.button": "Neu laden",
    "global.wishlist.page.hint": "Mit Hilfe Ihrer Wunschliste können Sie Prämeinwünsche dauerhaft speichern. Setzen Sie dazu die Prämie auf Ihre Wunschliste. Sobald Sie über ausreichend Punkte zur Bestellung verfügen, werden Sie automatisch per E-Mail informiert und können die Artikel der Wunschliste ganz bequem in eine Bestellung übernehmen.",
    "global.wishlist.page.remove.all": "Alle Artikel entfernen",
    "global.wishlist.page.add.all": "Alle Artikel in den Warenkorb",
    "global.wishlist.page.empty": "Ihre Wunschliste ist leer.",
    "global.wishlist.page.button.cart": "Zum Warenkorb",
    "global.profile.page.heading": "Ihr Profil",
    "global.countries.germany": "Deutschland",
    "global.countries.belgium": "Belgien",
    "global.countries.denmark": "Dänemark",
    "global.countries.france": "Frankreich",
    "global.countries.uk": "Großbritannien",
    "global.countries.italy": "Italien",
    "global.countries.luxembourg": "Luxemburg",
    "global.countries.netherlands": "Niederlande",
    "global.countries.norway": "Norwegen",
    "global.countries.austria": "Österreich",
    "global.countries.poland": "Polen",
    "global.countries.romania": "Rumänien",
    "global.countries.sweden": "Schweden",
    "global.countries.switzerland": "Schweiz",
    "global.countries.czech": "Tschechien",
    "global.countries.hungary": "Ungarn",
    "global.countries.usa": "USA",


    // CART
    "cart.no.orders": "Sie können aktuell keine Prämienartikel bestellen, da Sie einer Bestellsperre unterliegen. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "cart.hint": "Bitte beachten Sie, dass aktuell keine Prämienbestellungen möglich sind. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "cart.qty": "Anzahl: ",
    "cart.variant.label": "Variante: ",
    "cart.no.available": "Artikel ist nicht lieferbar!",
    "cart.wishlist.button.remove": "Vom Wunschzettel entfernen",
    "cart.wishlist.button.add": "Auf den Wunschzettel",
    "cart.order.heading": "Status der Bestellung",
    "cart.button.product.remove": "Entfernen",
    "cart.track.order.button": "Sendung verfolgen",
    "cart.product.available": "Artikel sofort lieferbar",
    "cart.product.soon.available": "Artikel ist verfügbar ab ",
    "cart.popup.sum": "Gesamtsumme:",
    "cart.popup.delivery": "Lieferung",
    "cart.popup.first": "Warenkorb",
    "cart.popup.second": "Artikel",
    "cart.credit.box.text1": "Um die Bestellung auszuführen benötigen Sie noch",
    "cart.credit.box.text2": "Punkte. Die fehlenden Punkte für diese Bestellung können Sie für",
    "cart.credit.box.text3": "Euro zukaufen",
    "cart.bank.recent": "Ihr Kontostand",
    "cart.credit.box.shipping": "Versand und Lieferkosten inkl.",
    "cart.credit.box.buttun.send": "Zur Bestellung",
    "cart.credit.box.button.more": "Weitere Prämien",

    // CHECKOUT
    "checkout.form.adress": "Ausgewählte Lieferadresse",
    "checkout.adress.button.change": "Adresse bearbeiten",
    "checkout.adress.button.safe": "Adresse speichern",
    "checkout.adress.button.cancel": "Abbrechen",
    "checkout.choose.adress": "Wählen Sie Ihre Adresse",
    "checkout.choose.adress.button.send": "An diese Adresse versenden",
    "checkout.choose.adress.button.new": "Neue Adresse hinzufügen",
    "checkout.qty.label": "Anzahl",
    "checkout.qty.products": "Anzahl Artikel: ",
    "checkout.hint.check": "Bitte überprüfen Sie die Bestellung und bestätigen Sie diese mit einem Klick auf Bestellung abschicken",
    "checkout.overview.adress": "Lieferadresse",
    "checkout.mail.check": "Sie erhalten eine Bestätigung per E-Mail an folgende Adresse: ",

    "checkout.back.to.cart": "Zurück zum Warenkorb",
    "checkout.change.adress.button": "Lieferadresse ändern",
    "checkout.agb.check1": "Ich erkläre mich mit den",
    "checkout.agb.check2": "Teilnahmebedingungen",
    "checkout.agb.check3": "von Buben & Mädchen GmbH einverstanden.",
    "checkout.load.points.button": "Punkte aufladen",
    "checkout.contact.button": "Zum Kontaktformular",
    "checkout.confirm.order.button": "Bestellung abschicken",
    "checkout.no.orders.possible": "Sie können aktuell keine Prämienartikel bestellen, da Sie einer Bestellsperre unterliegen. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "checkout.no.orders.hint": "Bitte beachten Sie, dass aktuell keine Prämienbestellungen möglich sind. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "checkout.modal.no.points": "Fehlende Punkte",
    "checkout.modal.currency": "€",
    "checkout.modal.currency.long": "EUR",
    "checkout.modal.payment.check": "Zuzahlung abschließen",
    "checkout.modal.transaction.success": "Transaktion erfolgreich, ID",
    "checkout.modal.close.button": "Schließen",
    "checkout.modal.step1": "Punkte kaufen",
    "checkout.modal.step2": "Zahlungsmethode",
    "checkout.modal.step3": "Transaktion war erfolgreich",
    "checkout.breadcrumb.button1": "Adresse",
    "checkout.breadcrumb.button2": "Übersicht",
    "checkout.breadcrumb.button3": "Bestätigung",

    // PROFILE
    "profile.page.heading": "Ihre Persönlichen Daten",
    "profile.page.pw.now": "Geben Sie bitte Ihr aktuelles Passwort ein",
    "profile.page.pw.no.empty": "Das Passwort darf nicht leer sein",
    "profile.page.pw.not.same": "Passwörter stimmen nicht überein",
    "profile.page.pw.min.txt": "Ihr gewähltes Passwort entspricht nicht den Sicherheitsbestimmungen. Ihr Passwort muss aus mindestens 8 Zeichen bestehen und Groß- und Kleinschreibung und mindestens eine Zahl beinhalten.",

    // CONTACT
    "contact.page.choose": "Bitte wählen:",
    "contact.page.success": "Nachricht wurde erfolgreich versendet!",
    "contact.page.help1": "Allgemeine Fragen",
    "contact.page.help2": "Fragen zu Wertungskriterien / Punktebuchung / Spielregeln",
    "contact.page.help3": "Fragen zu Bestellungen und Prämien",
    "contact.page.help3.special": "Fragen zu Bestellungen und Prämien",
    "contact.page.heading": "Bitte wählen Sie den Grund Ihrer Anfrage aus.",
    "contact.page.reason": "Kontaktgrund",
    "contact.page.order.nr": "Bestellnummer (TB.xxx) oder Artikelnummer (BM-xxx)",
    "contact.page.message": "Ihre Nachricht",
    "contact.page.message.placeholder": "Sehr geehrte Damen und Herren..",
    "contact.page.send": "Abschicken",
    "contact.page.info": "Schreiben Sie uns eine Mail. Wir sind von Montag bis Freitag von 9:00 – 18:00 Uhr für Sie da.",

    // MENU
    "menu.main.categories": "Hauptkategorien",
    "menu.highlights": "Highlights",
    "menu.all.novelties": "Alle Neuheiten anzeigen",
    "menu.award.category": "Prämienkategorie",
    "menu.sub.categories": "Unterkategorien",
    "menu.welcome.text": "Hallo",
    "menu.edit.profile": "Profil bearbeiten",
    "menu.conditions.participation": "Teilnahmebedingungen",
    "menu.terms.delivery": "Lieferbedingungen",
    "menu.contact": "Kontakt",
    "menu.logout": "Logout",
    "menu.home": "Home",
    "menu.ratings": "Wertungen",
    "menu.ranking": "Rangfolge",
    "menu.my.contracts": "Meine Verträge",
    "menu.sort.contracts": "Vertragszuordnung",
    "menu.winners.trip": "Gewinnerreise",
    "menu.points.account": "Punktekonto",
    "menu.shopping.cart": "Warenkorb",
    "menu.order.status": "Bestellstatus",
    "menu.wishlist": "Wunschliste",
    "menu.news": "News",
    "menu.award.shop": "Prämienshop",
    "menu.search": "Suche",

    // SHOP
    "shop.delivery1.msg1": "Artikel lieferbar innerhalb von ",
    "shop.delivery1.msg1.day": " Wochen",
    "shop.delivery2.msg2": "Artikel lieferbar in ",
    "shop.delivery2.msg2.day": " Werktagen",

    // APP
    "app.more.info": "Mehr Informationen",
    "app.less.info": "Weniger Informationen",
    "app.currency": "Euro",
    "app.currency_points": "Einheiten",
    "app.sales": "Umsatz",
    "app.profit": "Gewinn",
    "app.points": "PKT",
    "app.contracts": "Verträge",
    "app.contracts.number": "Anzahl Vertragspunkte",
    "app.contracts.number.previous": "Anzahl Vertragspunkte im Vorjahr",
    "app.sales.previous": "Umsatz im Vorjahr",
    "app.goals": "Ziel",
    "app.goals.target": "Ihre Zielvorgabe:",
    "app.days": "Tage",
    "app.hours": "Stunden",
    "app.mins": "Minuten",
    "app.award.news": "Prämienneuheiten - schon entdeckt?",
    "app.award.highlights": "Prämien-Highlights",
    "app.remaining.time": "Restliche Zeit bis zum Aktionsende",
    "app.your.performance": "Ihre Performance im Vergleich zum Ziel",
    "app.your.target": "Ihre Zielerreichung im Vergleich zum Vorjahr",
    "app.your.target.reached": "Herzlich Glückwunsch, Sie haben Ihre Zielvorgabe erreicht! Starke Leistung, weiter so!",
    "app.lets.go": "Auf geht’s, Ihnen fehlen noch ",
    "app.next.win": " zur nächsten Gewinnstufe.",
    "app.attack": "Attacke! Überbieten Sie Ihre Zielvorgaben",
    "app.goal": "Ihr Ziel: Seien Sie besser als im Vorjahr!",
    "app.you.achieved": "Das haben Sie bereits geschafft:",
    "app.win.levels": "Gewinnstufen – Das können Sie gewinnen",
    "app.video.no.support": "Ihr Browser unterstützt das Video-Tag nicht. Bitte aktualisieren Sie Ihren Browser.",
    "app.campaign.end": "Die Super Sales Aktion endet in:",
    "app.sales.performance": "Ihre Sales Performance",
    "app.sales.national": "National",
    "app.sales.per.contract": "Umsatz pro Vertrag",
    "app.sales.status": "Ihr Stand",
    "app.your.place": "DEINE PLATZIERUNG",
    "app.current.level": "Aktuelle Gewinnstufe",
    "app.win.level": "Gewinnstufen",
    "app.place": "Platz",
    "app.overall.ranking": "Gesamt-Ranking",
    "app.sales.contract": "Umsatz der Verträge",
    "app.contracts.closed": "Vertragspunkte",
    "app.herosplace.ranking": "HEROSPLACE RANKING",
    "app.back.win.level": "Zurück zu Gewinnstufen",
    "app.to.overall.ranking": "Zum Gesamt-Ranking",
    "app.choose.challenge": "Bounties - Wählen sie eine challenge aus",
    "app.challenge": "Challenge",
    "app.target.value": "Ziel-Wert",
    "app.your.profit": "Ihr Gewinn",
    "app.schaffeich": "Ja, das schaffe ich!",
    "app.this.challenge": "Diese Challenge wählen",
    "app.personal.challenge": "Ihre Persönliche Challenge",
    "app.target.achievement": "Zielerreichung",
    "app.actual.value": "IST WERT",
    "app.target.value.challenge": "ZIEL WERT",
    "app.win.target.achievement": "Gewinn bei Zielerreichung",
    "app.promotional.period.ends": "Aktionszeitraum endet in:",
    "app.time.choose.challenge": "Zeit für die Auswahl Ihrer Challenge!",
    "app.save.challenge": "Challenge speichern",
    "app.award.search": "Prämiensuche",
    "app.award.search.keyword": "Suchbegriff",
    "app.award.search.points.from": "Punkte von",
    "app.award.search.points.to": "..bis",
    "app.award.search.start": "Suche starten",
    "app.costumer": "Kunden",
    "app.new.costumer.barometer": "Neukunden-Barometer",
    "app.already.achieved": "Das hast du bereits erreicht",
    "app.acquired.new.customers": "Akquirierte Neukunden",
    "app.contracts.missing": "Verträge, die dir bis zur nächsten Stufe fehlen.",
    "app.number.new.costumers": "Neukunden-Anzahl",
    "app.gain.points": "Punkte-Gewinn",
    "app.all.win.steps": "Alle Gewinnstufen",
    "app.available.games": "Verfügbare Spiele",
    "app.equals.closed.contracts": "Entspricht Ihren abgeschlossenen Verträgen",
    "app.have.fun": "Viel Spaß!",
    "app.points.bandit": "Punkte-Bandit",
    "app.bandit.info.klick": "Klicken Sie auf den einarmigen ",
    "app.bandit.info.game": "Punkte-Banditen und spielen Sie.",
    "app.points.safe":  "Punktetresor",
    "app.point":  "Punkte",
    "app.points.safe.info": "Mit jedem Punktegewinn reduziert sich der allgemeine Punktetresor. Sobald der Tresor leer ist endet die Aktion.",
    "app.our.sales.target": "Unser Umsatzziel",
    "app.until.the": "bis zum ",
    "app.current.sales": "Aktueller Umsatz",
    "app.currency.icon": "€",
    "app.information": "Informationen",
    "app.plus": "PLUS",
    "app.bonus.ammount": "Bonus in Höhe von",
    "app.deadline": "Deadline",
    "app.monthly.award.points": "Jeden Monat schreiben wir Dir Prämienpunkte im Wert von 44 Euro gut. Dies ist eine Sachzuwendung, die für Dich komplett steuerfrei ist. Mit Deinen Punkten kannst Du aus über 3.000 tollen Produkten Deine persönliche Wunschprämie zum Zeitpunkt Deiner Wahl aussuchen. Viel Spaß!",
    "app.my.points": "MEINE PUNKTE",
    "app.news.award.club": "News aus dem Prämien-Club",
    "app.new": "NEU",
    "app.read.on": "weiterlesen",
    "app.read.all.news": "Alle News lesen",
    "app.jackpot.counter": "JACKPOT-COUNTER",
    "app.still.in.jackpot": "Sind noch im Jackpot",
    "app.your.point.status": "Ihr Punktestand",
    "app.back.to.overview": "Zurück zur Übersicht",
    "app.category": "Kategorie",
    "app.award.variant": "Prämien-Variante",
    "app.put.in.cart": "In den Warenkorb",
    "app.award.despcription": "Prämienbeschreibung",
    "app.main.category": "Hauptkategorie",
    "app.sub.category": "Unterkategorie",
    "app.choose.main.category": "Bitte wählen Sie eine Hauptkategorie.",
    "app.shop.search": "Suchen",
    "app.shop.sort.for": "Sortieren nach",
    "app.shop.sort.for.newest": "Neuste Prämien",
    "app.shop.sort.for.az": "Alphabetisch A-Z",
    "app.shop.sort.for.za": "Alphabetisch Z-A",
    "app.password.not.match": "Passwörter stimmen nicht überein.",
    "app.sign.up.step.one": "Schritt 1/2",
    "app.sign.up.declare": "Ich erkläre mich mit den ",
    "app.sign.up.agree": " von Buben & Mädchen GmbH einverstanden. ",
    "app.sign.up.just_agree": " einverstanden. ",
    "app.continue": "Weiter",
    "app.privacy.policy": "Datenschutzbedingungen",
    "app.privacy.disclaimer": "Ich bin damit einverstanden, dass meine personenbezogenen Daten, durch die in den Datenschutzbestimmungen ausgewiesene Stelle, im Rahmen meiner Teilnahme am genannten Programm, zum Zwecke der Teilnahme am Incentive verarbeitet werden. Mir ist bekannt, dass ausschließlich diejenigen personenbezogenen Daten verarbeitet werden, die zur Teilnahme an dem Incentive notwendig sind. Die jeweiligen Daten werden von mir selbst angegeben. Mir ist außerdem bekannt, dass ich meine Einwilligung jederzeit durch Erklärung gegenüber der in den Datenschutzbestimmungen ausgewiesene Stelle durch einfache Erklärung widerrufen kann. Mir stehen außerdem die Rechte nach dem Dritten Kapitel der DS-GVO auf Auskunft, Berichtigung, Löschung, Datenübertragbarkeit, Einschränkung der Verarbeitung, Widerspruch und auf Beschwerde gegenüber einer Aufsichtsbehörde zu.",
    "app.sign.up.step.two": "Schritt 2/2",
    "app.company": "Firma",
    "app.company.placeholder": "Firma XY",
    "app.salutation": "Anrede",
    "app.mr": "Herr",
    "app.ms": "Frau",
    "app.dr": "Dr",
    "app.firstname": "Vorname",
    "app.lastname": "Nachname",
    "app.firstname.placeholder": "Max",
    "app.lastname.placeholder": "Mustermann",
    "app.street": "Straße & Hausnummer",
    "app.street.placeholder": "Musterstraße",
    "app.plz": "PLZ*",
    "app.plz.placeholder": "12345",
    "app.city": "Ort*",
    "app.city.placeholder": "Ort",
    "app.country": "Land*",
    "app.country.placeholder": "Land",
    "app.birthday": "Geburtsdatum",
    "app.birthday.placeholder": "01.03.1960",
    "app.email_short": "E-Mail",
    "app.email": "E-Mail Adresse*",
    "app.email_your": "Ihre E-Mail Adresse*",
    "app.email.placeholder": "m.mustermann@muster.de",
    "app.phone": "Telefon",
    "app.phone.placeholder": "1234 567890",
    "app.handy": "Handy-Nummer",
    "app.handy.placeholder": "1234 567890",
    "app.customer": "Händler",
    "app.customer.placeholder": "Händler",
    "app.customer.nr": "HDL-Nr",
    "app.customer.nr.placeholder": "123-456-789",
    "app.agree.checkbox": "Hiermit erkläre ich mich einverstanden, regelmäßig Programminformationen zu erhalten.",
    "app.profile.pic.heading": "IHR PROFILBILD",
    "app.profile.pic.change": "Zum ändern klicken …",
    "app.profile.pic.drop": "Ziehen Sie Ihr Bild in diese Fläche …",
    "app.profile.pic.delete": "Profilbild löschen",
    "app.profile.pic.safe": "Profilbild speichern",
    "app.profile.add.adress": "Adresse hinzufügen",
    "app.profile.all.adresses": "Ihre Lieferadressen",
    "app.profile.adress.phone": "Telefon:",
    "app.profile.adress.mail": "E-Mail:",
    "app.profile.adress.settings": "Bearbeiten",
    "app.profile.adress.remove": "Entfernen",
    "app.profile.adress.change": "Adresse bearbeiten",
    "app.profile.adress.new": "Neue Adresse",
    "app.profile.adress.changes.safe": "Änderungen speichern",
    "app.profile.adress.new.safe": "Neue Adresse speichern",
    "app.profile.login.heading": "Ihre Logindaten",
    "app.profile.login.current.pw": "Aktuelles Passwort",
    "app.profile.login.new.pw": "Neues Passwort",
    "app.profile.login.new.pw.same": "Neues Passwort wiederholen",
    "app.profile.login.new.pw.safe": "Neues Passwort speichern",
    "app.safe.data": "Daten speichern",
    "app.personal.data": "Persönliche Daten",
    "app.sign.up.complete": "Registrierung – bitte Daten vervollständigen",
    "app.sign.up.check.data": "Hier können Sie im letzten Registrierungsschritt noch einmal Ihre persönlichen Daten einsehen, überprüfen und auch korrigieren. Wenn Sie Änderungen an Vor- und Nachnamen vornehmen, wenden Sie sich bitte an unser Supportteam. Bitte ändern Sie auch Ihr Passwort hier noch einmal.",
    "app.new.password_short": "Neues Passwort",
    "app.new.password": "Ihr neues Passwort",
    "app.new.password_save": "Neues Passwort speichern",
    "app.new.password.repeat": "Bitte wiederholen Sie Ihr Passwort",
    "app.award.code": "Prämien-Code",
    "app.award.code.placeholder": "Code",
    "app.award.code.redeem": "Einlösen",
    "app.exclusive.award.collection": "IHRE EXKLUSIVE PRÄMIENAUSWAHL",
    "app.code.invalid": "Code nicht gültig.",
    "app.forgot.password": "Passwort vergessen?",
    "app.promo.booster": "PROMO-BOOSTER",
    "app.code.collect": "MIT PROMO-CODE PUNKTE SAMMELN",
    "app.welcome.at.company": "Willkommen bei der Musterfirma-PrämienParty!",
    "app.code.collect.redeem": "Sie haben ein Aktionsprodukt erworben?! Super, dann können Sie gleich hier den Promotion-Code (oder mehrere) einlösen. Die entsprechenden Punkte werden automatisch Ihrem Punktekonto gutgeschrieben. Anschließend können Sie sich nach Lust und Laune Ihre Wunschprämie aussuchen.",
    "app.register.via.code": "Zur Registrierung via Promo-Code",
    "app.code.to.sign.up": "Geben Sie hier Ihren Promocode ein, um sich im XY Promocode Portal ein Konto einzurichten.",
    "app.code": "Promocode",
    "app.code.redeem": "Code einlösen",
    "app.already.customer": "Ich bin bereits Kunde",
    "app.ranking": "RANKING - MEINE PLATZIERUNG",
    "app.belong.to.group": "Sie gehören zur Gruppe",
    "app.rank": "Rang",
    "app.name": "Name",
    "app.rank.points": "Rankingpunkte",
    "app.evaluation.criteria": "Wertungskriterien",
    "app.your.current.rank": "Ihr aktueller Rang",
    "app.welcome.to.highperformance": "Willkommen im Highperformance-Club, dem Sales-Elite-Zirkel unseres Unternehmens. Mitglied im exklusiven Club werden nur die Vertriebsmitarbeiter eines Jahres, die Ihre Ziele am erfolgreichsten erfüllen. Entsprechend Ihrer Performance treten Sie in einer Leistungsgruppe an. Die Besten aus jeder Gruppe erhalten dann die exklusive Eintrittskarte in den Highperformance-Club und gewinnen eine außergewöhnliche und einmalige Reise.",
    "app.show.rank": "Ranking anzeigen",
    "app.news.no.heading": "Keine News vorhanden",
    "app.news.no.button": "Zu den Prämien wechseln",

    //404
    "page.404.headline": "OCH NEE ...",
    "page.404.subline": "Da funktioniert was nicht.",
    "page.404.info": "Entweder kam es zu einem schrecklichen Fehler, oder die Seite existiert nicht. Aber keine Sorge – wir helfen dir weiter!",
    "page.404.action.home": "Zur Startseite",
    "page.404.action.contact": "Kontakt aufnehmen",
    "page.404.domain.headline": "Vielleicht vertippt?",
    "page.404.domain.info": "Bitte überprüfen Sie die eingegebene URL. So ungefähr muss sie lauten:",
  },

  // ===== EN =====

  "en": {
    // Login
    "login.h1": "Welcome",
    "login.h2.subline": "In your customer portal",
    "login.h2.login": "Login",
    "login.label.username": "Username *",
    "login.input.username": "Username",
    "login.label.password": "Password *",
    "login.input.password": "Password",
    "login.button": "Login",
    "login.forgotten.password": "Did u forgot your password?",
    "login.error.info": "Es ist etwas schief gelaufen. Bitte Überprüfen Sie Ihre eingegebene Daten oder wenden Sie sich an unseren Support.",
    "login.new.password": "Define a new one.",
    "login.contact.support": "You can't log in? Please check your access data which you have received from us by mail. Alternatively please contact our support:",
    "login.service.mail": "service@incentivearena.de",
    "login.data.protection": "Data protection",
    "login.imprint": "Imprint",
    "login.contact": "Contact",
    // Global
    "global.close.info": "Information close",
    "global.open.info": "Further Information",
    "global.rules": "The Game rules",
    "global.points.account": "To the points account",
    "global.award.overview": "Overview of awards",
    "global.recommendation": "Our recommendation",
    "global.loading": "loading...",
    "global.countries.germany": "Germany",
    "global.countries.belgium": "Belgium",
    "global.countries.denmark": "Denmark",
    "global.countries.france": "France",
    "global.countries.uk": "United Kingdom",
    "global.countries.italy": "Italy",
    "global.countries.luxembourg": "Luxembourg",
    "global.countries.netherlands": "Netherlands",
    "global.countries.norway": "Norway",
    "global.countries.austria": "Austria",
    "global.countries.poland": "Poland",
    "global.countries.romania": "Romania",
    "global.countries.sweden": "Sweden",
    "global.countries.switzerland": "Switzerland",
    "global.countries.czech": "Czech Republic",
    "global.countries.hungary": "Hungary",
    "global.countries.usa": "USA",

    // MENU
    "menu.main.categories": "Main categories",
    "menu.highlights": "Highlights",
    "menu.all.novelties": "Show all novelties",
    "menu.award.category": "Award category",
    "menu.sub.categories": "Subcategories",
    "menu.welcome.text": "Hello",
    "menu.edit.profile": "Edit profile",
    "menu.conditions.participation": "Conditions of participation",
    "menu.terms.delivery": "Terms of delivery",
    "menu.contact": "Contact",
    "menu.logout": "Logout",
    "menu.home": "Home",
    "menu.ratings": "Ratings",
    "menu.ranking": "Ranking",
    "menu.my.contracts": "My contracts",
    "menu.sort.contracts": "Contract assignment",
    "menu.winners.trip": "Winners' Prize",
    "menu.points.account": "Points account",
    "menu.shopping.cart": "Cart",
    "menu.order.status": "Order status",
    "menu.wishlist": "Wish list",
    "menu.news": "News",
    "menu.award.shop": "Reward Shop",
    "menu.gamerules": "The rules of the game",
    "menu.search": "Search",
    // APP
    "app.more.info": "More information",
    "app.less.info": "Less information",
    "app.currency": "Dollar",
    "app.currency_points": "Dollar",
    "app.sales": "Sales",
    "app.profit": "Profit",
    "app.points": "PTS",
    "app.contracts": "Contracts",
    "app.contracts.number": "Number of contracts",
    "app.contracts.number.previous": "Number of contracts in the previous year",
    "app.sales.previous": "Sales in the previous year",
    "app.goals": "Goal",
    "app.goals.target": "Your goal target:",
    "app.days": "Days",
    "app.hours": "Hours",
    "app.mins": "Minutes",
    "app.award.news": "Premium novelties - already discovered?",
    "app.award.highlights": "Award Highlights",
    "app.remaining.time": "Remaining time until end of action",
    "app.your.performance": "Your performance compared to the target",
    "app.your.target": "Your goal achievement compared to the previous year",
    "app.your.target.reached": "Congratulations, you have reached your goal! Strong performance, keep it up!",
    "app.lets.go": "Let's go, you are still missing ",
    "app.next.win": " to the next winning level.",
    "app.attack": "Attack! Beat your targets",
    "app.goal": "Your goal: Be better than last year!",
    "app.you.achieved": "You have already done that:",
    "app.win.levels": "Win levels - What you can win",
    "app.video.no.support": "Your browser does not support the video tag. Please upgrade your browser.",
    //404
    "page.404.headline": "OOH NOO ...",
    "page.404.subline": "Something is not working.",
    "page.404.info": "Either there was a terrible error or the page does not exist. But don't worry - we will help you!",
    "page.404.action.home": "To the homepage",
    "page.404.action.contact": "Contact us",
    "page.404.domain.headline": "Vielleicht vertippt?",
    "page.404.domain.info": "Bitte überprüfen Sie die eingegebene URL. So ungefähr muss sie lauten:",
  },

  // ===== FR =====
  "fr": {
    // Login
    "login.h1": "Bienvenue",
    "login.h2.subline": "FORD HEROSPLACE",
    "login.h2.login": "INSCRIPTION",
    "login.label.username": "Nom d'utilisateur",
    "login.input.username": "Nom d'utilisateur",
    "login.label.password": "Mot de passe",
    "login.input.password": "Mot de passe",
    "login.button": "Login",
    "login.forgotten.password": "Mot de passe oublié",
    "login.error.info": "Erreur. Veuillez vérifier les données ou contacter le support",
    "login.new.password": " Entrez-en un nouveau",
    "login.contact.support": "Erreur de login ? Vérifiez vos données d'accès que vous avez reçues par e-mail. Ou contactez le support :",
    "login.service.mail": "service@salesattack.de",
    "login.data.protection": "Protection des données",
    "login.imprint": "Imprimer",
    "login.imprint": "Contact",
    "login.password_policy": "Entrez un nouveau mot de passe. Celui-ci doit contenir au moins 8 caractères, majuscules et minuscules, et au moins un chiffre.",
    "login.error.mail": "Erreur - L'adresse e-mail est manquante.",
    "login.error.password_policy": "Le mot de passe sélectionné n'est pas conforme aux règles de sécurité. Le mot de passe doit être composé d'au moins 8 caractères et contenir des lettres majuscules et minuscules et au moins un chiffre.",
    "login.error.password_mismatch":"Mots de passe non identiques!",
    "login.error.password_is_empty":"Erreur - Mot de passe manquant ",
    "login.password_reset.headline":"Réinitialiser le mot de passe",
    "login.password_reset.description": "Vous avez ici la possibilité de réinitialiser votre mot de passe. Lorsque vous confirmerez votre adresse e-mail, il vous sera demandé de saisir un nouveau mot de passe.",
    "login.password_reset.email_success": "Nous vous avons envoyé un lien pour changer votre mot de passe par e-mail.",
    "login.password_reset.success": "Super ! Le mot de passe a été réinitialisé avec succès.",
    "login.userdata.headline": "Données",
    "login.tos.headline": "Conditions de participation",

    // Global
    "global.close.info": " Fermer l'info",
    "global.open.info": "Plus d'informations",
    "global.rules": "Les règles du jeu",
    "global.rules.short": "Les règles du jeu",
    "global.points.account": "Compte de points",
    "global.award.overview": "Recompense",
    "global.recommendation": "Recommandations",
    "global.loading": "Update…",
    "global.your.cart": "Panier de commande",
    "global.your.order": "Commande",
    "global.less.info": "Moins d'info",
    "global.back_to_login": "Login",
    "global.next": "Suivant",
    "global.account.heading": "Pointage",
    "global.account.date": "Date",
    "global.account.desc": "Description",
    "global.account.points": "Points",
    "global.account.sum": "Total de la période",
    "global.account.old.points": "Balance des comptes act.",
    "globale.change.products": "Recompense",
    "global.order.view": "Aperçu de la commande",
    "global.order.button.view": "Aperçu de la commande",
    "global.back.to.homepage": "Retour à la page d'accuei",
    "global.confirmation.heading": "Merci beaucoup, nous avons reçu la commande",
    "global.confirmation.text": "Vous recevrez une confirmation de commande par e-mail dans les prochaines minutes. Si vous avez des questions concernant votre commande, veuillez vérifier le statut de votre commande sous Menu / Statut de la commande. Vous pouvez y vérifier à tout moment l'état actuel de la livraison de votre commande. Amusez-vous avec votre prime.",
    "global.no.orders": "Pas de commande disponible",
    "global.switch.to.products": "Recompense",
    "global.wishlist.page.hint": "Avec l'aide de la liste de souhaits, vous pouvez économiser des recompense de façon permanente. Pour ce faire, placez l'incitation sur la liste de souhaits",
    "global.wishlist.page.remove.all": "Supprimer",
    "global.wishlist.page.add.all": "Ajouter tous les articles au panier",
    "global.wishlist.page.empty": "Liste de souhaits vide",
    "global.wishlist.page.button.cart": "Retour au panier d'achat",
    "global.countries.germany": "Allemagne",
    "global.countries.belgium": "Belgique",
    "global.countries.denmark": "Danemark",
    "global.countries.france": "Francia",
    "global.countries.uk": "Grande Bretagne",
    "global.countries.italy": "Italie",
    "global.countries.luxembourg": "Luxembourg",
    "global.countries.netherlands": "Pays-Bas",
    "global.countries.norway": "Norvège",
    "global.countries.austria": "Autriche",
    "global.countries.poland": "Pologne",
    "global.countries.romania": "Roumanie",
    "global.countries.sweden": "la Suède",
    "global.countries.switzerland": "Suisse",
    "global.countries.czech": "République Tchèque",
    "global.countries.hungary": "Hongrie",
    "global.countries.usa": "Etats-Unis",

    // CART
    "cart.variant.label": "Variant: ",
    "cart.wishlist.button.add": " Liste de souhaits",
    "cart.button.product.remove": "Supprimer",
    "cart.product.available": "Article immédiatement disponible",
    "cart.product.soon.available": "L'article est disponible à partir de ",
    "cart.popup.sum": "Total: ",
    "cart.popup.first": "Panier de commande",
    "cart.popup.second": "Articles",
    "cart.credit.box.text1": "Tu manques toujours",
    "cart.credit.box.text2": "points. Vous pouvez acheter les points manquants pour cette commande pour",
    "cart.credit.box.text3": "€",
    "cart.bank.recent": "Balance des comptes",
    "cart.credit.box.shipping": "Frais d'expédition et de douane incl.",
    "cart.credit.box.buttun.send": "A la commande",
    "cart.credit.box.button.more": "Plus de recompense",

    // CHECKOUT
    "checkout.form.adress": "Adresse de livraison choisie",
    "checkout.adress.button.change": "Modifier l'adresse",
    "checkout.adress.button.safe": "Enregistrer la nouvelle adresse",
    "checkout.adress.button.cancel": "Avorter",
    "checkout.choose.adress": "Choisissez votre adresse",
    "checkout.choose.adress.button.send": "Envoyer à cette adresse",
    "checkout.choose.adress.button.new": "Ajouter une nouvelle adresse",
    "checkout.qty.label": "Numéro",
    "checkout.qty.products": "Nombre d'objets: ",
    "checkout.hint.check": "Vérifiez la commande et confirmez en cliquant sur Envoyer la commande",
    "checkout.overview.adress": "Adresse de livraison",
    "checkout.mail.check": "Vous recevrez une confirmation par e-mail à l'adresse suivante: ",

    "checkout.back.to.cart": "Retour au panier d'achat",
    "checkout.change.adress.button": "Modifier l'adresse de livraison",
    "checkout.agb.check1": "J'accepte les ",
    "checkout.agb.check2": "conditions de participation",
    "checkout.agb.check3": "de Buben & Mädchen GmbH.",
    "checkout.load.points.button": "Rechargez les points",
    "checkout.contact.button": "Pour contacter le formulaire",
    "checkout.confirm.order.button": "Envoyer la commande",
    "checkout.no.orders.possible": "Sie können aktuell keine Prämienartikel bestellen, da Sie einer Bestellsperre unterliegen. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "checkout.no.orders.hint": "Bitte beachten Sie, dass aktuell keine Prämienbestellungen möglich sind. Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.",
    "checkout.modal.no.points": "Points manquants",
    "checkout.modal.currency": "€",
    "checkout.modal.currency.long": "EUR",
    "checkout.modal.payment.check": "Co-paiement complet",
    "checkout.modal.transaction.success": "Transaction réussie, ID",
    "checkout.modal.close.button": "Conclure",
    "checkout.modal.step1": "Acheter des points",
    "checkout.modal.step2": "Mode de paiement",
    "checkout.modal.step3": "La transaction a réussi",
    "checkout.breadcrumb.button1": "L'adresse",
    "checkout.breadcrumb.button2": "Aperçu",
    "checkout.breadcrumb.button3": "Confirmation",

    // PROFILE
    "profile.page.heading": "Données personnelles",
    "profile.page.pw.no.empty": "Erreur - Mot de passe manquant ",
    "profile.page.pw.not.same": "Mot de passe faux",
    "profile.page.pw.min.txt": "Le mot de passe sélectionné n'est pas conforme aux règles de sécurité. Le mot de passe doit être composé d'au moins 8 caractères et contenir des lettres majuscules et minuscules et au moins un chiffre.",

    // CONTACT
    "contact.page.choose": "Veuillez choisir:",
    "contact.page.help1": "Question générale",
    "contact.page.help2": "Questions sur les critères de pointage / la comptabilisation des points / les règles du jeu",
    "contact.page.help3": "Questions sur les commandes et les primes",
    "contact.page.help3.special": "Questions sur les commandes et les primes",
    "contact.page.heading": "Sélectionnez le motif de la demande",
    "contact.page.reason": "Motif du contact",
    "contact.page.message": "Message",
    "contact.page.send": "Envoyer",
    "contact.page.info": "Écrivez-nous un e-mail. Nous sommes à votre disposition du lundi au vendredi de 9h00 à 18h00",

    // MENU
    "menu.main.categories": "Catégories principales",
    "menu.highlights": "Highlights",
    "menu.all.novelties": "Afficher toutes les actualités",
    "menu.award.category": "Icentive category",
    "menu.sub.categories": "Sub Category",
    "menu.welcome.text": "Hi!",
    "menu.edit.profile": "Profil",
    "menu.conditions.participation": "Conditions de participation",
    "menu.terms.delivery": "Conditions de livraison",
    "menu.contact": "Contact",
    "menu.logout": "Logout",
    "menu.home": "Home",
    "menu.my.contracts": "Contracts",
    "menu.points.account": "Compte de points",
    "menu.shopping.cart": "Panier de commande",
    "menu.order.status": "Statut de la commande",
    "menu.wishlist": "Liste de souhaits",
    "menu.news": "News",
    "menu.award.shop": "Shop",
    "menu.search": "Recherche",

    // SHOP
    "shop.delivery1.msg1": "Article livrable de suite ",
    "shop.delivery1.msg1.day": " Semaines",
    "shop.delivery2.msg2": "Article livrable de suite ",
    "shop.delivery2.msg2.day": " Jours ouvrables",

    "global.empty.table": "Aucune entrée disponible",
    "global.cart.empty": "Votre panier est vide",
    "global.wishlist.button": "Voir ma liste de souhaits",
    "global.button.go.to.contact": "Pour contacter le formulaire",
    "global.key.new": "NEW",
    "global.something.went.wrong": "Oups, quelque chose s'est mal passé.",
    "global.no.orders.in.time": "Il n'y a pas de commandes dans la période.",
    "global.accordion.orders.heading": "Filtre de période",
    "global.flatpicker.start": "de",
    "global.flatpicker.end": "jusqu'à",
    "global.flatpicker.date": "Choisissez la date..",
    "global.flatpicker.button": "Recharger",
    "global.profile.page.heading": "Votre profil",
    "cart.no.orders": "Vous ne pouvez actuellement pas commander d'articles bonus car vous êtes soumis à un blocage de commande. Si vous avez des questions à ce sujet, veuillez contacter votre personne de contact responsable.",
    "cart.hint": "Veuillez noter qu'aucune commande de récompenses n'est actuellement possible. Si vous avez des questions à ce sujet, veuillez contacter votre personne de contact responsable.",
    "cart.qty": "Numéro: ",
    "cart.no.available": "L'article n'est pas disponible !",
    "cart.wishlist.button.remove": "Supprimer de la liste de souhaits",
    "cart.order.heading": "Statut de la commande",
    "cart.track.order.button": "Suivi de l'expédition",
    "cart.popup.delivery": "Livraison",
    "profile.page.pw.now": "Veuillez saisir votre mot de passe actuel",
    "contact.page.success": "Le message a été envoyé avec succès!",
    "contact.page.message.placeholder": "Mesdames et Messieurs..",
    "contact.page.order.nr": "Numéro de commande (TB.xxx) ou numéro d'article (BM-xxx)",
    "menu.ratings": "Les critères de pointage",
    "menu.ranking": "Classement",
    "app.new": "NEW",
    "app.read.on": "continuer la lecture",
    "app.read.all.news": "Lire toutes les actualités",
    "app.customer": "Concessionnaire",
    "app.customer.placeholder": "Concessionnaire",
    "app.customer.nr": "N° de concessionaire",
    "app.profile.pic.heading": "Image de profil",
    "app.profile.pic.change": "Cliquez pour changer ...",
    "app.profile.pic.drop": "Faites glisser votre image dans cette zone...",
    "app.profile.pic.delete": "Supprimer la photo de profil",
    "app.profile.pic.safe": "Enregistrer la photo de profil",
    "app.profile.adress.new.safe": "Enregistrer la nouvelle adresse",
    "app.award.code.redeem": "Racheter",

    // APP
    "app.more.info": " + Infos",
    "app.less.info": " - Infos",
    "app.currency": "Euro",
    "app.currency_points": "Unités",
    "app.profit": "Avantages",
    "app.points": "PTS",
    "app.contracts": "Contracts",
    "app.contracts.number": "Points du contrat",
    "app.days": "Jo",
    "app.hours": "Hrs",
    "app.mins": "Min",
    "app.award.news": "Nouveaux hits",
    "app.award.highlights": "Incentive Highlights",
    "app.video.no.support": "Votre browser ne supporte pas la vidéo. Veuillez actualiser votre browser.",
    "app.campaign.end": "FORD Herosplace Timer",
    "app.sales.performance": "Scoreboard",
    "app.sales.national": "National",
    "app.sales.per.contract": "Points du contrat",
    "app.sales.status": "Score",
    "app.your.place": "Position",
    "app.current.level": "Incentive Level",
    "app.win.level": "Niveaux gagnants",
    "app.place": "Position",
    "app.overall.ranking": "Au classement général",
    "app.contracts.closed": "Points du contrat",
    "app.herosplace.ranking": "HEROSPLACE RANKING",
    "app.back.win.level": "Niveaux gagnants",
    "app.to.overall.ranking": "Au classement général",
    "app.award.search": "Recherche de récompense",
    "app.award.search.keyword": "Terme de recherche",
    "app.award.search.points.from": "Points min",
    "app.award.search.points.to": "Points max",
    "app.award.search.start": "Lancer la recherche",
    "app.point":  "Points",
    "app.currency.icon": "€",
    "app.information": "Informations",
    "app.plus": "PLUS",
    "app.back.to.overview": "Retour à l'aperçu",
    "app.category": "Catégories",
    "app.award.variant": "Variante de bonus",
    "app.put.in.cart": "Dans le panier",
    "app.award.despcription": "Description du prix",
    "app.main.category": "Catégorie principale",
    "app.sub.category": "Sous-catégorie",
    "app.choose.main.category": "Veuillez sélectionner une catégorie principale.",
    "app.shop.search": "Chercher",
    "app.shop.sort.for": "Trier par",
    "app.shop.sort.for.newest": "Dernières récompenses",
    "app.shop.sort.for.az": "Alphabétiquement A-Z",
    "app.shop.sort.for.za": "Alphabétiquement Z-A",
    "app.password.not.match": "Mot de passe faux",
    "app.sign.up.step.one": "Step 1/2",
    "app.sign.up.declare": " J'accepte ",
    "app.sign.up.agree": " de Buben & Mädchen GmbH.",
    "app.sign.up.just_agree": " accepter. ",
    "app.continue": "Suivant",
    "app.privacy.policy": "Réglementation de protection des données",
    "app.privacy.disclaimer": "J'accepte que mes données personnelles soient traitées par le bureau indiqué dans la réglementation sur la protection des données dans le cadre de ma participation au programme susmentionné aux fins de la participation à la incitation. Je suis conscient que seules les données personnelles nécessaires à la participation à l'incitation seront traitées. Les données correspondantes seront fournies par moi-même. Je suis également conscient que je peux révoquer mon consentement à tout moment en faisant une simple déclaration au bureau indiqué dans le réglementation sur la protection des données. Je dispose également des droits prévus au chapitre trois du DS-GVO, à savoir le droit à l'information, à la rectification, à l'effacement, à la portabilité des données, à la limitation du traitement, à l'objection et à l'introduction d'une plainte auprès d'une autorité de contrôle. ",
    "app.sign.up.step.two": "Step 2/2",
    "app.company": "Enterprise",
    "app.company.placeholder": "Garage Xyz",
    "app.salutation": "Titre",
    "app.mr": "M.",
    "app.ms": "Mme.",
    "app.dr": "Dr",
    "app.firstname": "Prénom",
    "app.lastname": "Nom",
    "app.firstname.placeholder": "Max",
    "app.lastname.placeholder": "Mustermann",
    "app.street": "Rue et n° de maison",
    "app.street.placeholder": "Musterstraße",
    "app.plz": "CP*",
    "app.plz.placeholder": "12345",
    "app.city": "Ville*",
    "app.city.placeholder": "Ville",
    "app.country": "Pays*",
    "app.country.placeholder": "Pays",
    "app.birthday": "Date de naissance",
    "app.birthday.placeholder": "01.03.1960",
    "app.email_short": "E-Mail",
    "app.email": "E-Mail adress*",
    "app.email_your": "E-Mail adress*",
    "app.email.placeholder": "m.mustermann@muster.ch",
    "app.phone": "Tél.",
    "app.phone.placeholder": "079 000 555",
    "app.handy": "Numéro de portable",
    "app.handy.placeholder": "079 000 555",
    "app.customer.nr.placeholder": "123-456-789",
    "app.agree.checkbox": "J'accepte de recevoir régulièrement des informations sur le programme.",
    "app.profile.add.adress": " Ajouter une nouvelle adresse",
    "app.profile.all.adresses": "Vos adresses de livraison",
    "app.profile.adress.phone": "Tél.:",
    "app.profile.adress.mail": "E-Mail:",
    "app.profile.adress.settings": "Modifier",
    "app.profile.adress.remove": "Supprimer",
    "app.profile.adress.change": "Modifier l'adresse",
    "app.profile.adress.new": "Ajouter une nouvelle adresse",
    "app.profile.adress.changes.safe": "Confirmez les données",
    "app.profile.login.heading": "Données Login",
    "app.profile.login.current.pw": "Mot de passe act.",
    "app.profile.login.new.pw": " Nouveau mot de passe",
    "app.profile.login.new.pw.same": "Veuillez répéter votre mot de passe",
    "app.profile.login.new.pw.safe": "Enregistrez le nouveau mot de passe",
    "app.safe.data": "Confirmez les données",
    "app.personal.data": "Données personnelles",
    "app.sign.up.complete": "Inscription - veuillez compléter les données",
    "app.sign.up.check.data": "Dernière étape : vous pouvez ici consulter, vérifier et aussi corriger vos données. Si vous changez de prénom ou de nom, veuillez contacter notre équipe de support. Veuillez également changer à nouveau votre mot de passe ici.",
    "app.new.password_short": " Nouveau mot de passe",
    "app.new.password": " Votre nouveau mot de passe",
    "app.new.password_save": "Enregistrez le nouveau mot de passe",
    "app.new.password.repeat": "Veuillez répéter votre mot de passe",
    "app.award.code": "Code de récompense",
    "app.award.code.placeholder": "Code",
    "app.forgot.password": "Mot de passe oublié",
    "app.news.no.heading": "Pas de nouvelles actualités",
    "app.news.no.button": "Retour à la liste des récompenses",

    //404
    "page.404.headline": "OH NO ...",
    "page.404.subline": "Une erreur.",
    "page.404.info": "Soit il y a eu une erreur, soit la page n'existe pas. Mais ne vous inquiétez pas, nous vous aiderons!",
    "page.404.action.home": "Page d'accueil",
    "page.404.action.contact": "Contact",
    "page.404.domain.headline": "Peut-être une erreur de frappe?",
    "page.404.domain.info": "Veuillez vérifier l'URL que vous avez saisie. Il devrait se lire approximativement comme suit",
  },
}

function t(key) {
  const lang = window.currentLang !== undefined ? window.currentLang : 'de';
  if (typeof t9n[lang] === 'undefined') return key;
  const s = t9n[lang][key];
  return (typeof s !== 'undefined')
    ? (s)
    : key
}

export default t;

export function countriesOptions() {
  return {
    'DE': t("global.countries.germany"),
    'BE': t("global.countries.belgium"),
    'DK': t("global.countries.denmark"),
    'FR': t("global.countries.france"),
    'UK': t("global.countries.uk"),
    'IT': t("global.countries.italy"),
    'LU': t("global.countries.luxembourg"),
    'NL': t("global.countries.netherlands"),
    'NO': t("global.countries.norway"),
    'AT': t("global.countries.austria"),
    'PL': t("global.countries.poland"),
    'RO': t("global.countries.romania"),
    'SE': t("global.countries.sweden"),
    'CH': t("global.countries.switzerland"),
    'CZ': t("global.countries.czech"),
    'HU': t("global.countries.hungary"),
    'US': t("global.countries.usa"),
  }
}

