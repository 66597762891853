import React from "react";
import { useParams } from "react-router-dom";
import HappyPraemien from "./HappyPraemien/HappyPraemien";

const HappyPraemienPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <HappyPraemien lang={lang} />
    </>
  )
}

export default HappyPraemienPage;
