import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import rocket from "../../../assets/lotties/feuerfrei/anim--feuerfrei-rocket";
import { AppContext } from "../../../data/ContextProvider";
import { withRouter } from "react-router-dom";
import { getAssetSlider, getContentPage } from "../../../data/api";
import { CONTENT_SPIELREGELN } from "../../../data/constants";
import t from "../../../helpers/t9n";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";

class AppFeuerfreiHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      loading: true,
      myPoints: 0,
      teaser_frontpage: "",
      headerSlider: []
    }
  }

  async componentDidMount() {
    const myPoints = this.context.getPointsOfUser()
    const headerSlider = await getAssetSlider('header')

    console.log(myPoints)
    getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id).then(res => {
      let text = ""
      if (res.text) {
        text = res.text
      } else {
        text = t("app.monthly.award.points")
      }

      this.setState({
        myPoints: myPoints,
        text: text,
        loading: false,
        headerSlider: headerSlider.slider
      })
    })
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    return (
      <>
        {this.state.headerSlider.length > 0 &&
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        }

        <>
          <div
            className={`app-header__section feuerfrei-header__section ${this.state.isOpen ? "app-header__section--open" : ""}`}>
            <div className={"app-header__content"}>
              <div className={"app-header__left"}>
                <span className={"app-header__heading"}>
                  <div>
                    <span>
                      <Icon icon={"app-feuerfrei"} />
                    </span>
                    <h1>{this.state.text.p33_header_content_headline}</h1>
                  </div>
                  <h2>{this.state.text.p33_header_content_subline}</h2>
                </span>

                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.text.p33_upper_content_text ? this.state.text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                  }}>
                </p>

                <span className={"app-header__actions"}>
                  {this.state.isOpen ?
                    <Button type={"link"} text={t("global.close.info")} textColor={"white"} icon={"arrow"} onClick={() => {
                      this.setState({ isOpen: !this.state.isOpen })
                    }} />
                    :
                    <Button type={"link"} text={t("global.open.info")} textColor={"white"} icon={"arrow"} onClick={() => {
                      this.setState({ isOpen: !this.state.isOpen })
                    }} />
                  }
                  <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                          onClick={() => {
                            this.props.history.push(`/${window.currentLang}/rules`)
                          }} />
                </span>
              </div>
              <div className={"app-header__right"}>
                <div className={"jackpot-counter"}>
                  <span className={"jackpot-counter__icon"}>
                    <Icon icon={"coins"} />
                  </span>
                  <h2>{t("app.my.points")}</h2>
                  <span className={"jackpot-counter__wrapper"}>
                    <CounterSpawner amount={Number(this.state.myPoints)} type={"highlight"} />
                    <h5>{t("app.points")}</h5>
                  </span>
                </div>
              </div>
            </div>
            <span className={"app-header__box"}>
              <UncontrolledLottie animationData={rocket} />
            </span>
          </div>
          <div className={"feuerfrei-counter__section"}>
            <span className={"app-header__actions app-header__actions--mobile"}>
              <Button type={"secondary"} text={`${this.state.isOpen ? t("app.less.info") : t("app.more.info")}`}
                      textColor={"black"} backgroundColor={"bg-white"}
                      onClick={() => {
                        this.setState({ isOpen: !this.state.isOpen })
                      }} />
            </span>
            <div className={"jackpot-counter"}>
              <span className={"jackpot-counter__icon"}>
                <Icon icon={"coins"} />
              </span>
              <h2>{t("app.my.points")}</h2>
              <span className={"jackpot-counter__wrapper"}>
                <CounterSpawner amount={Number(this.state.myPoints)} type={"highlight"} />
                <h5>{t("app.points")}</h5>
              </span>
            </div>
          </div>
        </>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}

AppFeuerfreiHeader.contextType = AppContext

export default withRouter(AppFeuerfreiHeader);
