import React, { Component } from "react";
import Tachometer from "../../../components/element/tachometer/Tachometer";
import { getAssetSlider } from "../../../data/api";
import Button from "../../../components/atom/button/Button";
import t from "../../../helpers/t9n";
import Icon from "../../../components/atom/icons/Icon";
import ProgressCircle from "../../../components/element/progress-circle/ProgressCircle";

class SalesTriathlonGoals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      headerSlider: [],
      startAnimation: true
    };
  }

  animateValue = (elem, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      if (start === 0 && end === 0) {
        elem.innerHTML = Math.floor(progress * (end - start) + start) + "%";
      } else {
        elem.innerHTML = Math.floor(progress * (start / end) * 100) + "%";
      }
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);

    this.setState({
      startAnimation: false
    })
  }

  animateNeedle = (elem, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      if (Math.floor(progress * (start / end) * 100 * 1.8) > 180) {
        elem.style.transform = `rotate(180deg)`;
      } else {
        elem.style.transform = `rotate(${Math.floor(progress * (start / end) * 100 * 1.8)}deg)`;
      }
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);

    this.setState({
      startAnimation: false
    })
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider("header");

    this.setState({
      headerSlider: headerSlider.slider,
      loading: false,
    });
  };

  isInViewport = () => {
    if (this.divRefTop) {
      const top = this.divRefTop.getBoundingClientRect().top;
      if (top <= 800){
        if (this.state.startAnimation) {
          const firstMonthVal = document.querySelector('#first-month .salestriathlon-goals__percent h3');
          this.animateValue(firstMonthVal, 173, 150, 5000);
          
          const firstMonthLine = document.querySelector('#first-month .tachometer .line');
          this.animateNeedle(firstMonthLine, 173, 150, 5000);

          const secondMonthVal = document.querySelector('#second-month .salestriathlon-goals__percent h3');
          this.animateValue(secondMonthVal, 40, 100, 5000);
          
          const secondMonthLine = document.querySelector('#second-month .tachometer .line');
          this.animateNeedle(secondMonthLine, 40, 100, 5000);

          const thirdMonthVal = document.querySelector('#third-month .salestriathlon-goals__percent h3');
          this.animateValue(thirdMonthVal, 0, 0, 5000);
          
          const thirdMonthLine = document.querySelector('#third-month .tachometer .line');
          this.animateNeedle(thirdMonthLine, 0, 0, 5000);

          const allMonthsVal = document.querySelector('#all-months .salestriathlon-goals__percent h3');
          this.animateValue(allMonthsVal, 173, 450, 5000);
          
          const allMonthsLine = document.querySelector('#all-months .tachometer .line');
          this.animateNeedle(allMonthsLine, 173, 450, 5000);
        }
      } 
    }
  }

  async componentDidMount() {
    await this.loadData();

    document.addEventListener("scroll", this.isInViewport);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.isInViewport);
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <div className="salestriathlon-goals__section">

        <div className="app-header__actions app-header__actions--mobile">
          <Button
            type="secondary"
            text={`${this.props.isHeaderOpen ? "Weniger Informationen" : "Mehr Informationen"}`}
            textColor="black"
            backgroundColor="bg-white"
            onClick={() => this.props.handleHeader()}
          />
        </div>

        {/* Monatsziele */}
        <div className="salestriathlon-goals__content">
          <div className="salestriathlon-goals__inner salestriathlon-goals__inner--blue">           
            <h2 className="salestriathlon-goals__heading">Deine Monatssziele</h2>

            <div className="salestriathlon-goals__container">
              <div className="salestriathlon-goals__single salestriathlon-goals__single--finished">
                <div className="salestriathlon-goals__head">
                  <h3>Erster Monat</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  <p>Schließe</p>
                  <h2>150</h2>
                  <p>Verträge ab!</p>
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
                <div className="salestriathlon-goals__center">
                  <h3>Deine Belohnung</h3>
                </div>
                <div className="salestriathlon-goals__lower">
                  <h2>1.500 <span>Pkt</span></h2>
                  <Icon icon="wusch" />
                </div>
              </div>

              <div className="salestriathlon-goals__single salestriathlon-goals__single--current">
                <div className="salestriathlon-goals__head">
                  <h3>Zweiter Monat</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  <p>Schließe</p>
                  <h2>150</h2>
                  <p>Verträge ab!</p>
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
                <div className="salestriathlon-goals__center">
                  <h3>Deine Belohnung</h3>
                </div>
                <div className="salestriathlon-goals__lower">
                  <h2>1.500 <span>Pkt</span></h2>
                  <Icon icon="wusch" />
                </div>
              </div>

              <div className="salestriathlon-goals__single">
                <div className="salestriathlon-goals__head">
                  <h3>Dritter Monat</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  <p>Schließe</p>
                  <h2>150</h2>
                  <p>Verträge ab!</p>
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
                <div className="salestriathlon-goals__center">
                  <h3>Deine Belohnung</h3>
                </div>
                <div className="salestriathlon-goals__lower">
                  <h2>1.500 <span>Pkt</span></h2>
                  <Icon icon="wusch" />
                </div>
              </div>
            </div>
          </div>
          {/* Monatsziele */}

          {/* Zielerreichung */}
          <div ref={(elem) => this.divRefTop = elem} className="salestriathlon-goals__inner salestriathlon-goals__inner--blue salestriathlon-goals__inner--accomplished">
            <h2 className="salestriathlon-goals__heading">Deine Zielerreichung</h2>

            <div className="salestriathlon-goals__container">
              <div className="salestriathlon-goals__single salestriathlon-goals__single--finished">
                <div className="salestriathlon-goals__head">
                  <h3>Erster Monat</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  <h2>173</h2>
                  <p>Verträge</p>
                </div>
                <div id="first-month" className="salestriathlon-goals__center">
                  <Tachometer />
                  <div className="salestriathlon-goals__percent">
                    <h3>0 %</h3>
                  </div>
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
              </div>

              <div className="salestriathlon-goals__single salestriathlon-goals__single--current">
                <div className="salestriathlon-goals__head">
                  <h3>Zweiter Monat</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  <h2>40</h2>
                  <p>Verträge</p>
                </div>
                <div id="second-month" className="salestriathlon-goals__center">
                  <Tachometer />
                  <div className="salestriathlon-goals__percent">
                    <h3>0 %</h3>
                  </div>
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
              </div>

              <div className="salestriathlon-goals__single">
                <div className="salestriathlon-goals__head">
                  <h3>Dritter Monat</h3>
                </div>
                <div className="salestriathlon-goals__upper">
                  <h2>0</h2>
                  <p>Verträge</p>
                </div>
                <div id="third-month" className="salestriathlon-goals__center">
                  <Tachometer />
                  <div className="salestriathlon-goals__percent">
                    <h3>0 %</h3>
                  </div>
                  <span className="thumbsup"><Icon icon="thumbsup" /></span>
                </div>
              </div>
            </div>
          </div>
          {/* Zielerreichung */}

          {/* Übersicht */}
          <div className="salestriathlon-goals__inner salestriathlon-goals__inner--grey">
            <h2 className="salestriathlon-goals__heading">Dein Gesamtziel der Aktion</h2>

            <div className="salestriathlon-goals__info">

              <div className="salestriathlon-goals__sum">
                <div className="salestriathlon-goals__left">
                  <div className="salestriathlon-goals__box">
                    <div className="salestriathlon-goals__top">
                      <h2>450 <span>Verträge</span></h2>
                    </div>
                    <div className="salestriathlon-goals__bottom">
                      <h2>4.500 <span>Pkt</span></h2>
                    </div>
                  </div>
                </div>

                <div className="salestriathlon-goals__right">
                  <p>Solltest Du ein Monatsziel nicht erreichen, kannst Du trotzdem noch die volle Punktzahl gewinnen, wenn Du am Ende der Aktion Dein Gesamtziel (Summe aller Monate) erreichst.</p>
                  <p>In diesem Sinne:</p>
                  <h3>Gib Alles!</h3>
                </div>
              </div>

            </div>
          </div>
          {/* Übersicht */}

          {/* Restzeit */}
          <div className="salestriathlon-goals__inner salestriathlon-goals__inner--blue salestriathlon-goals__inner--accomplished">
            <h2 className="salestriathlon-goals__heading">Deine Gesamtziel-Erreichung</h2>

            <div className="salestriathlon-goals__performance">

              <div className="salestriathlon-goals__performance-left">
                <div className="salestriathlon-goals__performance-top">
                  <div className="salestriathlon-goals__contracts">
                    <h2>173</h2>
                    <p>Verträge</p>
                  </div>
                </div>

                <div id="all-months" className="salestriathlon-goals__performance-bottom">
                  <Tachometer />
                  <div className="salestriathlon-goals__percent">
                    <h3>0 %</h3>
                  </div>
                </div>
              </div>

              <div className="salestriathlon-goals__performance-right">
                <div className="salestriathlon-goals__bigbox">
                <h3>{t("app.remaining.time")}</h3>
                <div className="salestriathlon-goals__circles">
                  <ProgressCircle 
                    value={0} 
                    maxValue={1}
                    isCountDown={true} 
                    text={t("app.days")} 
                  />
                  <ProgressCircle 
                    value={0} 
                    maxValue={24} 
                    text={t("app.hours")} 
                  />
                  <ProgressCircle 
                    value={0} 
                    maxValue={60} 
                    text={t("app.mins")} 
                  />
                </div>
                </div>
              </div>

            </div>
          </div>
          {/* Restzeit */}

        </div>
      </div>
    );
  }
}

export default SalesTriathlonGoals;