import axios from 'axios'

import { API_HOST, API_HOST_LOGIN } from "./config";
import { getToken, saveToken, saveManagerData } from "../lib/lib";


//User Section
export async function managerLogin(email, password) {
  const payload = {
    "manager": {
      "email": email,
      "password": password
    }
  }

  const response = await axios.post(
    `${API_HOST}/manager_login`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
  .catch(error => {
    throw (error.response)
  })

  saveToken(response.headers['authorization'])
  saveManagerData(response.data)

  return {
    manager: response.data,
    authorization: response.headers['authorization']
  }
}

export async function managerLogout() {
  await axios.get(
    `${API_HOST}/manager_logout`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
}

export async function resetManagerPassword(data) {
  return axios.post(
    `${API_HOST_LOGIN}manager_reset_password`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function loadCurrentManager() {
  const response = await axios.get(
    `${API_HOST}/ct_managers/current`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}

export async function reloginManager() {

  const response = await axios.get(
    `${API_HOST_LOGIN}manager_relogin`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(error => {
    throw (error.response)
  })
  return response.data
}

export async function updateManager(manager) {
  const response = await axios.put(
    `${API_HOST}/ct_managers/update`,
    { manager: manager },
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    throw error
  });
  return response.data
}

export async function updateManagerPassword(data) {
  return axios.post(
    `${API_HOST}/ct_managers/update_password`,
    data,
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken(),
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function completeOnBoarding() {
  const response = await axios.post(
    `${API_HOST}/ct_managers/complete`,
    { },
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    throw error
  })
  return response.data
}


export async function getTextForKey(key) {
  const response = await axios.get(
    `${API_HOST}/ct_texts/show_by_key/${key}`,
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })

  return response.data
}

export async function getCtUsers(page) {
  const response = await axios.get(
    `${API_HOST}/ct_users?page=${page}`,
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    throw error
  })
  return response.data
}


export async function createCtUser(user) {
  const response = await axios.post(
    `${API_HOST}/ct_users`,
    { user: user },
    {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    throw (error.response.data)
  })
  return response.data
}

export async function uploadCtUserCsv(file) {
  const data = new FormData();

  data.append("file", file)

  return axios.post(
    `${API_HOST}/ct_users/preview`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getToken()
      }
    }
  ).then(response => response)
  .catch(error => {
    throw (error.response)
  })
}

export async function importCtUsers(id) {
  const response = await axios.post(
    `${API_HOST}/ct_users/import/${id}`,
    {}, {
    headers: {
      'Accept': 'application/json',
      'Authorization': getToken()
    }
  }
  ).catch(function (error) {
    throw (error.response)
  });
  return response.data
}

