import React, { Component } from "react";
import { Link } from "react-router-dom";

import UncontrolledLottie from "../../components/atom/lottie/UncontrolledLottie";
import Button from "../../components/atom/button/Button";
import { German } from 'flatpickr/dist/l10n/de.js';
import emptyWish from "../../assets/lotties/empty-wishlist/anim--icon-emtpy-wishlist";
import { AppContext } from "../../data/ContextProvider";
import Flatpickr from "react-flatpickr";
import OrderItem from "../../components/element/order-item/OrderItem";
import { formatDate, formatDateToIsoDate } from "../../lib/lib";
import { getOrders } from "../../data/api";
import Accordion from "../../components/element/accordion/Accordion";
import RangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import t from "../../helpers/t9n";

// import Pagination from "../../components/atom/pagination/Pagination";

class Orders extends Component {

  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      order_sum: {},
      page: 1,
      loading: true,
      // date: [
      //
      // ],
    }
  }

  getDateFilter(idSuffix) {
    // const todayMinus30 = new Date()
    // todayMinus30.setDate(todayMinus30.getDate() - 30)
    // const todayPlus1 = new Date()
    // todayPlus1.setDate(todayPlus1.getDate() + 1)

    return (<div className={"orders__actions"}>
              <span className={"orders__date"}>
                <label>{t("global.flatpicker.start")}</label>
                <Flatpickr options={{
                  mode: "single",
                  dateFormat: "d-m-Y",
                  altInput: true,
                  altFormat: "j F, Y",
                  locale: German,
                  defaultDate: [this.state.date[0], this.state.date[1]],
                  plugins: [RangePlugin({ input: `#secondRangeInput_` + idSuffix })],
                  altInputClass: "textfield__input"
                }} onChange={date => {
                  this.setState({ date: date });
                }} />
              </span>
              <span className={"orders__date"}>
                <label>{t("global.flatpicker.end")}</label>
                <input className={"textfield__input"} type="text" placeholder={t("global.flatpicker.date")}
                       id={`secondRangeInput_${idSuffix}`}
                       data-fp-omit="" readOnly="readonly" />
              </span>
              <Button type={"primary"}
                      textColor={"white"} text={t("global.flatpicker.button")}
                      icon={"reload"} onClick={() => this.loadData()} />
            </div>)
  }

  componentDidMount() {

    const todayMinus30 = new Date()
    todayMinus30.setDate(todayMinus30.getDate() - 30)
    const todayPlus1 = new Date()
    todayPlus1.setDate(todayPlus1.getDate() + 1)

    this.setState({
      date: [
        todayMinus30, todayPlus1
      ]
    }, () => {
      this.loadData()
    })
  }

  loadData = () => {
    const date_from = this.state.date ? formatDateToIsoDate(this.state.date[0]) : ""
    const date_to = this.state.date ? formatDateToIsoDate(this.state.date[1]) : ""

    const orders = getOrders({
      page: this.state.page,
      date_from: date_from,
      date_to: date_to
    })

    const order_sum = {}
    orders.then(orders => {
      orders.orders.forEach((order ) => {
        order_sum[order.number] = 0
        order.position_attributes.forEach((pos) => {
          order_sum[order.number] += (pos.quantity * pos.points)
        })
      })

      this.setState({
        orders: orders.orders,
        order_sum: order_sum,
        hasAnyOrders: orders.meta.total_without_filter > 0,
        loading: false
      })
    })
  }

  render() {
    const orders = this.state.orders;
    const order_sum = this.state.order_sum;

    if (this.state.loading)
      return "Loading.."

    return (
      <>
        <div className={"content orders cart"}>
          <div className={"orders__top--mobile"}>
            {this.state.hasAnyOrders &&
            <Accordion heading={t("global.accordion.orders.heading")}>
              {this.getDateFilter("mobile")}
            </Accordion>
            }
          </div>
          <div className={"orders__container"}>
            {orders.length ?
              <>
                <div className={`orders__left ${this.state.isOneUnavailable ? "orders__left--unavailable" : ""}`}>
                  {
                    orders.map((elem) => {
                      return <OrderItem orderNr={elem.number}
                                        orderPrice={order_sum[elem.number]}
                                        orderDate={formatDate(elem.created_at, false)}
                                        orderId={elem.id}
                                        key={elem.number}
                      />
                    })
                  }
                  {/*<Pagination totalItems={27} itemsPerPage={9} currentPage={1} />*/}
                </div>
                <div className={"orders__right"}>
                  {this.getDateFilter("desktop")}
                </div>
              </>
              :
              <>
              <div className={`orders__left orders__left--empty`}>
                <div className={"orders__empty"}>
                  <span className={"orders__lottie-circle"}>
                    <UncontrolledLottie animationData={emptyWish} />
                  </span>
                  <h2>{this.state.hasAnyOrders ? t("global.no.orders.in.time") : t("global.no.orders")}</h2>
                  <span className={"orders__empty-actions"}>
                    <Link to={`/${window.currentLang}/shop`}>
                      <Button type={"primary"}
                              textColor={"white"}
                              text={t("global.switch.to.products")} />
                    </Link>
                  </span>
                </div>
              </div>
                {this.state.hasAnyOrders &&
                <div className={"orders__right"}>
                  {this.getDateFilter("desktop")}
                </div>
                }
              </>
            }
          </div>
        </div>
      </>
    );
  }
}

Orders.contextType = AppContext;

export default Orders;
