import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "../../../components/atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import UncontrolledLottie from "../../../components/atom/lottie/UncontrolledLottie";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
import t from "../../../helpers/t9n";

class ResetSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={"login__container--right reset-success"}>
        <span className={"reset-success__icon"}>
          <UncontrolledLottie animationData={confetti} />
        </span>
        <h3 className="center-text">{t("login.password_reset.email_success")}</h3>

        <div style={{ marginTop: 30 }}>
          <form name="reset-success-form" className="reset-success__form"
                onSubmit={(e) => this.props.submitFunc(e, 0)}>
            <Button type="primary" textColor="white" text={t("global.back_to_login")} />
          </form>
        </div>

        <span className={"login__links"}>
          <a href={`/${window.currentLang}`}>{t("menu.home")}</a>
          <a href={`/${window.currentLang}/privacy`}>{t("login.data.protection")}</a>
          <a href={`/${window.currentLang}/imprint`}>{t("login.imprint")}</a>
        </span>
      </div>
    );
  }
}

ResetSuccess.contextType = AppContext;

export default withRouter(ResetSuccess);
