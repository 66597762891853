import React from "react";
import Button from "../../../components/atom/button/Button";
import { Link } from "react-router-dom";
import t from "../../../helpers/t9n";

const Confirmation = (props) => {
  if (props.error) {
    return (
      <div className={`checkout__center checkout__center--error`}>
      <h3>{t("global.something.went.wrong")}</h3>
      <p>
        <b>{props.error}</b>
      </p>
      <span className="checkout__center-actions">
        <Link to={`/${window.currentLang}/`}>
          <Button text={t("global.back.to.homepage")} type={"primary"} textColor={"white"} />
        </Link>
        <Link to={`/${window.currentLang}/orders`}>
          <Button text={t("global.order.button.view")} type={"secondary"} textColor={"black"} />
        </Link>
      </span>
    </div>
    );
  }

  return (
    <div className={`checkout__center`}>
      <h3>{t("global.confirmation.heading")}</h3>
      <p>{t("global.confirmation.text")}</p>
      <span className="checkout__center-actions">
        <Link to={`/${window.currentLang}/`}>
          <Button text={t("global.back.to.homepage")} type={"primary"} textColor={"white"} />
        </Link>
        <Link to={`/${window.currentLang}/orders`}>
          <Button text={t("global.order.button.view")} type={"secondary"} textColor={"black"} />
        </Link>
      </span>
    </div>
  );
}

export default Confirmation;
