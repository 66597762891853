import React, { Component } from "react";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import CardSlider from "../../components/module/card-slider/CardSlider";
import { getCasino, getContentPage, spinCasinoBandit } from "../../data/api";
import AppCasinoHeader from "../../components/module/app-casino-header/AppCasinoHeader";
import AppCasinoRoom from "../../components/module/app-casino-room/AppCasinoRoom";
import { AppContext } from "../../data/ContextProvider";
import { CONTENT_SPIELREGELN } from "../../data/constants";
import axios from "axios";
import t from "../../helpers/t9n";

class Casino extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      slots: 0,
      isHeaderOpen: true,
      isSpinning: false,
      pageText: null,
    }

    this.timerHandle = null
  }

  loadData = async () => {
    axios.all([getCasino(), getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)])
      .then(axios.spread((casino, page) => {
        this.setState({
          slots: casino.slots,
          points_safe: casino.points_safe,
          loading: false,
          pageText: page.text,
        })
      }))
  }

  componentDidMount() {
    this.loadData()
  }

  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle)
  }

  handleBanditSpin = () => {
    if (this.state.slots > 0) {
      this.setState({
        isSpinning: !this.state.isSpinning
      }, () => {
        spinCasinoBandit().then(spin => {
          this.timerHandle = setTimeout(() =>
            this.setState({
              isSpinning: !this.state.isSpinning,
              booking_amount: spin.booking_amount,
              slots: spin.slots
            }, () => {
              this.context.setPointsOfUser(spin.points)
              this.loadData()
            }), 1000)
        })
      })
    }
  }

  handleHeader = () => {
    this.setState({
      isHeaderOpen: !this.state.isHeaderOpen
    })
  }

  render() {

    if (this.state.loading)
      return t("global.loading")

    return (
      <>
        <div className={"content app neukundenjaeger"}>
          <AppCasinoHeader handleHeader={this.handleHeader}
                           slots={this.state.slots}
                           isHeaderOpen={this.state.isHeaderOpen}
                           pageText={this.state.pageText}
          />
          <AppCasinoRoom handleHeader={this.handleHeader}
                         isSpinning={this.state.isSpinning}
                         slots={this.state.slots}
                         banditValue={this.state.booking_amount}
                         safe={this.state.points_safe}
                         isHeaderOpen={this.state.isHeaderOpen}
                         handleSpinBandit={this.handleBanditSpin} />
          <SectionRecommendations label={t("app.award.highlights")} />

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>
        </div>
      </>
    );
  }
}

Casino.contextType = AppContext

export default Casino;
