import React from "react";

import "../sass/styles.sass";
import Navbar from "../components/element/navbar/Navbar";
import Footer from "../components/element/footer/Footer";

function ClubLayout(props) {

  return (
    <React.Fragment>
      <Navbar bgColor={"black"}
              isScrolling={true}
              isClub={props.isClub} />

      <div className={`container container--account`}>
        {props.children}
      </div>

      <Footer />
    </React.Fragment>
  );
}

ClubLayout.defaultProps = {
  isClub: true,
};

export default ClubLayout;
