import React, { Component } from "react";
import PropTypes from "prop-types";

import TinySlider from "../../../vendor/tiny-slider-react";
import Icon from "../../atom/icons/Icon";
import Product from "../../element/product/Product";
import Button from "../../atom/button/Button";
import { Link } from "react-router-dom";
import { getProductsLimited } from "../../../data/api";
import { getToken } from "../../../lib/lib";
import { getProductImage, getMinProductPriceForProject, renderTitle } from "../../../helpers/utils";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";

class CardSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      settings: {
        loop: props.loop ? props.loop : false,
        lazyload: false,
        nav: false,
        mouseDrag: true,
        controls: false,
        gutter: 12,
        slideBy: 1,
        fixedWidth: props.productType === "short" ? 280 : 380,
        autoHeight: false,
        arrowKeys: false,
        startIndex: 0,
        items: props.itemAmount ? props.itemAmount : 4,
        responsive: {
          0: {
            fixedWidth: 280,
          },
          768: {
            fixedWidth: this.props.isNewProductSlider ? 280 : 380,
          }
        }
      },
      loading: true,
      firstElem: true,
      lastElem: false,
      cardOffset: 0,
      cardLimit: 8,
    };
  }

  sliderBreakpoint = (info, eventName) => {
    // direct access to info object
    console.log(info.event.type, info.container.id);
  }

  onGoTo = dir => {
    this.ts.slider.goTo(dir);

    let index = this.ts.slider.getInfo().index;
    let maxSlides = this.ts.slider.getInfo().slideCount;
    let items = this.ts.slider.getInfo().items;

    if (index > 0) {
      this.setState({ firstElem: false });
    } else if (index === 0) {
      this.setState({ firstElem: true });
    }

    if ((index + items) === maxSlides) {
      this.setState({
        lastElem: true,
      });
    } else if ((index + items) < maxSlides) {
      this.setState({ lastElem: false });
    }
  };

  componentWillUnmount() {
    if (this.ts)
      this.ts.slider = null
  }

  renderCard = (card, index) => {
    if (card.options && (card.options.length > 0)) {
      return <div key={index} style={{ position: "relative" }}>
                <Product type={this.props.productType}
                  id={card.id}
                  isWished={card.wish}
                  variantId={card.options[0].id}
                  hasMultipleVariants={(card.options.length > 0)}
                  price={getMinProductPriceForProject(card.options, this.context.getUserData().project_code)}
                  isOnWishlistPage={this.props.isOnWishlistPage}
                  hasWishAction={this.props.hasWishAction}
                  isNew={this.props.isNewProductSlider}
                  image={getProductImage(card.images, this.props.isRecommendationsSlider)}
                  isHighlight={this.props.isRecommendationsSlider}
                  title={renderTitle(card)}
                />
            </div>
    } else {
      return ""
    }
  }

  async componentDidMount() {

    if (this.props.isNewProductSlider) {

      const products = await getProductsLimited(getToken())

      if (products) {
        this.setState({ cards: products.products, loading: false })
      }

    } else {
      this.setState({ cards: this.props.cards, loading: false })
    }
  }

  render() {

    if (this.state.loading) {
      return t("global.loading")
    } else {
      let { cards, firstElem, lastElem } = this.state

      return (
        <div
          className={`card-slider__wrapper`}>
          <span className="card-slider__header">
            <h2 className="card-slider__label">
              {this.props.sliderLabel}
            </h2>
          </span>
          <div className="card-slider__container">
            {!this.props.isRecommendationsSlider &&
            <span
              onClick={() => this.onGoTo("prev")}
              className={`card-slider__arrow card-slider__arrow--left
                      ${firstElem ? "card-slider__arrow--inactive" : ""}`}
            >
              <Icon icon="arrow" />
            </span>
            }

            {this.context.getAppIdOfUser() === "17" ? (
              <span
                onClick={() => this.onGoTo("prev")}
                className={`card-slider__arrow card-slider__arrow--left
                        ${firstElem ? "card-slider__arrow--inactive" : ""}`}
              >
                <Icon icon="arrow" />
              </span>
            ) : (null)}

            <div className="card-slider">
              <TinySlider
                settings={this.state.settings}
                ref={ts => (this.ts = ts)}
              >
                { cards && cards.map((elem, i) => {
                    return this.renderCard(elem, i)
                  })
                }
              </TinySlider>
            </div>
            {!this.props.isRecommendationsSlider &&
            <span
              onClick={() => this.onGoTo("next")}
              className={`card-slider__arrow card-slider__arrow--right
                      ${lastElem ? "card-slider__arrow--inactive" : ""}`}>
              <Icon icon="arrow" />
            </span>
            }

            {this.context.getAppIdOfUser() === "17" ? (
              <span
                onClick={() => this.onGoTo("next")}
                className={`card-slider__arrow card-slider__arrow--right
                        ${lastElem ? "card-slider__arrow--inactive" : ""}`}>
                <Icon icon="arrow" />
              </span>
            ) : (null)}
          </div>

          {this.props.showButtonLink &&
          <Link to={`/${window.currentLang}/shop/`}>
            <Button text={t("menu.all.novelties")} textColor={"black"} type={"secondary"}
                    isGold={this.context.isClubApp} isClubButton={this.context.isClubApp} />
          </Link>
          }
          {/*<Pagination totalItems={this.state.cards.length} currentPage={1} itemsPerPage={4} />*/}
        </div>
      );
    }
  }
}

CardSlider.propTypes = {
  sliderLabel: PropTypes.string,
  loop: PropTypes.bool,
  cards: PropTypes.array,
  productType: PropTypes.oneOf(["short", "standard"]),
  itemAmount: PropTypes.number,
  isNewProductSlider: PropTypes.bool,
  isRecommendationsSlider: PropTypes.bool,
  showButtonLink: PropTypes.bool,
  hasWishAction: PropTypes.bool
};

CardSlider.defaultProps = {
  sliderLabel: "Placeholder Label",
  productType: "short",
  showButtonLink: true,
  isNewProductSlider: true,
  hasWishAction: true,
  isRecommendationsSlider: false,
  isOnWishlistPage: false
};

CardSlider.contextType = AppContext

export default CardSlider;
