import React, { Component } from "react";
import { getAllNews, getContentPage, getHighperformance } from "../../data/api";
import AppHighperformanceNews from "../../components/module/app-highperformance-news/AppHighperformanceNews";
import axios from "axios";
import { AppContext } from "../../data/ContextProvider";
import AppPraemienClubHeader from "../../components/module/app-praemienclub-header/AppPraemienClubHeader";
import AppPraemienClubPerformance
  from "../../components/module/app-praemienclub-performance/AppPraemienClubPerformance";
import CardSlider from "../../components/module/card-slider/CardSlider";
import AppPraemienClubRanking from "../../components/module/app-praemienclub-ranking/AppPraemienClubRanking";
import AppPraemienClubWertung from "../../components/module/app-praemienclub-wertung/AppPraemienClubWertung";
import { CONTENT_SPIELREGELN } from "../../data/constants";
import t from "../../helpers/t9n";

class PraemienshopClub extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isHeaderOpen: true,
      loading: true,
    }
  }

  componentDidMount() {
    axios.all([getHighperformance(), getAllNews(), getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)])
      .then(axios.spread((highperf, news, page) => {

        this.setState({
          data: highperf,
          text: page.text,
          news: news.news,
          loading: false
        })
      }))
  }

  handleHeader = () => {
    this.setState({
      isHeaderOpen: !this.state.isHeaderOpen
    })
  }

  render() {

    if (this.state.loading) {
      return "Loading..."
    }

    const isRanking = this.props.view === "ranking"

    return (
      <>
        <div className={"content app praemienshopclub club"}>

          <AppPraemienClubHeader handleHeader={this.handleHeader} isHeaderOpen={this.state.isHeaderOpen}
                                 text={this.state.text}
                                 isRanking={isRanking} />

          <AppPraemienClubPerformance handleHeader={this.handleHeader} isHeaderOpen={this.state.isHeaderOpen}
                                      performance={this.state.data} />

          {this.props.view === "ranking" &&
          <AppPraemienClubRanking data={this.state.data} segment={this.state.data.segment}
                                  isPointsMode={this.state.data.performance_mode === "points"} />
          }

          {this.props.view === "wertung" &&
          <AppPraemienClubWertung data={this.state.data.evaluation_criteria} />
          }

          {this.props.view === "home" &&
          <AppHighperformanceNews data={this.state.news} />
          }

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>

        </div>
      </>
    );
  }
}

PraemienshopClub.contextType = AppContext

export default PraemienshopClub;
