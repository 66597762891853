import React, { Component } from "react";
import SectionRecommendations from "../../../components/module/section-recommendations/SectionRecommendations";
import CardSlider from "../../../components/module/card-slider/CardSlider";
import { getContentPage } from "../../../data/api";
import { AppContext } from "../../../data/ContextProvider";
import SalesTriathlonGoals from "../Components/SalesTriathlonGoals";
import SalesTriathlonHeader from "../Components/SalesTriathlonHeader";
import { CONTENT_SPIELREGELN } from "../../../data/constants";
import t from "../../../helpers/t9n";

class SalesTriathlonHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHeaderOpen: true,
      loading: true,
    };
  }

  async componentDidMount() {
    const page = await getContentPage(
      CONTENT_SPIELREGELN,
      this.context.getUserData().project_id,
      this.context.getUserData().app_id
    );

    this.setState({
      pageText: page.text,
      loading: false,
    });
  }

  handleHeader = () => {
    this.setState({
      isHeaderOpen: !this.state.isHeaderOpen,
    });
  };

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <div className="content app salestriathlon">
        <SalesTriathlonHeader
          text={this.state.pageText}
          handleHeader={this.handleHeader}
          isHeaderOpen={this.state.isHeaderOpen}
        />

        <SalesTriathlonGoals
          handleHeader={this.handleHeader}
          isHeaderOpen={this.state.isHeaderOpen}
        />

        <SectionRecommendations label="Prämien-Highlights" />

        <div className="section__card-slider">
          <CardSlider
            sliderLabel={t("app.award.news")}
            isNewProductsSlider={true}
          />
        </div>
      </div>
    );
  }
}

SalesTriathlonHome.contextType = AppContext;
export default SalesTriathlonHome;
