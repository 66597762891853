import React from "react";
import { useParams } from "react-router-dom";
import Neukunden from "./Neukunden/Neukunden";

const NeukundenPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <Neukunden lang={lang} />
    </>
  )
}

export default NeukundenPage;
