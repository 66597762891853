import React from "react";

import "../sass/styles.sass";
import Navbar from "../components/element/navbar/Navbar";
import Footer from "../components/element/footer/PublicFooter";

function EmptyLayout(props) {

  return (
    <React.Fragment>
      <Navbar bgColor={"black"} isScrolling={true} />
      <div className={`container ${props.fullHeight ? "container--full-height" : ""}`}>
        {props.children}
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default EmptyLayout;
