import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import Header from "../components/element/header-section/HeaderSection";
import GloriaRewardsShopHeader from "./GloriaRewards/Components/GloriaRewardsShopHeader";
import Shop from "./Shop/Shop";

import t from "../helpers/t9n";
import { AppContext } from "../data/ContextProvider";
import { APP_GLORIAREWARDS } from "../data/constants";


const ShopPage = () => {
  const { lang, category, subcategory } = useParams()
  const context = useContext(AppContext)

  const project = context.getProjectData()

  const headline    = (project.shop_headline.length > 0)? project.shop_headline : "Prämienshop" 
  const subheadeline = (project.shop_subheadline.length > 0)? project.shop_subheadline : "" 

  return (
    <>
      {parseInt(context.getAppIdOfUser()) === APP_GLORIAREWARDS ? (
        <GloriaRewardsShopHeader title={headline} subtitle={subheadeline} />
      ) : (
        <Header heading={t("menu.award.shop")} />
      )}
      <Shop lang={lang} category={category} subcategory={subcategory} />
    </>
  )
}

export default ShopPage;
