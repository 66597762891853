import React from "react";
import { useParams } from "react-router-dom";
import SalesTriathlonHome from "./Pages/SalesTriathlonHome";

const SalesTriathlon = () => {
  let { lang } = useParams();

  return (
    <>
      <SalesTriathlonHome lang={lang} />
    </>
  )
}

export default SalesTriathlon;
