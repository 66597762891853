import React, { Component } from "react";
import { getContentPage, getHighperformance } from "../../data/api";
import AppHighperformanceHeader from "../../components/module/app-highperformance-header/AppHighperformanceHeader";
import AppHighperformancePerformance
  from "../../components/module/app-highperformance-performance/AppHighperformancePerformance";
import AppHighperformanceNews from "../../components/module/app-highperformance-news/AppHighperformanceNews";
import AppHighperformanceLinks from "../../components/module/app-highperformance-links/AppHighperformanceLinks";
import AppHighperformanceRanking from "../../components/module/app-highperformance-ranking/AppHighperformanceRanking";
import AppHighperformanceWertung from "../../components/module/app-highperformance-wertung/AppHighperformanceWertung";
import { CONTENT_SPIELREGELN } from "../../data/constants";
import { AppContext } from "../../data/ContextProvider";

class Highperformance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isHeaderOpen: true,
      loading: true,
      text: null,
    }

    this.rankingRef = React.createRef()
    this.rankingAnchor = React.createRef()
  }

  async componentDidMount() {
    const p = await getHighperformance()
    const pageText = await getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)

    let text = ""
    if (pageText.text) {
      text = pageText.text
    } else {
      text = "Willkommen im Highperformance-Club, dem Sales-Elite-Zirkel unseres Unternehmens. Mitglied im exklusiven Club werden nur die Vertriebsmitarbeiter eines Jahres, die Ihre Ziele am erfolgreichsten erfüllen. Entsprechend Ihrer Performance treten Sie in einer Leistungsgruppe an. Die Besten aus jeder Gruppe erhalten dann die exklusive Eintrittskarte in den Highperformance-Club und gewinnen eine außergewöhnliche und einmalige Reise."
    }

    this.context.setCurrentPlace(p.current_place)

    this.setState({
      data: p,
      text: text,
      loading: false
    }, () => {
      if (this.props.view === "ranking" || this.props.view === "wertung") {
        this.scrollToRanking()
      }
    })

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.view !== this.props.view) {
      console.log(this.rankingAnchor.current)
      this.scrollToRanking()
      // window.location.reload()
      // if (this.props.view && this.props.view === "ranking") {
      //   this.scrollToRanking()
      // }
    }
  }

  handleHeader = () => {
    this.setState({
      isHeaderOpen: !this.state.isHeaderOpen
    })
  }

  scrollToRanking = () => {
    // window.scrollTo(0, this.rankingAnchor.current.offsetTop)
    console.log("scrolling")
    this.rankingAnchor.current.scrollIntoView({
      behaviour: 'smooth',
      block: 'start',
      inline: 'center',
    });
  }

  render() {
    if (this.state.loading) {
      return "Loading..."
    }

    const isRanking = this.props.view === "ranking" || this.props.view === "wertung"

    return (
      <>
        <div className={"content app highperformance club"}>

          <AppHighperformanceHeader handleHeader={this.handleHeader} isHeaderOpen={this.state.isHeaderOpen}
                                    pageText={this.state.text}
                                    isRanking={isRanking} />

          <AppHighperformancePerformance handleHeader={this.handleHeader} isHeaderOpen={this.state.isHeaderOpen}
                                         scrollToRankingFunc={this.scrollToRanking}
                                         pageText={this.state.text}
                                         performance={this.state.data} />

          <div className={"anchor"} id={"ranking"} ref={this.rankingAnchor} />
          {this.props.view === "ranking" &&
          <AppHighperformanceRanking
            data={this.state.data}
            rankingRef={this.rankingRef}
            // visible={this.props.view === "ranking"}
            // additionalClass={this.props.view === "ranking" ? "visible" : ""}
            isPointsMode={this.state.data.performance_mode === "points"} />
          }

          <span className={"anchor"} id={"wertung"} />
          {this.props.view === "wertung" &&
          <AppHighperformanceWertung data={this.state.data.evaluation_criteria} />
          }

          {this.props.view === "home" &&
          <AppHighperformanceNews data={this.state.news} />
          }
          <AppHighperformanceLinks data={this.state.news} />

        </div>
      </>
    );
  }
}

Highperformance.contextType = AppContext

export default Highperformance;
