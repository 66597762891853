import React, { Component } from "react";
import CardSlider from "../../components/module/card-slider/CardSlider";
import SectionPrivateHeader from "../../components/module/section-private-header/SectionPrivateHeader";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import SectionProductSearch from "../../components/module/section-product-search/SectionProductSearch";
import { SHOP_HIGHLIGHTS } from "../../data/constants"
import t from "../../helpers/t9n";


class Private extends Component {
  render() {
    return (
      <>
        <div className={`content private`}>

          <SectionPrivateHeader />
          <SectionProductSearch />
          <SectionRecommendations label={"Prämien-Highlights"} buttonLabel={"Weitere Highlights"}
                                  shopParams={SHOP_HIGHLIGHTS} />

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} />
          </div>
        </div>
      </>
    );
  }
}

export default Private;
