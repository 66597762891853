import React, { useContext } from "react";
import PropTypes from 'prop-types'
import Image from "../../atom/image/Image";
import { API_HOST } from "../../../data/config";
import { AppContext } from "../../../data/ContextProvider";

const ContentHeader = props => {
  
  const context = useContext(AppContext)
  
  const imageUrl = props.imageKey ? `${API_HOST}/asset/${props.imageKey}?pid=${context.user.project_id}` : process.env.PUBLIC_URL + `/images/app/gewinnerreise/img--newyork-bg.jpg`;
  
  return (
    <div className={`content-header`}>
      <Image alt={"content-header-image"} type={"default"}
             image={imageUrl} />
      <h1>{props.heading}</h1>
      <h2>{props.subHeading}</h2>
    </div>
  );
}

ContentHeader.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  hasImage: PropTypes.bool,
}

ContentHeader.defaultProps = {
  hasImage: true,
}


export default ContentHeader;
