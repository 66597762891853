import React, { Component } from "react";
import { AppContext } from "../../../data/ContextProvider";
import PropTypes from "prop-types";
import AppPromoBoosterLogin from "../app-promobooster-login/AppPromoBoosterLogin";
import AppPromoBoosterHeaderAccess from "../app-promobooster-header-access/AppPromoBoosterHeaderAccess";
import AppPromoBoosterLower from "../app-promobooster-lower/AppPromoBoosterLower";

class AppPromoBoosterHeader extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      error: "",
      hasAccess: this.context.isLoggedIn(),
    }
  }

  handleAccessToPromoBooster = () => {
    this.setState({
      hasAccess: !this.state.hasAccess
    })
  }

  getCurrentState() {
    return this.state.hasAccess ?
      <>
        <AppPromoBoosterHeaderAccess />
        <AppPromoBoosterLower />
      </>
      :
      <AppPromoBoosterLogin isHeaderOpen={this.props.isHeaderOpen}
                            handleHeader={this.props.handleHeader}
                            handleAccess={this.handleAccessToPromoBooster} />
  }

  render() {
    return (
      <>
      {this.getCurrentState()}
      </>
    );
  }
}

AppPromoBoosterHeader.propTypes = {
  handleHeader: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
};
AppPromoBoosterHeader.defaultProps = {
  isHeaderOpen: false,
};

AppPromoBoosterHeader.contextType = AppContext

export default AppPromoBoosterHeader;
