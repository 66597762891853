import React from "react";
import { useParams } from "react-router-dom";
import SalesPush from "./SalesPush/SalesPush";

const SalesPushPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <SalesPush lang={lang} />
    </>
  )
}

export default SalesPushPage;
