import React, { useContext } from "react";

import "../sass/styles.sass";
import Navbar from "../components/element/navbar/Navbar";
import Footer from "../components/element/footer/Footer";
import { AppContext } from "../data/ContextProvider";
import CardSlider from "../components/module/card-slider/CardSlider";
import ModalInformation from "../components/module/modal-information/ModalInformation";
import t from "../helpers/t9n";

function WishlistLayout({ children }) {

  const context = useContext(AppContext);

  return (
    <React.Fragment>
      <Navbar bgColor={"black"} isScrolling={true} />


      <div className={`container container--wishlist`}>
        {context.isModalInfoVisible &&
        <ModalInformation />
        }

        {children}
      </div>

      <div className={"section__card-slider"}>
        <CardSlider sliderLabel={t("app.award.news")} isOnWishlistPage={true} />
      </div>
      <div className={`popup-cart__overlay ${context.isPopupCartVisible ? "popup-cart__overlay--visible" : ""}`}>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default WishlistLayout;
