import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Button from '../../../components/atom/button/Button'
import Product from '../../../components/element/product/Product'
import CardSlider from '../../../components/module/card-slider/CardSlider'
import { getHighlights } from '../../../data/api'
import { SHOP_HIGHLIGHTS } from '../../../data/constants'
import { AppContext } from '../../../data/ContextProvider'
import t from '../../../helpers/t9n'
import { getMinProductPriceForProject } from '../../../helpers/utils'

class GloriaRewardsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      cards: [],
      featured: {}
    };
    this.shopURL = `/${window.currentLang}/shop`
  }

  async componentDidMount() {

    const highlights = await getHighlights();

    //Hack
    const highlightsHighlights = await getHighlights();

    const product1 = (highlightsHighlights) ? highlightsHighlights.products[0] : null
    const product2 = (highlightsHighlights) ? highlightsHighlights.products[1] : null
    const product3 = (highlightsHighlights) ? highlightsHighlights.products[2] : null
    const product4 = (highlightsHighlights) ? highlightsHighlights.products[3] : null

    this.setState({
      cards: highlights.products,
      product1: product1,
      product2: product2,
      product3: product3,
      product4: product4,
      loading: false
    })

    if (this.props.shopParams === SHOP_HIGHLIGHTS) {
      this.shopURL = `/${window.currentLang}/shop?highlights=1`
    }
  }

  render() {
    if (this.state.loading) return t("global.loading");

    const cards = this.state.cards;

    return (
      <>
        <div className="gloria-rewards__content">

          {/* Featured Products */}
          <div className="gloria-rewards__featured">
            <div className="gloria-rewards__featured--left">
              {this.state.product1 &&
                <Product
                  id={this.state.product1.id}
                  isNew={this.state.product1.new}
                  image={this.state.product1.images[1].url}
                  isHighlight={this.state.product1.highlight}
                  title={this.state.product1.title}
                  button="Entdecken"
                />
              }

            </div>
            <div className="gloria-rewards__featured--left">
              {this.state.product2 &&
                <Product
                  id={this.state.product2.id}
                  isNew={this.state.product2.new}
                  image={this.state.product2.images[1].url}
                  isHighlight={this.state.product2.highlight}
                  title={this.state.product2.title}
                  button="Entdecken"
                />
              }
            </div>
          </div>
          {/* Featured Products */}

          {/* Highlights Products */}
          <div className="gloria-rewards__highlights">
            <h2>Prämien-Highlights</h2>

            <div className="gloria-rewards__highlights--left">

              {this.state.product3 &&
              <Product
                  id={this.state.product3.id}
                  isNew={this.state.product3.new}
                  image={this.state.product3.images[1].url}
                  isHighlight={this.state.product3.highlight}
                  title={this.state.product3.title}
                  hasWishAction={true}
                  isWished={this.state.product3.wish}
                  price={getMinProductPriceForProject(this.state.product3.options, this.context.getUserData().project_code)}
                />
              }
            </div>
            <div className="gloria-rewards__highlights--right">
              {this.state.product4 &&
                <Product
                  id={this.state.product4.id}
                  isNew={this.state.product4.new}
                  image={this.state.product4.images[1].url}
                  isHighlight={this.state.product4.highlight}
                  title={this.state.product4.title}
                  hasWishAction={true}
                  isWished={this.state.product4.wish}
                  price={getMinProductPriceForProject(this.state.product4.options, this.context.getUserData().project_code)}
                />
              }
            </div>

            <div className="gloria-rewards__highlights--button">
            <Link to={`/${window.currentLang}/shop/Echter%20Luxus/Echter%20Luxus`}>
              <Button
                type="secondary"
                text="Weitere Highlights"
                textColor="black"
              />
            </Link>
            </div>
          </div>
          {/* Highlights Products */}

          {/* Top Product
          <div className="gloria-rewards__topproduct">
            {this.state.product1 &&
              <Product
                id={this.state.product1.id}
                isNew={this.state.product1.new}
                image={this.state.product1.images[2].url}
                isHighlight={this.state.product1.highlight}
                title={this.state.product1.title}
                button="Entdecken"
              />
            }
          </div>
          */}
          {/* Top Product */}

          {/* New Products */}
          <div className="gloria-rewards__newproducts">
            <CardSlider
              productType="standard"
              cards={cards}
              showButtonLink={false}
              sliderLabel={t("global.recommendation")}
              isNewProductSlider={false}
              isRecommendationsSlider={true}
              hasWishAction={true}
            />
            <div className="gloria-rewards__newproducts--button">
            <Link to={`/${window.currentLang}/shop/`}>
              <Button
                type="secondary"
                text="Alle Neuheiten anzeigen"
                textColor="black"
              />
            </Link>
            </div>
          </div>
          {/* New Products */}

        </div>
      </>
    )
  }
}

GloriaRewardsContent.contextType = AppContext
export default GloriaRewardsContent;