import axios from 'axios'

import { API_HOST, API_HOST_LOGIN } from "./config";
import { getToken, toQueryString } from "../lib/lib";

//User Section
export async function login(email, password, project_id=0) {
  const payload = {
    "user": {
      "email": email,
      "password": password,
      "project_id": project_id
    }
  }

  const response = await axios.post(
    `${API_HOST}/login`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })

  return {
    user: response.data,
    authorization: response.headers['authorization']
  }
}

export async function impersonate(token) {
  const payload = {
    "impersonate_token": token
  }

  const response = await axios.post(
    `${API_HOST_LOGIN}impersonate`,
    JSON.stringify(payload),
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
  .catch(error => {
    throw (error.response)
  })

  return {
    user: response.data.user,
    authorization: response.data.authorization
  }
}

export async function logout() {
  await axios.get(
    `${API_HOST_LOGIN}api/v1/logout`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
}

export async function reloginUser(token) {
  const response = await axios.get(
    `${API_HOST_LOGIN}relogin`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }
  ).catch(error => {
    throw (error.response)
  })
  return response.data
}

export async function loadCurrentUser() {
  const response = await axios.get(
    `${API_HOST_LOGIN}user/current`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}

export async function updateUser(user) {
  const response = await axios.put(
    `${API_HOST_LOGIN}user/update`,
    { user: user },
    {
    headers: {
      'Accept': 'application/json',
      'Authorization': getToken()
    }
  }
  ).catch(function (error) {
    throw error
  });
  return response.data

}

export async function signUpUserWithCode(data, code) {
  const response = await axios.post(
    `${API_HOST_LOGIN}/register?c=${code}`,
    data, {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}


export async function getUserStats() {
  const response = await axios.get(
    `${API_HOST}/stats/`,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}


//#region wishlist

export async function addToWishlist(token, id, variantId) {
  let query = "";

  if (variantId !== null) {
    query += "variant_id=" + variantId
  }
  return axios.post(
    `${API_HOST}/product_wishes?${query}`,
    { id: id }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function removeFromWishlist(token, id, variantId) {
  let query = "";

  if (variantId !== null) {
    query += "variant_id=" + variantId
  }
  return axios.delete(
    `${API_HOST}/product_wishes/${id}?${query}`,
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function removeAllFromWishlist(token) {
  return axios.delete(
    `${API_HOST}/product_wishes/delete_all`,
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function getWishlist(token) {
  const response = await axios.get(
    `${API_HOST}/product_wishes`,
    {
      headers: {
        'Authorization': token
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })

  return response.data
}

//#endregion

//#region bookings

export async function getBookings(queryParams = null) {
  let qs = ''
  if (queryParams !== null) {
    qs = toQueryString(queryParams)
  }
  const response = await axios.get(
    `${API_HOST}/bookings?${qs}`,
    {
      headers: {
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}


//#endregion

//#region leistungsdaten

export async function getPerformancedata(queryParams = null) {
  let qs = ''
  if (queryParams !== null) {
    qs = toQueryString(queryParams)
  }
  const response = await axios.get(
    `${API_HOST}/performancedata?${qs}`,
    {
      headers: {
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}

//#leistungsdaten

//#region highlights

//Liefert die neusten 5 Highlight-Produkte
export async function getHighlights() {
  const response = await axios.get(
    `${API_HOST}/product-highlights`,
    {
      headers: {
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data

}

//#endregion

//#region checkout (address, order)

export async function getAddressById(token, id) {
  const response = await axios.get(
    `${API_HOST}/addresses/${id}`, {
      headers: {
        'Authorization': token
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data.address
}

export async function getAddresses() {
  const response = await axios.get(
    `${API_HOST}/addresses`, {
      headers: {
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data.addresses
}

export async function createAddress(data) {
  const response = await axios.post(
    `${API_HOST}/addresses`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
  .catch(error => {
    throw (error.response)
  })
  return response.data
}

export async function updateAddress(id, data) {
  const response = await axios.put(
    `${API_HOST}/addresses/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
  .catch(error => {
    throw (error.response)
  })
  return response.data
}

export async function deleteAddress(id) {
  const response = await axios.delete(
    `${API_HOST}/addresses/${id}`, {
      headers: {
        'Authorization': getToken()
      }
    }
  )
  .catch(error => {
    throw (error.response)
  })
  return response.data
}

export async function getOrders(queryParams) {
  let qs = ''
  if (queryParams !== null) {
    qs = toQueryString(queryParams)
  }
  const response = await axios.get(
    `${API_HOST}/orders?${qs}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}

export async function getOrderById(token, orderId) {
  const response = await axios.get(
    `${API_HOST}/orders/${orderId}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': token
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}

export async function getOrderPosition(token, position_numbers) {
  let request = "";
  position_numbers.forEach((c) => {
    request += "number[]=" + c + "&";
  })

  const response = await axios.get(
    `${API_HOST}/productoptions?${request}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': token
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data.positions
}

export async function createOrder(addressID) {
  const response = await axios.post(
    `${API_HOST}/orders`,
    { address_id: addressID },
    {
      headers: {
        'Authorization': getToken()
      }
    }
  ).catch(error => {
    throw (error.response)
  })
  return response.data
}

//#endregion

//#region cart

export async function addToCart(token, option_id, quantity) {
  return axios.post(
    `${API_HOST}/cart/${option_id}/add`, { quantity: quantity}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => {
      throw (error.response)
    })
}

export async function removeFromCart(option_id, quantity = 1) {
  return axios.delete(
    `${API_HOST}/cart/${option_id}/remove`, {
      headers: {
        'Authorization': getToken()
      },
      params: {
        quantity: quantity
      }
    }
  )
    .then(response => response.data)
    .catch(error => {
      throw (error.response)
    })
}

export async function getCart() {
  const response = await axios.get(
    `${API_HOST}/cart`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

export async function addTheWishlistToCart(token) {
  return axios.post(
    `${API_HOST}/cart/import`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }
  )
    .then(response => response.data)
    .catch(error => {
      throw (error.response)
    })
}


//#endregion

//#region categories & products

export async function getCategories(token) {
  const response = await axios.get(
    `${API_HOST}/categories`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response ? response.data.categories : null
}

export async function getProducts(token, page, search_term = "", order_by = "", p_from = "", p_to = "", highlights = false) {
  let params = "?page=" + page;
  if (search_term !== "") {
    params += "&search_term=" + search_term;
  }
  if (order_by !== "") {
    params += "&order_by=" + order_by;
  }
  if (p_from !== "") {
    params += "&p_from=" + p_from;
  }
  if (p_to !== "") {
    params += "&p_to=" + p_to;
  }
  if (highlights) {
    params += "&highlights=1";
  }

  const response = await axios.get(
    `${API_HOST}/products${params}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response ? response.data : null
}

export async function getProductsLimited(token) {
  const response = await axios.get(
    `${API_HOST}/products-limited`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response ? response.data : null
}

export async function getProductById(token, id) {
  const response = await axios.get(
    `${API_HOST}/products/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response ? response.data : null
}

export async function getProductsByCategories(token,
                                              main_category_ids,
                                              category_ids,
                                              search_term = "",
                                              order_by = "",
                                              page = null,
                                              p_from = "",
                                              p_to = "",
                                              highlights = false) {
  let params = "";
  main_category_ids.forEach((c) => {
    params += "main_category_id[]=" + c + "&";
  })
  category_ids.forEach((c) => {
    params += "category_id[]=" + c + "&";
  })
  if (search_term !== "") {
    params += "search_term=" + search_term + "&";
  }
  if (order_by !== "") {
    params += "order_by=" + order_by + "&";
  }
  if (page) {
    params += "page=" + page + "&";
  }
  if (p_from !== "") {
    params += "&p_from=" + p_from;
  }
  if (p_to !== "") {
    params += "&p_to=" + p_to;
  }
  if (highlights) {
    params += "&highlights=1";
  }

  const response = await axios.get(
    `${API_HOST}/products?${params}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data;
}

//#endregion


//--------------------------------- Projekt / App Prefs ----------------------

export async function getAppPrefs() {
  const response = await axios.get(
    `${API_HOST}/app_preferences/project`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}


//--------------------------------- RankingLevels / Gewinnstufen ----------------------

export async function getRankingLevels() {
  const response = await axios.get(
    `${API_HOST}/rankinglevels`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

//--------------------------------- RankingLevels / Gewinnstufen ----------------------

export async function getRankings() {
  const response = await axios.get(
    `${API_HOST}/rankings`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

export async function getUserRankingForSalesPush() {
  const response = await axios.get(
    `${API_HOST}/rankings/salespush`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}


//--------------------------------- Apps  ----------------------

//HerosPlace
export async function getTransactionsForHerosPlace() {
  const response = await axios.get(
    `${API_HOST}/transactions/herosplace`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

//SalesPush
export async function getTransactionsForSalesPush() {
  const response = await axios.get(
    `${API_HOST}/transactions/salespush`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

// Neukundenjäger
export async function getNewcontractsRankings() {
  const response = await axios.get(
    `${API_HOST}/newcontractsranking`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}


//FeuerfreiSteuerfrei
export async function getFeuerfreiSteuerfrei(token) {
  const response = await axios.get(
    `${API_HOST}/feuerfreisteuerfrei`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

//General Text
export async function getText(key) {
  const response = await axios.get(
    `${API_HOST}/text/${key}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

//PrämienCasino
export async function getCasino() {
  const response = await axios.get(
    `${API_HOST}/casino`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

//PrämienCasino - Bandit
export async function spinCasinoBandit() {
  const response = await axios.post(
    `${API_HOST}/casino`, {}, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

//SchaffeIch
export async function getBet() {
  const response = await axios.get(
    `${API_HOST}/bets/user`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

export async function getBetDefaultsForUser() {
  const response = await axios.get(
    `${API_HOST}/bets/user-defaults`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

//SchaffeIch
export async function selectBet(data) {
  const response = await axios.post(
    `${API_HOST}/bets`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .catch(error => {
      throw (error.response)
    })
  return response.data
}

export async function getHighperformance() {
  const response = await axios.get(
    `${API_HOST}/pointsplaces`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

export async function getContentPage(pageNum, project_id = null, app_id = null) {
  //project_id
  let query = "";

  if (app_id !== null && pageNum === 33) {
    query += "&app_id=" + app_id
  }

  if (project_id !== null) {
    query += "&project_id=" + project_id;
  }

  const response = await axios.get(
    `${API_HOST}/page?pagenum=${pageNum}${query}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

//#endregion

//Promo Code
export async function checkPromoCode(promoCode) {
  const response = await axios.get(
    `${API_HOST}/promo_codes?c=${promoCode}`, {
      headers: {
        'Accept': 'application/json',
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response && response.data
}

export async function redeemPromoCode(promoCode) {
  const response = await axios.post(
    `${API_HOST}/promo_code?c=${promoCode}`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': getToken(),
      }
    }
  ).catch(error => {
    throw(error.response)
  });

  return response && response.data
}


export async function resetPassword(data) {
  return axios.post(
    `${API_HOST_LOGIN}/reset_password`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    }
  )
  .then(response => response)
  .catch(error => {
    throw (error.response)
  })
}

export async function updatePassword(data) {
  return axios.post(
    `${API_HOST_LOGIN}/update_password`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': getToken(),
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function updatePasswordProfile(data) {
  return axios.post(
    `${API_HOST_LOGIN}/update_password_profile`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': getToken(),
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}


//Avatar

export async function updateAvatar(id, file) {
  const data = new FormData();
  data.append("id", id)
  data.append("avatar", file)

  return axios.post(
    `${API_HOST_LOGIN}/avatar`,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': getToken()
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

export async function deleteAvatar(id) {
  return axios.delete(
    `${API_HOST_LOGIN}/avatar`,
    {
      headers: {
        'Authorization': getToken()
      },
      data: {
        id: id
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

//News
export async function getAllNews() {
  const response = await axios.get(
    `${API_HOST}/news`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(function (error) {
    console.log(error);
  });

  return response ? response.data : []
}

//function to mark news / mails as read
export async function readPost(id) {
  return axios.post(
    `${API_HOST}/post_readings`,
    { id: id }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
    .then(response => response)
    .catch(error => {
      throw (error.response)
    })
}

// Project Domain
export async function getProjectByDomain(domain = null) {
  const response = await axios.get(
    `${API_HOST_LOGIN}/project?domain=${domain}`, {
      headers: {
        'Accept': 'application/json',
      }
    }
  ).catch(function (error) {
    console.log(error)
    throw (error)
    // return error.response.data;
  });

  return response.data
}

//Send Contact Email
export async function sendContactMail(support, data, project_id = null) {
  data.support = support
  return axios.post(
    `${API_HOST_LOGIN}/contact?pid=${project_id}`,
    data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  )
  .then(response => response)
}


export async function approvePaypalOrder(orderID, userPoints, cartAmount, delta, addressID) {
  const data = { order_id: orderID, user_points: userPoints, cart_amount: cartAmount, delta: delta, address_id: addressID}
  const response = await axios.post(
    `${API_HOST}/paypal/approve`,
    data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken()
      }
    }
  ).catch(error => {
    throw (error.response)
  })

  return response.data
}

/**
 * get all header slider images
 * @returns {Promise<any>}
 */
export async function getAssetSlider() {
  const response = await axios.get(
    `${API_HOST_LOGIN}api/v1/slider/header`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': getToken()
      }
    }).catch(function (error) {
      console.log(error);
    }
  );
  return response.data
}
