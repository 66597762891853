import React, { Component } from "react";



import Button from "../../../components/atom/button/Button";

import Header from "../Components/Header";
import RegisterForm from "../Components/RegisterForm";
import Stepper from "../Components/Stepper";
import TableSeller from "../Components/TableSeller";
import UserImport from "../Components/UserImport";

import { getCtUsers, completeOnBoarding, getTextForKey } from "../../../data/ct-api";

import t from "../../../helpers/t9n";
import { dangerousHTMLText } from "../../../lib/lib";
import { AppContext } from "../../../data/ContextProvider";


class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: true,
      isOpenRegister: false,
      isOpenUpload: false,
      currentPage: 1,
    };
  }

  handleRegister = () => {
    this.setState({
      isOpenRegister: !this.state.isOpenRegister
    });
  }

  handleUpload = () => {
    this.setState({
      isOpenUpload: !this.state.isOpenUpload
    });
  }


  paginate = (pageNumber) => {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this.loadData()
    })
  }

  onImportSuccess = (messages) => {
    console.log(".....", messages)
  }

  onCreateSuccess = () => {
    this.loadData()
  }


  loadData = async () => {
    const page  = this.state.currentPage || 1
    const users = await getCtUsers(page)
    const pageText = await getTextForKey("registration")

    this.setState({
      users: users.users,
      pager: users.meta,
      pageText: pageText,
      loading: false
    })
  }

  async componentDidMount() {
    if (!this.context.user.onboarding_completed) {
      completeOnBoarding()
    }
    this.loadData()
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <div className="compl-tool">

          <Header headline="" />

          {!this.context.user.onboarding_completed &&
            <Stepper activeStep="6" />
          }

          <div className="content content--small">
            <h4>{this.state.pageText.headline}</h4>
            <p dangerouslySetInnerHTML={{ __html: dangerousHTMLText(this.state.pageText.body) }} ></p>
          </div>

          <div className="compl__upload">
            <div className="content">
              <span className="compl-btn">
                <Button
                  type="primary"
                  text="Einzelnen Verkäufer registrieren"
                  textColor="white"
                  onClick={this.handleRegister}
                  icon="single-seller"
                  additionalClass={this.state.isOpenRegister ? "button--card button--card-active" : "button--card"}
                />
              </span>

              <span className="compl-btn">
                <Button
                  type="primary"
                  text="Mehrere Verkäufer registrieren"
                  textColor="white"
                  onClick={this.handleUpload}
                  icon="several-sellers"
                  additionalClass={this.state.isOpenUpload ? "button--card button--card-active" : "button--card"}
                />
              </span>
            </div>
          </div>

          {this.state.isOpenRegister &&
            <div className="compl__user__container compl__user__container--open">
              <h2>Verkäufer registrieren</h2>
              <RegisterForm handleRegister={this.handleRegister} onSuccess={this.onCreateSuccess} />
            </div>
          }

          {this.state.isOpenUpload &&
            <UserImport onClose={this.handleUpload} onSuccess={this.onImportSuccess}/>
          }

          <div className="compl__info">
            <div className="content">
              <h3>Aktuell angemeldete Verkäufer</h3>
              <TableSeller users={this.state.users} pager={this.state.pager} paginate={this.paginate}/>
            </div>
          </div>

        </div>
      </>
    );
  }
}

Registration.contextType = AppContext;
export default Registration;
