import React, { useContext } from "react";
import PropTypes from 'prop-types'
import Image from "../../atom/image/Image";
import { AppContext } from "../../../data/ContextProvider";

const Header = props => {
  const context = useContext(AppContext)

  return (
    <div className={`header ${props.hasImage ? "" : "header--no-image"}`}>
      <h1>{props.heading}</h1>
      {props.subHeading &&
      <h2>{props.subHeading}</h2>
      }

      {context.getAppIdOfUser() === "17" ? (
        <div className="gloria-rewards__shop-header"></div>
      ) : (
        <>
          {props.hasImage &&
            <Image alt={"header-image"} type={"default"}
                image={process.env.PUBLIC_URL + `/images/${props.isRed ? "header-red.jpg" : "header.png"}`} />
          }
        </>
      )}
    </div>
  )
};

Header.propTypes = {
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  hasImage: PropTypes.bool,
  isRed: PropTypes.bool,
}

Header.defaultProps = {
  hasImage: true,
  isRed: false
}


export default Header;
