import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import { withRouter } from "react-router-dom";
import t from "../../../helpers/t9n";

class AppGewinnerreiseRanking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    }
  }

  render() {
    const { data } = this.state

    const places = data.places
    const group = data.segment


    return (
      <>
      <div className={`highperformance-placement__section`}>
        <div className={"highperformance-placement__header"}>
          <h2>{t("app.ranking")}</h2>
        </div>
        {group && group.title && (
          <div className={"highperformance-placement__header-sub"}>
            <div>{t("app.belong.to.group")}</div>
            <div><h2>{group.title}</h2></div>
          </div>
        )}
      </div>
      <div className={`highperformance-ranking__section`}>
        <div className={"highperformance-ranking__content"}>
          <div className={"highperformance-ranking__list"}>
            <div className={"highperformance-ranking__list-header"}>
              <ul>
                <li>{t("app.rank")}</li>
                <li>{t("app.name")}</li>
                <li>{t("app.rank.points")}</li>
                <li>{t("app.target.achievement")}</li>
              </ul>
            </div>
            <div className={"highperformance-ranking__list-ranks"}>
              <ul>
                {places.map((entry, i) => (
                  <li key={entry.place + "_" + i + "_" + entry.amount}>
                    <div className={`highperformance-ranking__list-entry
                                    ${entry.place <= 3 && "highperformance-ranking__list-entry--top"}
                                    ${entry.place === data.current_place && "highperformance-ranking__list-entry--current"}`}>
                      <span>{entry.place}</span>
                      <span>{(entry.user && this.context.getUserData().uid === entry.user_id) ? <>{entry.user.first_name} {entry.user.last_name}</> : "-"}</span>
                      <span>{Number(entry.amount).toLocaleString()}</span>
                      <span>{this.props.isPointsMode ? entry.amount + t("app.points") : entry.percent + " %"}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={"highperformance-ranking__actions"}>
            <Button type={"secondary"} textColor={"black"} text={t("app.evaluation.criteria")}
                    isClubButton={true}
                    onClick={() => {
                      this.props.history.push(`/${window.currentLang}/wertungskriterien`)
                    }} />
          </div>
        </div>
      </div>
      </>
    );
  }
}

AppGewinnerreiseRanking.contextType = AppContext

export default withRouter(AppGewinnerreiseRanking);
