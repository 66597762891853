import React, { Component } from "react";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import CardSlider from "../../components/module/card-slider/CardSlider";
import AppHappyPraemienHeader from "../../components/module/app-happy-praemien-header/AppHappyPraemienHeader";
import SectionProductSearch from "../../components/module/section-product-search/SectionProductSearch";
import AppHappyPraemienNews from "../../components/module/app-happypraemien-news/AppHappyPraemienNews";
import t from "../../helpers/t9n";

class HappyPraemien extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
        <div className={"content app jackpot happypraemien"}>

          <AppHappyPraemienHeader />
          <SectionProductSearch />
          <SectionRecommendations label={t("app.award.highlights")} />
          <AppHappyPraemienNews />

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>
        </div>
    );
  }
}

export default HappyPraemien;
