import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { Link } from "react-router-dom";
import t from "../../../helpers/t9n";

class ShippingOverviewBox extends Component {
  render() {
    return (
      <div className="shipping-box shipping-box--overview">
        <div className="shipping-box__content">
          <span className="shipping-box__heading">
            {t("checkout.overview.adress")}
          </span>
          <span className="shipping-box__row">
            <span className="shipping-box__details">
              <span className="shipping-box__info">
                {this.props.data.salutation} {this.props.data.first_name}{" "}
                {this.props.data.last_name}
              </span>
              <span className="shipping-box__info">
                {this.props.data.company}
              </span>
              <span className="shipping-box__info">
                {this.props.data.street}
              </span>
              <span className="shipping-box__info">
                {this.props.data.zip} {this.props.data.city}
              </span>
            </span>
          </span>
          <span className="shipping-box__details">
            <span className="shipping-box__row shipping-box__row--nm">
              <span className="shipping-box__label">{t("app.phone")}: </span>
              <span className="shipping-box__info">
                {this.props.data.phone}
              </span>
            </span>
            <span className="shipping-box__row shipping-box__row--email">
              <span className="shipping-box__label">
                {t("app.email_short")}:{" "}
              </span>
              <span className="shipping-box__info">
                {this.props.data.email}
              </span>
            </span>
            <span className="shipping-box__row shipping-box__row--nm">
              <span className="shipping-box__label shipping-box__label--bold">
                {t("checkout.mail.check")} {this.props.data.email}
              </span>
            </span>
          </span>
        </div>

        <div className="shipping-box__actions">
          <Link to={`/${window.currentLang}/cart`}>
            <Button
              text={t("checkout.back.to.cart")}
              textColor="black"
              type="secondary"
            />
          </Link>
          <Button
            text={t("checkout.change.adress.button")}
            textColor="black"
            type="secondary"
            onClick={() => this.props.stepFunc(1)}
          />
        </div>
      </div>
    );
  }
}

export default ShippingOverviewBox;
