import React, { Component } from "react";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import CardSlider from "../../components/module/card-slider/CardSlider";
import AppSalesAttackHeader from "../../components/module/app-salesattack-header/AppSalesAttackHeader";
import AppSalesAttackPerformance from "../../components/module/app-salesattack-performance/AppSalesAttackPerformance";
import {
  getContentPage,
  getTransactionsForSalesPush,
  getAppPrefs,
  getRankingLevels,
  getUserRankingForSalesPush
} from "../../data/api";
import { AppContext } from "../../data/ContextProvider";
import { CONTENT_SPIELREGELN } from "../../data/constants";
import t from "../../helpers/t9n";

class SalesAttack extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isHeaderOpen: true,
      loading: true
    }
  }

  async componentDidMount() {
    const appPrefs = await getAppPrefs()
    const rankinglevels = await getRankingLevels()
    const page = await getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)
    const transactions = await getTransactionsForSalesPush()
    const user_rank = await getUserRankingForSalesPush()

    this.setState({
      transactions: transactions.transactions,
      rankingLevels: rankinglevels.rankinglevels,
      user_rank: user_rank.user_rank,
      appPrefs: appPrefs.preferences,
      pageText: page.text,
      loading: false
    })
  }

  handleHeader = () => {
    this.setState({
      isHeaderOpen: !this.state.isHeaderOpen
    })
  }

  render() {
    if (this.state.loading)
      return t("global.loading")
    return (
      <>
        <div className={"content app jackpot salesattack"}>

          <AppSalesAttackHeader
            text={this.state.pageText}
            handleHeader={this.handleHeader}
            isHeaderOpen={this.state.isHeaderOpen}
          />

          <AppSalesAttackPerformance
            handleHeader={this.handleHeader}
            isHeaderOpen={this.state.isHeaderOpen}
            ranking_levels={this.state.rankingLevels}
            transactions={this.state.transactions}
            currencyMode={this.state.appPrefs.currency_mode}
            current_points={this.state.user_rank.current_rank_level_points}
            remaining_target={this.state.user_rank.remaining_transactions_amount}
            start={this.state.appPrefs.start}
            end={this.state.appPrefs.end}
          />

          <SectionRecommendations label={t("app.award.highlights")} />

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>
        </div>
      </>
    );
  }
}

SalesAttack.contextType = AppContext
export default SalesAttack;
