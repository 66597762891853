import React from "react";
import { useParams } from "react-router-dom";
import TeamTrophy from "./TeamTrophy/TeamTrophy";
import Gewinnerreise from "./Gewinnerreise/Gewinnerreise";

const TeamTrophyPage = (props) => {
  let { lang } = useParams();

  return (
    <>
      <TeamTrophy lang={lang} view={props.view} />
    </>
  )
}

export default TeamTrophyPage;
