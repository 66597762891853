import React from "react";
import { useParams } from "react-router-dom";
import Jackpot from "./Jackpot/Jackpot";

const JackpotPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <Jackpot lang={lang} />
    </>
  )
}

export default JackpotPage;
