import React, { Component } from "react";
import Image from "../../components/atom/image/Image";
import { API_HOST } from "../../data/config";
import { AppContext } from "../../data/ContextProvider";

class Content extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isReasonSelected: false,
    }
  }

  handleReason = (reason) => {
    this.setState({
      reason: reason.target.value,
      isReasonSelected: true,
    })
  }

  handleChange = (e) => {
    // console.log(e.target.name, e.target.value)
    if (e.target) {
      const name = e.target.name
      const value = e.target.value
      const state = this.state
      state[name] = value
      this.setState({ ...state });
    }
  }

  render() {

    const pId = "p" + this.props.pageId
    const data = this.props.data

    return (
      <>
        <div className={"content-page"}>
          <div className={"content-page__content"}>
            <div className={"content-page__upper"}>
              <div className={"content-page__text"}>
                <h2>{data[pId + "_upper_content_headline"]}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data[pId + "_upper_content_text"].replace(/(?:\r\n|\r|\n)/g, '<br />')
                  }}>
                </p>
              </div>
              <div className={"content-page__sidebar"}>
                <div className={"content-page__sidebar-heading"}>
                  <h3>Wissenswertes</h3>
                </div>
                {[1, 2, 3].map(i =>
                  <div className={"content-page__sidebar-benefit"}>
                    <span>
                      {data[pId + "_sidebar_benefit_title_" + i]}
                    </span>
                    <p>{data[pId + "_sidebar_benefit_text_" + i]}</p>
                  </div>
                )}
              </div>
            </div>
            <div className={"content-page__center"}>
              <div className={"content-page__image content-page__image--big"}>
                <Image
                  image={`${API_HOST}/asset/${data[pId + "_gallery_img_1"]}?pid=${this.context.user.project_id}`} />
              </div>
              <span>
                <div className={"content-page__image"}>
                  <Image
                    image={`${API_HOST}/asset/${data[pId + "_gallery_img_2"]}?pid=${this.context.user.project_id}`} />
                </div>
                <div className={"content-page__image"}>
                  <Image
                    image={`${API_HOST}/asset/${data[pId + "_gallery_img_3"]}?pid=${this.context.user.project_id}`} />
                </div>
              </span>
            </div>
            <div className={"content-page__lower"}>
              <div className={"content-page__text"}>
                <h2>{data[pId + "_lower_content_headline"]}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data[pId + "_lower_content_text"].replace(/(?:\r\n|\r|\n)/g, '<br />')
                  }}>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Content.contextType = AppContext

export default Content;
