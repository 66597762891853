import React, { Component } from "react";
import { Link } from "react-router-dom";

import CartProduct from "../../components/element/cart-product/CartProduct";
import UncontrolledLottie from "../../components/atom/lottie/UncontrolledLottie";
import Button from "../../components/atom/button/Button";
import emptyWish from "../../assets/lotties/empty-wishlist/anim--icon-emtpy-wishlist";
import { AppContext } from "../../data/ContextProvider";
import { getToken } from "../../lib/lib";
import {
  getWishlist,
  removeFromWishlist,
  removeAllFromWishlist,
  addTheWishlistToCart,
  getUserStats
} from "../../data/api";
import { getProductImage, getMinProductPriceForProject, getVariantForProject, renderAvailabilityState } from "../../helpers/utils";
import t from "../../helpers/t9n";

class Wishlist extends Component {

  constructor(props) {
    super(props);

    this.state = {
      wishlist: [],
      hasData: false,
      loading: true,
      hasWishlistUpdated: props.hasWishlistUpdated
    }
  }

  handleRemoveFromWishlist = async (id, variantId) => {
    await removeFromWishlist(getToken(), id, variantId)
    await this.loadData()
  }

  removeAllFromWishlist = async () => {
    await removeAllFromWishlist(getToken())
    await this.loadData()
  }

  addTheWishlistToCart = async () => {
    await addTheWishlistToCart(getToken())
    await this.loadData()
  }

  getProducts = () => {
    const elements = []
    this.state.wishlist.product_wishes.forEach((elem) => {
      const variant = getVariantForProject(elem.product.options, this.context.getUserData().project_code, elem.option_id)
      // the variant is not avail in the project anymore
      if (variant !== null) {
        elements.push(<CartProduct
                                   product={elem.product}
                                   title={elem.product.title}
                                   price={getMinProductPriceForProject(elem.product.options, this.context.getUserData().project_code)}
                                   variant={variant.article_number}
                                   variantId={elem.option_id}
                                   id={elem.product.number}
                                   pid={elem.product.id}
                                   image={getProductImage(elem.product.images)}
                                   removeFromWishlistCallback={this.handleRemoveFromWishlist}
                                   isAvailable={elem.product.options[0].state === "available"}
                                   isWishlistProduct={true}
                                   quantity={1}
                                   shippingDays={renderAvailabilityState(variant.availability_state)}
                                   key={elem.product.id} />)
      }
    })

    return elements
  }

  loadStats = async () => {
    const stats = await getUserStats()
    this.context.setStatsOfUser(stats.stats)
  }

  loadData = async () => {
    const wishlist = await getWishlist(getToken())
    await this.loadStats()

    this.setState({
      wishlist: wishlist,
      hasData: (wishlist.product_wishes.length > 0)
    }, () => {
      this.setState({
        loading: false,
      })
    })
  }

  async componentDidMount() {
    if (this.context.getUserData().order_suspension) {
      this.context.toggleModalInfo(true, "Sie können aktuell keine Prämienartikel bestellen, da Sie einer Bestellsperre unterliegen.\n" +
        "Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.")
    }

    await this.loadData()
  }

  // async componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (prevProps.hasWishlistUpdated !== this.props.hasWishlistUpdated) {
  //     this.setState({
  //       loading: true,
  //     })
  //     await this.loadData()
  //   }
  // }

  render() {

    if (this.state.loading) {
      return "loading.."
    } else {

      return (
        <>
          <div className={"content wishlist"}>
            <div className={"wishlist__container"}>
            {this.state.hasData ?
              <>
              <div className={`wishlist__left ${this.state.isOneUnavailable ? "wishlist__left--unavailable" : ""}`}>
                {
                  this.getProducts()
                }
                {/*<Pagination totalItems={27} itemsPerPage={9} currentPage={1} />*/}
                  </div>
                  <div className={"wishlist__right"}>
                    <div className={"wishlist__info"}>
                      <div className={"wishlist__actions"}>
                        <Button type={"danger"} textColor={"black"} icon={"garbage"} text={t("global.wishlist.page.remove.all")}
                                onClick={this.removeAllFromWishlist} />
                        <Button type={"primary"} textColor={"white"} text={t("global.wishlist.page.add.all")}
                                onClick={this.addTheWishlistToCart} />
                      </div>
                        <p>{t("global.wishlist.page.hint")}</p>
                    </div>
                  </div>
                </>
              :
              <div className={`wishlist__left wishlist__left--empty`}>
                  <div className={"wishlist__empty"}>
                    <span className={"wishlist__lottie-circle"}>
                      <UncontrolledLottie animationData={emptyWish} />
                    </span>
                    <h2>{t("global.wishlist.page.empty")}</h2>
                    <span className={"wishlist__empty-actions"}>
                      <Link to={`/${window.currentLang}/shop`}>
                        <Button type={"primary"}
                                textColor={"white"}
                                text={t("global.switch.to.products")} />
                      </Link>
                      <Link to={`/${window.currentLang}/cart`}>
                        <Button type={"secondary"} textColor={"black"} text={t("global.wishlist.page.button.cart")} />
                      </Link>
                    </span>
                  </div>
                </div>
            }
            </div>
          </div>
        </>
      );
    }
  }
}

Wishlist.contextType = AppContext;

export default Wishlist;
