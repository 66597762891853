import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import PropTypes from "prop-types";
import ballons from "../../../assets/lotties/promobooster/anim--ballons";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import { withRouter } from "react-router-dom";
import { getContentPage } from "../../../data/api";
import { CONTENT_SPIELREGELN } from "../../../data/constants";
import t from "../../../helpers/t9n";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import { getAssetSlider } from "../../../data/api";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";

class AppPromoBoosterHeaderAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isOpen: true,
      headerSlider: []
    }
  }

  async componentDidMount() {
    const page = await getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)
    const headerSlider = await getAssetSlider('header')

    this.setState({
      pageText: page.text,
      loading: false,
      headerSlider: headerSlider.slider
    })
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    const text = this.state.pageText

    return (
      <>
        {this.state.headerSlider.length > 0 &&
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        }
        <div
          className={`app-header__section promobooster-header__section--access ${this.state.isOpen ? "app-header__section--open" : ""}`}>
          <video loop autoPlay>
            <source
              src={`${process.env.PUBLIC_URL}/videos/SalesAttack/anim--blue-house-bg.mp4`}
              type="video/mp4" />{t("app.video.no.support")}
          </video>
          <div className={"app-header__content"}>
            <div className={"promobooster-header__upper"}>
              <span className={"app-header__heading"}>
                <div>
                  <span>
                    <Icon icon={"app-promobooster"} />
                  </span>
                  <h1>{text.p33_header_content_headline}</h1>
                </div>
                <h2>{text.p33_header_content_subline}</h2>

                <p
                  dangerouslySetInnerHTML={{
                    __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                  }}>
                </p>

                <span className={"app-header__actions"}>
                  <Button type={"link"} text={`${this.state.isOpen ? t("global.close.info") : t("global.open.info")}`}
                          textColor={"white"} icon={"arrow"} onClick={() => {
                    this.setState({ isOpen: !this.state.isOpen })
                  }} />
                  <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                          onClick={() => {
                            this.props.history.push(`/${window.currentLang}/rules`)
                          }} />
                </span>
              </span>
            </div>
          </div>
          <span className={"app-header__box"}>
            <UncontrolledLottie animationData={ballons} />
          </span>
        </div>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}

AppPromoBoosterHeaderAccess.propTypes = {
  handleHeader: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
};
AppPromoBoosterHeaderAccess.defaultProps = {
  isHeaderOpen: false,
};

AppPromoBoosterHeaderAccess.contextType = AppContext

export default withRouter(AppPromoBoosterHeaderAccess);
