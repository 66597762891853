import React, { Component } from "react";
import { addToCart, addToWishlist, getUserStats, removeFromWishlist } from "./api";
import { getToken } from "../lib/lib";

export const AppContext = React.createContext();

class ContextProvider extends Component {

  state = {
    pointsPrice: 0.2,
    isLoginModalVisible: false,
    lang: "de",
    availableLanguages: ["de", "en", "fr"],
    isUserLoggedIn: false,
    isManagerLoggedIn: false,
    isMainMenuOpen: false,
    isCategoryMenuOpen: false,
    categoryChildren: null,
    selectedCategory: "Neuheiten",
    isSubCategoryMenuOpen: false,
    isSearchOpen: false,
    isPopupCartVisible: false,
    isModalInfoVisible: false,
    isModalContractInfoVisible: false,
    isModalContractSuccessisible: false,
    isClubApp: false,
    hasShopAccess: false,
    modalInfoMessage: null,
    hasModalInfoError: false,
    isNavbarScrolling: false,
    hasWishlistUpdated: false,
    user: {
      uid: "",
      username: "",
      balance: 223500
    },
    currentPlace: 0,
    project: {},
    stats: {
      contracts: false
    },

    setLanguage: newLang => {
      const origin = window.origin;
      const currentPath = window.location.pathname;
      const noLangPath = currentPath.substr(3);

      window.location = origin + "/" + newLang.toLowerCase() + noLangPath;
    },

    getAvailableLanguages: () => {
      let langs = [...this.state.availableLanguages];
      langs = langs.map(el => {
        return el.toUpperCase();
      });
      return langs;
    },

    isLoggedIn: () => {
      return this.state.isUserLoggedIn;
    },

    getUserStats: async () => {
      const stats = await getUserStats()
      this.setState({
        stats: stats.stats
      })
    },

    hasOpenContracts: () => {
      if (this.state.user.contracts_enabled) {
        return this.state.stats.contracts
      }
      return false
    },

    handleMainMenu: () => {
      this.setState({
        isMainMenuOpen: !this.state.isMainMenuOpen,
        isCategoryMenuOpen: false,
        isSearchOpen: false,
        isPopupCartVisible: false,
      })
    },

    closeAllMenus: () => {
      this.setState({
        isMainMenuOpen: false,
        isCategoryMenuOpen: false,
        isSearchOpen: false
      })
    },

    handleCategoryMenu: () => {
      if (this.state.isSubCategoryMenuOpen) {
        this.setState({
          isMainMenuOpen: false,
          isSubCategoryMenuOpen: false,
          isCategoryMenuOpen: !this.state.isCategoryMenuOpen
        })
      } else {
        this.setState({
          isMainMenuOpen: false,
          isPopupCartVisible: false,
          isCategoryMenuOpen: !this.state.isCategoryMenuOpen
        })
      }
    },

    handleSubCategoryMenu: (mainCat, children) => {
      if (!mainCat && !children) {
        this.setState({
          isSubCategoryMenuOpen: false
        })
        return
      }

      if (!this.state.isSubCategoryMenuOpen) {
        this.setState({
          selectedCategory: mainCat,
          categoryChildren: children,
          isSubCategoryMenuOpen: !this.state.isSubCategoryMenuOpen
        })
      } else {
        this.setState({
          selectedCategory: mainCat,
          categoryChildren: children,
        })
      }
    },

    handleSearch: () => {
      this.setState({
        isSearchOpen: !this.state.isSearchOpen,
      })
    },

    handleAddToCart: (variantId, quantity) => {
      const response = addToCart(getToken(), variantId, quantity)
      response.then(res => {
        getUserStats().then((stats) => {
          this.setState({
            stats: stats.stats,
            isPopupCartVisible: this.state.isPopupCartVisible ? this.state.isPopupCartVisible : !this.state.isPopupCartVisible
          })
        })
      })
    },

    handleAddToWishlist: (id, variantId = null) => {
      const response = addToWishlist(getToken(), id, variantId)
      response.then(res => {
        getUserStats().then((stats) => {
          this.setState({
            stats: stats.stats
          })
        })
        return true
      })
    },

    handleRemoveFromWishlist: (id, variantId = null) => {
      const response = removeFromWishlist(getToken(), id, variantId)
      response.then(res => {
        getUserStats().then((stats) => {
          this.setState({
            stats: stats.stats
          })
        })
        return true
      })
    },

    toggleNavbarScroll: () => {
      this.setState({
        isNavbarScrolling: !this.state.isNavbarScrolling
      })
    },

    togglePopupCart: (value = null) => {
      if (value !== null) {
        this.setState({
          isPopupCartVisible: value
        })
      } else {
        this.setState({
          isPopupCartVisible: !this.state.isPopupCartVisible
        })
      }
    },

    toggleModalInfo: (isError, message = null) => {
      this.setState({
        modalInfoMessage: message,
        isModalInfoVisible: !this.state.isModalInfoVisible,
        hasModalInfoError: isError
      })
    },

    toggleModalNewContract: (isTrue) => {
      this.setState({
        isModalContractInfoVisible: isTrue
      })
    },

    toggleModalSuccessContract: () => {
      this.setState({
        isModalContractSuccessisible: !this.state.isModalContractSuccessisible
      })
    },

    saveUserData: (userData) => {
      this.setState({
        ...this.state,
        isUserLoggedIn: true,
        user: {
          hasAcceptedGdpr: userData.accept_gdpr,
          uid: userData.id,
          order_suspension: userData.order_suspension,
          username: userData.email,
          app_id: userData.project.app_id,
          project_id: userData.project_id,
          project_code: userData.project.code,
          shop_status: userData.project.shop_status,
          company: userData.company,
          company_number: userData.company_number,
          subscribed_infos: userData.subscribed_infos,
          dob: userData.dob,
          welcome_tool_enabled: userData.welcome_tool_enabled,
          contracts_enabled: userData.contracts_enabled,
          tracking_code: userData.tracking_code
        },
        stats: {
          ...this.state.stats,
          points: userData.points
        }
      }, () => {
        return true
      })
    },

    getUserData: () => {
      return this.state.user;
    },

    saveManagerData: (userData) => {
      this.setState({
        ...this.state,
        isManagerLoggedIn: true,
        user: {
          uid: userData.id,
          username: userData.email,
          project_id: userData.project_id,
          company: userData.company,
          company_number: userData.company_number,
          region: userData.region,
          area: userData.area,
          department: userData.department,
          onboarding_completed: userData.onboarding_completed,
          compl_tool_enabled: userData.compl_tool_enabled,
          compl_tool_disabled: userData.compl_tool_disabled,
          tracking_code: userData.tracking_code
        }
      }, () => {
        return true
      })
    },

    saveProjectData: (projectData) => {
      let email = 'service@incentivearena.de'
      if (projectData && projectData.project[0] && projectData.project[0].support_email) {
        email = projectData.project[0].support_email
      }

      localStorage.setItem('project_id', projectData.project[0].id);

      this.setState({
        project: projectData.project[0],
        projectSupportMail: email
      })
    },

    getProjectData: () => {
      return this.state.project;
    },

    getProjectID: () => {
      if (localStorage.getItem('project_id')) {
        return localStorage.getItem('project_id')
      } else {
        return 0
      }
    },

    getNameOfUser: () => {
      // TODO initilize on load and return this.state.user.name
      return localStorage.getItem('name')
    },

    getAppIdOfUser: () => {
      return localStorage.getItem('app_id')
    },

    getPointsOfUser: () => {
      return this.state.stats.points
      // return localStorage.getItem('points')
    },

    setPointsOfUser: (pts) => {
      this.setState({})
      localStorage.setItem('points', pts);
    },

    setStatsOfUser: (stats) => {
      this.setState({
        stats: stats
      })
    },

    getStatsOfUser: () => {
      return this.state.stats
    },

    setIsClubApp: (isClub) => {
      this.setState({
        isClubApp: isClub
      })
    },

    getHasShopAccess: () => {
      return this.state.hasShopAccess
    },

    setHasShopAccess: (hasAccess) => {
      this.setState({
        hasShopAccess: hasAccess
      })
    },

    setHasWishlistUpdated: (value) => {
      this.setState({
        hasWishlistUpdated: value
      })
    },

    setCurrentPlace: (place) => {
      this.setState({
        currentPlace: place
      })
    }


  };

  // OPTIMIZE: Move out of ContextProvider
  componentDidMount() {
    const pathLang = window.location.pathname.substr(1, 2);
    const currentLang =
      pathLang === "" ||
      !this.state.getAvailableLanguages().includes(pathLang.toUpperCase())
        ? this.state.getAvailableLanguages()[0]
        : pathLang;

    window.currentLang = currentLang.toLowerCase();

    this.setState({
      lang: currentLang.toLowerCase()
    });
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default ContextProvider;
