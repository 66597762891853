import React, { Component } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../../../data/ContextProvider";

import ShippingOverviewBox from "../../../components/element/shipping-box/ShippingOverviewBox";
import CartProduct from "../../../components/element/cart-product/CartProduct";
import Checkbox from "../../../components/atom/checkbox/Checkbox";
import Button from "../../../components/atom/button/Button";
import Icon from "../../../components/atom/icons/Icon";

import ModalMorePoints from "../../../components/module/modal-more-points/ModalMorePoints";

import { createOrder, getCart, removeFromCart } from "../../../data/api";
import {
  getProductImage,
  getProductPrice,
  renderAvailabilityState,
} from "../../../helpers/utils";
import { roundTo } from "../../../lib/lib";
import t from "../../../helpers/t9n";

class Overview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCheckboxChecked: false,
      loading: true,
      cart: {},
      address: {},
      isModalPointsVisible: false,
    };
  }

  handleCheckbox(e) {
    this.setState({
      isCheckboxChecked: e.target.checked,
    });
  }

  toggleModalPoints = () => {
    this.setState({ isModalPointsVisible: !this.state.isModalPointsVisible });
  };

  closeOrderTransaction = (order) => {
    this.setState({ isModalPointsVisible: false });
    if (order.id) {
      this.props.stepFunc(3);
    }
  };

  handleRemoveFromCart = async (id) => {
    const response = removeFromCart(id);

    response.then((res) => {
      this.setState({
        cart: res.cart,
        total_price: res.total_price,
      });
    });
  };

  handleSubmitOrder = async () => {
    try {
      let addressID = 0; // default user address

      //ein Hack, mit personnel_number wird Adresse aus dem Useroject von einer zesaetzlichen aus addresses Tabelle unterschieden.
      if (!this.props.address.personnel_number) {
        addressID = this.props.address.id;
      }

      const response = await createOrder(addressID);

      if (response.order.id) {
        this.props.stepFunc(3);
      }
    } catch (error) {
      this.props.stepFunc(3, error.data.error);
    }
  };

  loadData = async () => {
    const cart = await getCart();

    this.setState({
      cart: cart.cart,
      total_price: cart.total_price,
      loading: false,
    });
  };

  async componentDidMount() {
    this.loadData();
  }

  renderMsg = (deltaPositive, deltaInCurrency) => {
    return (
      <span className="credit-box__notification">
        {t("cart.credit.box.text1")} <b>{deltaPositive.toLocaleString()}</b>{" "}
        {t("cart.credit.box.text2")} <b>{deltaInCurrency.toLocaleString()} </b>{" "}
        {t("cart.credit.box.text3")}.
      </span>
    );
  };

  render() {
    if (this.state.loading) {
      return "loading...";
    } else {
      const cart = this.state.cart;
      const balance = this.context.getPointsOfUser();
      const sum = this.state.total_price;

      const delta = balance - sum;
      const isInsufficient = delta < 0;
      const deltaPositive = Math.abs(delta);

      const projectData = this.context.getProjectData();
      const calculation_factor =
        projectData && projectData.calculation_factor
          ? parseInt(projectData.calculation_factor)
          : 1;
      const factor = calculation_factor === 2 ? 1 : 10;

      const deltaInCurrency = roundTo(
        (deltaPositive / factor) * 1.0249 + 0.35,
        2
      );

      const user_shop_access_disabled =
        this.context.getUserData().order_suspension;
      const shop_is_closed = this.context.getUserData().shop_status === 0;

      return (
        <>
          <div className="checkout__left">
            <div className="checkout__list">
              {cart.map((elem) => {
                return (
                  <CartProduct
                    product={elem.product}
                    title={elem.product.title}
                    price={getProductPrice(
                      elem.option,
                      this.context.getUserData().project_code
                    )}
                    variant={elem.option.article_number}
                    variantId={elem.option.id}
                    id={elem.product.number}
                    pid={elem.product.id}
                    image={getProductImage(elem.images)}
                    quantity={elem.item.quantity}
                    quantityLabel={t("checkout.qty.label")}
                    isAvailable={elem.option.state === "available"}
                    isOverviewProduct={true}
                    shippingDays={renderAvailabilityState(
                      elem.option.availability_state
                    )}
                    key={elem.product.id}
                    removeFromCartCallback={this.handleRemoveFromCart}
                  />
                );
              })}
              <span className="checkout__summary">
                <span className="checkout__amount">
                  {t("checkout.qty.products")}
                  <span className="c-orange">{cart.length}</span>
                </span>
                <span className="checkout__sum">
                  {t("cart.popup.sum")}
                  <span className="c-orange">
                    {Number(this.state.total_price)} {t("app.points")}
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div className="checkout__right">
            <div className="checkout__column">
              <span className="checkout__info">
                {t("checkout.hint.check")}
              </span>

              <ShippingOverviewBox
                stepFunc={this.props.stepFunc}
                data={this.props.address}
              />

              {isInsufficient && (
                <div className="checkout__submit-box credit-box__actions">
                  <span className="credit-box__row">
                    {this.renderMsg(deltaPositive, deltaInCurrency)}
                  </span>

                  <button
                    onClick={this.toggleModalPoints}
                    className="button-points"
                  >
                    <span className="button-points__icon">
                      {" "}
                      <Icon icon="coins" />
                    </span>
                    {t("checkout.load.points.button")}
                  </button>
                </div>
              )}

              {shop_is_closed ? (
                <div className="checkout__submit-box">
                  <div className="credit-box__content">
                    <span className="credit-box__balance-label">
                      {user_shop_access_disabled && (
                        <span>
                          {t("checkout.no.orders.possible")}
                        </span>
                      )}
                      {shop_is_closed && (
                        <span>
                          {t("checkout.no.orders.hint")}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className={"credit-box__actions"}>
                    <Link to={`/${window.currentLang}/contact`}>
                      <Button
                        text={t("checkout.contact.button")}
                        textColor={"white"}
                        type={"primary"}
                      />
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="checkout__submit-box">
                  <Checkbox
                    label={[
                      t("checkout.agb.check1"),
                      <a
                        key={"submit_info_tos"}
                        href={`/${window.currentLang}/tos`}
                      >
                        {t("checkout.agb.check2")}
                      </a>,
                      t("checkout.agb.check3"),
                    ]}
                    onChange={(e) => this.handleCheckbox(e)}
                    disabled={isInsufficient}
                  />
                  <Button
                    type="primary"
                    text={t("checkout.confirm.order.button")}
                    textColor="white"
                    state={
                      this.state.isCheckboxChecked && cart.length > 0
                        ? "active"
                        : "inactive"
                    }
                    onClick={() => this.handleSubmitOrder()}
                  />
                </div>
              )}
            </div>
          </div>
          {this.state.isModalPointsVisible && (
            <ModalMorePoints
              cartAmount={sum}
              userPoints={balance}
              delta={deltaPositive}
              deltaInCurrency={deltaInCurrency}
              selectedAddress={this.props.address}
              onClose={this.toggleModalPoints}
              onCloseOrderTransaction={this.closeOrderTransaction}
            />
          )}
        </>
      );
    }
  }
}

Overview.contextType = AppContext;
export default Overview;
