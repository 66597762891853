import React from 'react';
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import './sass/styles.sass';
import ShopLayout from "./layout/ShopLayout";
import ShopPage from "./pages/ShopPage";
import LoginLayout from "./layout/LoginLayout";
import LoginPage from "./pages/Login/LoginPage";
import ImpersonatePage from "./pages/Login/ImpersonatePage";
import Icons from "./components/atom/icons/Icons";
import MainMenu from "./components/module/main-menu/MainMenu";
import ProductPage from "./pages/ProductPage";
import CartPage from "./pages/CartPage";
import CartLayout from "./layout/CartLayout";
import CheckoutPage from "./pages/CheckoutPage";
import CheckoutLayout from "./layout/CheckoutLayout";
import ShopHomePage from "./pages/ShopHomePage";

import WishlistPage from "./pages/WishlistPage";
import WishlistLayout from "./layout/WishlistLayout";
import OrdersPage from "./pages/OrdersPage";
import OrdersLayout from "./layout/OrdersLayout";
import OrdersDetailPage from "./pages/OrdersDetailPage";

import Private from "./pages/Private/Private";
import ProductLayout from "./layout/ProductLayout";
import EmptyLayout from "./layout/EmptyLayout";
import Imprint from "./pages/Imprint/Imprint";
import AccountLayout from "./layout/AccountLayout";
import AccountPage from "./pages/AccountPage";
import PerformancedataPage from "./pages/PerformancedataPage";
import ProfilePage from "./pages/ProfilePage";
import NewsPage from "./pages/NewsPage";
import ContactPage from "./pages/ContactPage";
import Privacy from "./pages/Privacy/Privacy";
import JackpotPage from "./pages/JackpotPage";
import HerosPlacePage from "./pages/HerosPlacePage";
import Tos from "./pages/Tos/Tos";
import Shipping from "./pages/Shipping/Shipping";
import Agb from "./pages/Agb/Agb";
import FeuerfreiPage from "./pages/FeuerfreiPage";
import SalesPushPage from "./pages/SalesPushPage";
import SalesAttackPage from "./pages/SalesAttackPage";
import ScrollToTop from "./components/basics/ScrollToTop/ScrollToTop";
import NeukundenPage from "./pages/NeukundenPage";
import PromoBoosterPage from "./pages/PromoBoosterPage";

import CasinoPage from "./pages/CasinoPage";
import SchaffeIchPage from "./pages/SchaffeIchPage";
import HighperformancePage from "./pages/HighperformancePage";
import ClubLayout from "./layout/ClubLayout";
import ContentPage from "./pages/ContentPage";
import SwitchFragmentSupport from "./components/wrapper/SwitchFragmentSupport";
import GewinnerreisePage from "./pages/GewinnerreisePage";
import RulesPage from "./pages/Rules/RulesPage";
import HappyPraemienPage from "./pages/HappyPraemienPage";
import TeamTrophyPage from "./pages/TeamTrophyPage";
import PraemienshopClubPage from "./pages/PraemienshopClubPage";
import SalesTriathlonPage from "./pages/SalesTriathlon/SalesTriathlon";
import GloriaRewardsPage from "./pages/GloriaRewards/GloriaRewards";
import NotFoundPage from "./pages/NotFoundPage";

import ETracker from "./components/module/eTracker";

import { AppContext } from "./data/ContextProvider";
import { getProjectByDomain } from "./data/api";


import { checkToken } from "./lib/lib";

// BASICSHOP // Achtung die Page für den Prämienshop heisst Private, wieso auch immer
import {
  APP_HIGHPERFORMANCE,
  APP_GEWINNERREISE,
  APP_SHOPCLUB, // PRAEMIENSHOPCLUB - TopClub mit Prämien
  APP_SALESPUSH,
  APP_SALESATTACK,
  APP_TEAMTROPHY,
  APP_SCHAFFEICH,
  APP_NEUKUNDENJAEGER,
  APP_HEROSPLACE,
  APP_CASINO,
} from "./data/constants";

import ComplTool from './pages/ComplTool/ComplTool';
import ContractsNew from './pages/Contracts/pages/ContractsNew';
import ContractsArchived from './pages/Contracts/pages/ContractsArchived';
import { getWithExpiry, setWithExpiry } from './helpers/utils';
import FooterLayout from './layout/FooterLayout';

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      app_id: null,
    }

    this.notAllowedForShop = [APP_HIGHPERFORMANCE, APP_GEWINNERREISE]
    this.clubApps = [APP_SHOPCLUB, APP_HIGHPERFORMANCE, APP_GEWINNERREISE]
    this.appsWithRankingPage = [APP_SHOPCLUB]
    this.appsWithWertungPage = [APP_SHOPCLUB]
    this.appsWithPerformancedataPage = [APP_HEROSPLACE, APP_SALESATTACK, APP_SALESPUSH, APP_NEUKUNDENJAEGER, APP_SCHAFFEICH, APP_TEAMTROPHY, APP_SHOPCLUB, APP_HIGHPERFORMANCE, APP_GEWINNERREISE, APP_CASINO]
  }

  async componentDidMount() {

    try {

      const userData = await checkToken();

      if (userData) {
        const appId = parseInt(this.context.getAppIdOfUser(), 10)
        const hasShopAccess = !this.notAllowedForShop.includes(appId)

        const projectData = await getProjectByDomain(document.domain)

        if (projectData) {
          this.context.saveProjectData(projectData)
        }


        this.context.setHasShopAccess(hasShopAccess)
        this.context.saveUserData(userData);

        await this.context.getUserStats();

        // const openContracts = this.context.hasOpenContracts()
        // const getExpiration = getWithExpiry("contracts")

        // if(getExpiration === openContracts) {
        //   this.context.toggleModalNewContract(false)
        // } else if (!openContracts) {
        //   this.context.toggleModalNewContract(false)
        // } else {
        //   this.context.toggleModalNewContract(true)
        // }

        if (hasShopAccess && userData.order_suspension) {
          this.context.toggleModalInfo(true, "Sie können aktuell keine Prämienartikel bestellen, da Sie einer Bestellsperre unterliegen.\n" +
            "Sollten Sie hierzu Rückfragen haben, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner.")
        }

        this.context.setIsClubApp(this.clubApps.includes(appId))

        this.setState({
          app_id: appId,
          loading: false,
        })

      } else {

        const projectData = await getProjectByDomain(document.domain)

        if (projectData) {
          this.context.saveProjectData(projectData)

          const appId = parseInt(projectData.project[0].app_id, 10)
          this.context.setIsClubApp(this.clubApps.includes(appId))
          this.context.setHasShopAccess(!this.notAllowedForShop.includes(appId))

          this.setState({
            app_id: appId,
            app_name: projectData.project[0].name,
            isDomainUnconfigured: false,
            loading: false,
          })

        } else {
          console.log("PLEASE REGISTER DOMAIN IN DB")
        }
      }


    } catch (e) {
      if (e && e.status === 401) {
        this.setState({
          app_id: null,
          loading: false
        })
      } else {
        //TODO: SHOW ERROR PAGE by state, see before render Login
        this.setState({
          app_id: null,
          isDomainUnconfigured: true,
          loading: false
        })
      }
    }
  }

  getHomePageForAppId = () => {
    switch (this.state.app_id) {
      case 1:
        return (
          <ShopLayout>
            <Private />
          </ShopLayout>
        )
      case 2:
        return (
          <AccountLayout>
            <JackpotPage />
          </AccountLayout>
        )
      case 3:
        return (
          <AccountLayout>
            <HerosPlacePage />
          </AccountLayout>
        )
      case 4:
        return (
          <AccountLayout>
            <SalesPushPage />
          </AccountLayout>
        )
      case 5:
        return (
          <AccountLayout>
            <SalesAttackPage />
          </AccountLayout>
        )
      case 6:
        return (
          <AccountLayout>
            <NeukundenPage />
          </AccountLayout>
        )
      case 7:
        return (
          <AccountLayout>
            <PromoBoosterPage />
          </AccountLayout>
        )
      case 8:
        return (
          <AccountLayout>
            <FeuerfreiPage />
          </AccountLayout>
        )
      case 9:
        return (
          <AccountLayout>
            <CasinoPage />
          </AccountLayout>
        )
      case 10:
        return (
          <AccountLayout>
            <SchaffeIchPage />
          </AccountLayout>
        )
      case 11:
        return (
          <ClubLayout isClub={true}>
            <PraemienshopClubPage view={"home"} />
          </ClubLayout>
        )
      case 12:
        return (
          <ClubLayout>
            <HighperformancePage view={"home"} />
          </ClubLayout>
        )
      case 13:
        return (
          <ClubLayout isClub={false}>
            <GewinnerreisePage view={"home"} />
          </ClubLayout>
        )
      case 14:
        return (
          <AccountLayout>
            <HappyPraemienPage />
          </AccountLayout>
        )
      case 15:
        return (
          <AccountLayout>
              <TeamTrophyPage view={"home"} />
            </AccountLayout>
        )
      case 16:
        return (
          <AccountLayout>
            <SalesTriathlonPage />
          </AccountLayout>
        )
      case 17:
        return (
          <AccountLayout>
            <GloriaRewardsPage />
          </AccountLayout>
        )
      default:
        break;
    }
  }

  getRoutesForShop() {
    if (!this.notAllowedForShop.includes(this.state.app_id)) {
      return (
        <>
          <Route exact path="/:lang?/product/:id?">
            <ProductLayout>
              <ProductPage />
            </ProductLayout>
          </Route>
          <Route exact path="/:lang?/shop/:category?/:subcategory?">
            <ShopLayout>
              <ShopPage />
            </ShopLayout>
          </Route>
          <Route exact path="/:lang?/cart">
            <CartLayout>
              <CartPage />
            </CartLayout>
          </Route>
          <Route exact path="/:lang?/wishlist">
            <WishlistLayout>
              <WishlistPage />
            </WishlistLayout>
          </Route>
          <Route exact path="/:lang?/orders/:orderid">
            <OrdersLayout>
              <OrdersDetailPage />
            </OrdersLayout>
          </Route>
          <Route exact path="/:lang?/orders">
            <OrdersLayout>
              <OrdersPage />
            </OrdersLayout>
          </Route>
          <Route exact path="/:lang?/checkout">
            <CheckoutLayout>
              <CheckoutPage />
            </CheckoutLayout>
          </Route>
          <Route exact path="/:lang?/account">
            <AccountLayout>
              <AccountPage />
            </AccountLayout>
          </Route>
          <Route exact path="/:lang?/home">
            <ShopLayout>
              <ShopHomePage />
            </ShopLayout>
          </Route>
          <Route exact path="/:lang?/shipping">
            <EmptyLayout>
              <Shipping />
            </EmptyLayout>
          </Route>
          <Route exact path="/:lang?/contact">
            <AccountLayout>
              <ContactPage />
            </AccountLayout>
          </Route>
          <Route exact path="/:lang?/tos">
            <AccountLayout>
              <Tos />
            </AccountLayout>
          </Route>
          <Route exact path="/:lang?/profile">
            <AccountLayout>
              <ProfilePage />
            </AccountLayout>
          </Route>
          <Route exact path="/:lang?/news">
            <AccountLayout>
              <NewsPage />
            </AccountLayout>
          </Route>
          <Route exact path="/:lang?/agb">
            <AccountLayout>
              <Agb />
            </AccountLayout>
          </Route>
        </>
      )
    }
  }

  getRoutesForHighperformance() {
    const allowed = this.notAllowedForShop.includes(this.state.app_id)
    if (this.state.app_id === APP_HIGHPERFORMANCE) {
      return (
        <>
        <Route exact path="/:lang?/ranking">
          <ClubLayout noShopAccess={allowed}>
            <HighperformancePage view={"ranking"} />
          </ClubLayout>
        </Route>
        <Route exact path="/:lang?/wertungskriterien">
          <ClubLayout noShopAccess={allowed}>
            <HighperformancePage view={"wertung"} />
          </ClubLayout>
        </Route>
        <Route exact path="/:lang?/gewinnerreise">
          <ClubLayout noShopAccess={allowed}>
            <ContentPage />
          </ClubLayout>
        </Route>
        </>
      )
    }
  }

  getRoutesForGewinnerreise() {
    const allowed = this.notAllowedForShop.includes(this.state.app_id)
    if (this.state.app_id === APP_GEWINNERREISE) {
      return (
        <>
        <Route exact path="/:lang?/ranking">
          <ClubLayout noShopAccess={allowed}>
            <GewinnerreisePage view={"ranking"} />
          </ClubLayout>
        </Route>
        <Route exact path="/:lang?/wertungskriterien">
          <ClubLayout noShopAccess={allowed}>
            <GewinnerreisePage view={"wertung"} />
          </ClubLayout>
        </Route>
        <Route exact path="/:lang?/gewinnerreise">
          <ClubLayout noShopAccess={allowed}>
            <ContentPage />
          </ClubLayout>
        </Route>
        </>
      )
    }
  }

  getRoutesForPraemienClub() {
    const allowed = this.notAllowedForShop.includes(this.state.app_id)
    if (this.state.app_id === APP_SHOPCLUB) {
      return (
        <>
        <Route exact path="/:lang?/ranking">
          <ClubLayout noShopAccess={allowed}>
            <PraemienshopClubPage view={"ranking"} />
          </ClubLayout>
        </Route>
        <Route exact path="/:lang?/wertungskriterien">
          <ClubLayout noShopAccess={allowed}>
            <PraemienshopClubPage view={"wertung"} />
          </ClubLayout>
        </Route>
        </>
      )
    }
  }

  getRoutesForTeamTrophy() {
    const allowed = this.notAllowedForShop.includes(this.state.app_id)
    if (this.state.app_id === APP_TEAMTROPHY) {
      return (
        <>
        <Route exact path="/:lang?/motivation">
          <AccountLayout noShopAccess={allowed}>
            <TeamTrophyPage view={"motivation"} />
          </AccountLayout>
        </Route>
        <Route exact path="/:lang?/gewinn">
          <AccountLayout noShopAccess={allowed}>
            <TeamTrophyPage view={"gewinn"} />
          </AccountLayout>
        </Route>
        </>
      )
    }
  }

  getLoginPage() {
    if (this.state.isDomainUnconfigured) {
      return (
        <EmptyLayout fullHeight={true}>
          <NotFoundPage isSubDomainError={true} />
        </EmptyLayout>
      )
    }

    if (this.state.app_id === 7) {
      return (
        <Route path={"/:lang?" || "/:lang/login"}>
          <AccountLayout>
            <PromoBoosterPage />
          </AccountLayout>
        </Route>
      )
    } else {
      return (
        <>
          <Route exact path={"/:lang?/new_password/:reset_token"}>
            <LoginLayout>
              <LoginPage resetPassword={true} />
            </LoginLayout>
          </Route>
          <Route exact path={"/:lang?/impersonate/:impersonate_token"}>
            <LoginLayout>
              <ImpersonatePage />
            </LoginLayout>
          </Route>
          <Route path={"/:lang?" || "/:lang/login/:step?"}>
            <LoginLayout>
              <LoginPage />
            </LoginLayout>
          </Route>
          <Route>
            <Redirect to={`${window.currentLang}/404`} />
          </Route>
        </>
      )
    }
  }

  getBasicRoutes() {
    return (
      <>
        <Route exact path="/:lang?/imprint">
          <EmptyLayout>
            <Imprint />
          </EmptyLayout>
        </Route>

        <Route exact path="/:lang?/privacy">
          <EmptyLayout>
            <Privacy />
          </EmptyLayout>
        </Route>

        <Route exact path="/:lang?/404">
          <EmptyLayout fullHeight={true}>
            <NotFoundPage isSubDomainError={false} />
          </EmptyLayout>
        </Route>

        <Route path="/:lang?/compliance-tool">
          <ComplTool />
        </Route>
      </>
    )
  }

  getLoginFooterRoutes() {
    return (
      <>
        <Route exact path="/:lang?/imprint">
          <FooterLayout>
            <Imprint />
          </FooterLayout>
        </Route>

        <Route exact path="/:lang?/privacy">
          <FooterLayout>
            <Privacy />
          </FooterLayout>
        </Route>
      </>
    )
  }

  render() {

    if (this.state.loading) {
      return "Loading"
    }

    //FIXME darüber nachdenken
    // Bei langsamer DB-Verbindung sieht man beim Page-Wechsel kurz den Login
    if (!this.context.getUserData().hasAcceptedGdpr) {
      return (
        <Router>
          <div
            className={`mainContainer mainContainer--center ${this.context.isNavbarScrolling ? "container--scroll" : ""}`}>
            <ScrollToTop />
            <Icons />
            <SwitchFragmentSupport>
              {this.getBasicRoutes()}
              {this.getLoginPage()}
            </SwitchFragmentSupport>
          </div>
         </Router>
      )
    }

    //If logged in :
    return (
      <>
      <Router>
        {this.context.user.tracking_code &&
            <ETracker />
        }
        <div
          className={`mainContainer ${this.context.isClubApp ? "club" : ""} ${this.context.hasShopAccess ? "shop" : ""} ${this.context.isNavbarScrolling ? "container--scroll" : ""} ${this.context.getAppIdOfUser() === "17" ? "gloria-rewards-app" : ""}`}>
          <ScrollToTop />
          <MainMenu noShopAccess={this.notAllowedForShop.includes(this.state.app_id)}
                    isClub={this.clubApps.includes(this.state.app_id)}
                    hasRanking={this.appsWithRankingPage.includes(this.state.app_id)}
                    hasWertung={this.appsWithWertungPage.includes(this.state.app_id)}
                     // noRules={this.state.app_id === APP_SHOP}
                    hasPerformancedataPage={this.appsWithPerformancedataPage.includes(this.state.app_id)}
                    hasContractsPage={this.context.user.contracts_enabled}
          />
          <Icons />
          <SwitchFragmentSupport>

            {this.getLoginFooterRoutes()}
            {this.getRoutesForShop()}

            <Route exact path="/:lang?/rules">
              <FooterLayout>
                <RulesPage />
              </FooterLayout>
            </Route>

            {this.getRoutesForHighperformance()}
            {this.getRoutesForGewinnerreise()}
            {this.getRoutesForPraemienClub()}
            {this.getRoutesForTeamTrophy()}

            <Route exact path="/:lang?/">
              {this.getHomePageForAppId()}
            </Route>

            <Route exact path="/:lang?/performancedata">
              <AccountLayout>
                <PerformancedataPage />
              </AccountLayout>
            </Route>

            <Route exact path="/:lang?/contracts/new">
              <AccountLayout>
                <ContractsNew />
              </AccountLayout>
            </Route>

            <Route exact path="/:lang?/contracts/archived">
              <AccountLayout>
                <ContractsArchived />
              </AccountLayout>
            </Route>

            {this.getLoginPage()}
          </SwitchFragmentSupport>
        </div>
      </Router>
      </>
    );
  }
}

App.contextType = AppContext

export default App;
