import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import Icon from "../../components/atom/icons/Icon";
import Button from "../../components/atom/button/Button";

import hunter from "../../assets/lotties/neukunden/anim--neukunden-hunter";
import UncontrolledLottie from "../../components/atom/lottie/UncontrolledLottie";

import t from "../../helpers/t9n";
import AppSliderHeader from "../../components/module/app-slider-header/AppSliderHeader";
import { getAssetSlider } from "../../data/api";
import AppAkkordeonHeader from "../../components/module/app-akkordeon-header/AppAkkordeonHeader";
import { AppContext } from "../../data/ContextProvider";

class AppNeukundenHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerSlider: [],
      loading: false
    }
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    const text = this.props.text

    return (
      <>
      {this.state.headerSlider.length > 0 &&
        <AppSliderHeader headerSlider={this.state.headerSlider} />
      }

      <div
        className={`app-header__section neukunden-header__section ${this.props.isHeaderOpen ? "app-header__section--open" : ""}`}>
        <video loop autoPlay>
          <source
            src={`${process.env.PUBLIC_URL}/videos/Neukunden/anim--bg-blue-mountains.mp4`}
            type="video/mp4" />{t("app.video.no.support")}
        </video>
        <div className={"app-header__content"}>
          <div className={"app-header__left"}>
            <span className={"app-header__heading"}>
              <div>
                <span>
                  <Icon icon={"app-neukundenjaeger"} />
                </span>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: text.p33_header_content_headline
                  }}>
                </h1>
              </div>
              <h2>{text.p33_header_content_subline}</h2>
            </span>

            <p
              dangerouslySetInnerHTML={{
                __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
              }}>
            </p>

            <span className={"app-header__actions"}>
              {this.props.isHeaderOpen ?
                <Button type={"link"} text={t("global.close.info")} textColor={"white"} icon={"arrow"}
                        onClick={() => this.props.handleHeader()} />
                :
                <Button type={"link"} text={t("global.open.info")} textColor={"white"} icon={"arrow"}
                        onClick={() => this.props.handleHeader()} />
              }
              <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                      onClick={() => {
                        this.props.history.push(`/${window.currentLang}/rules`)
                      }} />
            </span>
          </div>
        </div>
        <span className={"app-header__box"}>
          <UncontrolledLottie animationData={hunter} />
        </span>
      </div>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}

AppNeukundenHeader.contextType = AppContext
export default withRouter(AppNeukundenHeader);
