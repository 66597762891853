import React from "react";
import PropTypes from "prop-types";
import Icon from "../icons/Icon";

const Button = props => (
  <button
    onClick={props.onClick}
    className={`button button--${props.state}
    button--${props.textColor}
    button--${props.type}
    button--${props.backgroundColor}
    ${props.icon ? "button--has-icon" : ""}
    ${!props.text ? "button--icon-only" : ""}
    ${props.isClubButton ? "button--club" : ""}
    ${props.isGold ? "button--gold" : ""}
    ${props.additionalClass ? props.additionalClass : ""}
    `}
    disabled={props.disabled}
  >
    {props.icon ? <Icon icon={props.icon} /> : null}
    <span className="button-txt">{props.text}</span>
    {props.type === "club-link" &&
    <div className={"button__bubble"}>
      <Icon icon={"arrow"} />
      <p>{props.text}</p>
    </div>
    }

    {props.children}

  </button>
);

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "noOutline", "link", "danger", "club-link"]),
  isGold: PropTypes.bool,
  state: PropTypes.oneOf(["active", "inactive"]),
  backgroundColor: PropTypes.oneOf(["bg-black", "bg-white"]),
  textColor: PropTypes.oneOf(["black", "white"]),
  isClubButton: PropTypes.bool,
  icon: PropTypes.string,
  additionalClass: PropTypes.string,
  onClick: PropTypes.func
};
Button.defaultProps = {
  type: "primary",
  state: "active",
  textColor: "black",
  isClubButton: false,
  isGold: false,
};

export default Button;
