import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { formatDate } from "../../../lib/lib";

class AppHighperformanceWertung extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    }
  }

  render() {
    const { data } = this.state
    let prevEntryDesc = ""

    return (
      <>
      <div className={`highperformance-placement__section highperformance-wertung__section`}>
        <div className={"highperformance-placement__header"}>
          <h2>Wertungskriterien – So punkten Sie</h2>
        </div>
        {/* <Button text={"Infos anzeigen"} type={"secondary"} isClubButton={true} textColor={"white"} /> */}
      </div>
      <div className={`highperformance-ranking__section highperformance-wertung__ranking`}>
        <div className={"highperformance-ranking__content"}>
          <div className={"highperformance-ranking__list"}>
            <div className={"highperformance-ranking__list-header"}>
              <ul>
                <li>Wertungskriterien</li>
                <li>Beschreibung</li>
                <li>Zeitraum</li>
                <li>Ranking-Punkte</li>
              </ul>
            </div>
            <div className={"highperformance-ranking__list-ranks"}>
              <ul>
                {data.map((entry, i) => {
                  let description = (prevEntryDesc !== entry.description) ? entry.description : ""
                  prevEntryDesc = entry.description
                  return (
                    <li key={i}>
                      <div className={"highperformance-ranking__list-entry"}>
                        <span>{description}</span>
                        <span>{entry.title}</span>
                        <span>{formatDate(entry.from_date, false)}  - {formatDate(entry.to_date, false)}</span>
                        <span>{Number(entry.amount)}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default AppHighperformanceWertung;
