import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import Selectfield from "../../atom/selectfield/Selectfield";
import Textfield from "../../atom/textfield/Textfield";
import TextfieldGroup from "../../atom/textfield-group/TextfieldGroup";
import Checkbox from "../../atom/checkbox/Checkbox";
import Image from "../../atom/image/Image";
import Icon from "../../atom/icons/Icon";
import Dropzone from "react-dropzone";
import t, { countriesOptions } from "../../../helpers/t9n";

class SectionProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageModalVisible: false,
    };
  }

  toggleProfileImageModal() {
    this.setState({
      isImageModalVisible: !this.state.isImageModalVisible,
    });
  }

  onAvatarDelete = () => {
    this.props.deleteAvatar();
    this.toggleProfileImageModal();
  };

  onDrop = (files) => {
    if (files && files.length && files.length > 0) {
      this.props.onAvatarChange(files[0]);
    }
  };

  render() {
    const user = this.props.user;
    const avatar_path = user.avatar_path
      ? `${process.env.REACT_APP_API_HOST_LOGIN}` + user.avatar_path
      : `${process.env.PUBLIC_URL}/images/img--user-placeholder.jpg`;

    const countries = countriesOptions()
    const selectedCountry = countries[user.country_code || "DE"]

    return (
      <>
        <div className="profile-header__section">
          {this.props.showAvatarUpload && (
            <div className="profile-header__image-wrapper">
              <span className="profile-header__image">
                <Image image={avatar_path} />
                <span
                  className="profile-header__add-new"
                  onClick={() => {
                    this.toggleProfileImageModal();
                  }}
                >
                  <Icon icon="camera" />
                </span>
              </span>
            </div>
          )}

          <h2>{t("profile.page.heading")}</h2>
          {this.props.subHeadline && <p>{this.props.subHeadline}</p>}

          {this.props.messageSuccess && (
            <div className="profile__messages profile__messages--success">
              {this.props.messageSuccess}
            </div>
          )}

          {this.props.messageError && (
            <div className="profile__messages profile__messages--error">
              {this.props.messageError}
            </div>
          )}

          <div className={"profile-header__content"}>
            <form
              name={"registration-form"}
              onSubmit={(e) => this.props.handleUpdateProfile(e)}
            >
              <div className={"profile__personal-data"}>
                <div
                  className={
                    "login__columns--wrapper login__columns--wrapper--step2"
                  }
                >
                  <input type={"hidden"} name={"address_id"} value={user.id} />

                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Selectfield
                        label={t("app.salutation")}
                        options={{
                          herr: t("app.mr"),
                          frau: t("app.ms"),
                          dr: t("app.dr"),
                        }}
                        name="salutation"
                        defaultValue={
                          user.salutation
                            ? user.salutation.toLowerCase()
                            : t("app.mr")
                        }
                        onSelect={(e) => this.props.handleChange(e)}
                        isRequired={true}
                        placeholder={t("app.salutation")}
                      />
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.firstname")}
                        placeholder={t("app.firstname.placeholder")}
                        value={user.first_name}
                        isRequired={true}
                        name="first_name"
                        handleChange={(e) => this.props.handleChange(e)}
                      />
                    </div>
                    <div className="login__col">
                      <Textfield
                        label={t("app.lastname")}
                        isRequired={true}
                        placeholder={t("app.lastname.placeholder")}
                        value={user.last_name}
                        name="last_name"
                        handleChange={(e) => this.props.handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.street")}
                        placeholder={t("app.street.placeholder")}
                        name="street"
                        value={user.street}
                        isRequired={true}
                        handleChange={(e) => this.props.handleChange(e)}
                      />
                    </div>
                    <div className="login__col">
                      <TextfieldGroup>
                        <Textfield
                          label={t("app.plz")}
                          placeholder={t("app.plz.placeholder")}
                          name="zip"
                          value={user.zip}
                          isRequired={true}
                          handleChange={(e) => this.props.handleChange(e)}
                        />
                      </TextfieldGroup>
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.city")}
                        placeholder={t("app.city.placeholder")}
                        name="city"
                        value={user.city}
                        isRequired={true}
                        handleChange={(e) => this.props.handleChange(e)}
                      />
                    </div>
                    <div className="login__col">
                      <Selectfield
                        label={t("app.country")}
                        name="country_code"
                        options={countries}
                        isRequired={true}
                        defaultValue={selectedCountry}
                        onSelect={(e) => this.props.handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.birthday")}
                        placeholder={t("app.birthday.placeholder")}
                        handleChange={(e) => this.props.handleChange(e)}
                        isRequired={true}
                        value={user.dob}
                        inputType="date"
                        name="dob"
                      />
                    </div>
                    <div className="login__col">
                      <Textfield
                        label={t("app.email")}
                        placeholder={t("app.email.placeholder")}
                        isDisabled={true}
                        value={user.email}
                        name="email"
                        inputType="email"
                      />
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.phone")}
                        placeholder={t("app.phone.placeholder")}
                        name="phone"
                        value={user.phone}
                        handleChange={(e) => this.props.handleChange(e)}
                      />
                    </div>
                    <div className="login__col">
                      <Textfield
                        label={t("app.handy")}
                        placeholder={t("app.handy.placeholder")}
                        name="mobile"
                        value={user.mobile}
                        handleChange={(e) => this.props.handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.customer")}
                        placeholder={t("app.customer.placeholder")}
                        name="company"
                        value={user.company}
                        isDisabled={true}
                        handleChange={(e) => this.props.handleChange(e)}
                      />
                    </div>
                    <div className="login__col">
                      <Textfield
                        label={t("app.customer.nr")}
                        placeholder={t("app.customer.nr.placeholder")}
                        name="company_number"
                        value={user.company_number}
                        isDisabled={true}
                        isRequired={true}
                        handleChange={(e) => this.props.handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {this.props.showSubscriberInfos && (
                <div className="profile__newsletter">
                  <Checkbox
                    label={[t("app.agree.checkbox")]}
                    id="subscribed_infos"
                    name="subscribed_infos"
                    selected={
                      user.subscribed_infos === null
                        ? true
                        : user.subscribed_infos
                    }
                    onChange={() => this.props.handleSubscribeInfos()}
                  />
                </div>
              )}

              <span className="profile-header__actions">
                <Button
                  type="secondary"
                  text={t("app.safe.data")}
                  textColor="white"
                />
              </span>
            </form>
          </div>
        </div>
        {this.state.isImageModalVisible && (
          <>
            <div
              className={`profile-image-modal ${
                user.avatar_path ? "profile-image-modal--selected" : ""
              }`}
            >
              <div className={"profile-image-modal__content"}>
                <span
                  className={"profile-image-modal__close"}
                  onClick={() => {
                    this.toggleProfileImageModal();
                  }}
                >
                  <Icon icon={"close"} />
                </span>

                <h2>{t("app.profile.pic.heading")}</h2>

                <div className={`profile-image-modal__drop`}>
                  <div className={`profile-image-modal__drop--inner`}>
                    {user.avatar_path && <Image image={avatar_path} />}
                    <Dropzone
                      onDrop={this.onDrop}
                      multiple={false}
                      accept="image/png, image/gif, image/jpg, image/jpeg"
                      minSize={10}
                      maxSize={5242880}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <Icon icon={"upload"} />
                          <input {...getInputProps()} />

                          {user.avatar_path ? (
                            <h4>{t("app.profile.pic.change")}</h4>
                          ) : (
                            <h4>{t("app.profile.pic.drop")}</h4>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
                <div className={"profile-image-modal__actions"}>
                  {user.avatar_path && (
                    <Button
                      type={"danger"}
                      textColor={"black"}
                      text={t("app.profile.pic.delete")}
                      onClick={() => this.onAvatarDelete()}
                    />
                  )}
                  <Button
                    type={"primary"}
                    textColor={"white"}
                    text={t("app.profile.pic.safe")}
                    onClick={() => {
                      this.toggleProfileImageModal();
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={`main-menu__overlay ${
                this.state.isImageModalVisible
                  ? "main-menu__overlay--visible"
                  : ""
              }`}
            ></div>
          </>
        )}
      </>
    );
  }
}

SectionProfileHeader.contextType = AppContext;
export default SectionProfileHeader;
