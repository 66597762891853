import React, { Component } from "react";
import { formatDate } from "../../../lib/lib";

class AppPraemienClubWertung extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      isInfoVisible: false
    }
  }

  toggleInfo = () => {
    this.setState({
      isInfoVisible: !this.state.isInfoVisible
    })
  }

  render() {
    const { data, isInfoVisible } = this.state
    let prevEntryDesc = ""

    return (
      <>
      <div className={`highperformance-placement__section highperformance-wertung__section`}>
        <div className={"highperformance-placement__header"}>
          <h2>Wertungskriterien – So punkten Sie</h2>
        </div>
        <p
          className={`highperformance-placement__info ${isInfoVisible ? "highperformance-placement__info--visible" : ""}`}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec.
        </p>
      </div>
      <div className={`highperformance-ranking__section highperformance-wertung__ranking`}>
        <div className={"highperformance-ranking__content"}>
          <div className={"highperformance-ranking__list"}>
            <div className={"highperformance-ranking__list-header"}>
              <ul>
                <li>Wertungskriterien</li>
                <li>Beschreibung</li>
                <li>Zeitraum</li>
                <li>Ranking-Punkte</li>
              </ul>
            </div>
            <div className={"highperformance-ranking__list-ranks"}>
              <ul>
                {data.map((entry, i) => {
                  let description = (prevEntryDesc !== entry.description) ? entry.description : ""
                  prevEntryDesc = entry.description
                  return (
                    <li key={i}>
                      <div className={"highperformance-ranking__list-entry"}>
                        <span>{description}</span>
                        <span>{entry.title}</span>
                        <span>{formatDate(entry.from_date, false)} - {formatDate(entry.to_date, false)}</span>
                        <span>{Number(entry.amount)}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default AppPraemienClubWertung;
