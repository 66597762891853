import React, { useContext, useEffect, useState } from "react";
import { getContentPage } from "../../data/api";
import { AppContext } from "../../data/ContextProvider";

const Tos = () => {

  const [data, setData] = useState({});
  const [pId] = useState(21);
  const [loading, setLoading] = useState(true);
  const context = useContext(AppContext)

  useEffect(() => {
    getContentPage(pId, context.getProjectData().id, context.getProjectData().app_id).then(page => {
      console.log(page)
      setData(page.text)
      setLoading(false)
    })
  }, ([pId, context]))

  if (loading)
    return "Loading.."

  return (
    <>
      <div className={"content privacy agb"}>
        <h1>{data["p" + pId + "_header_content_headline"]}</h1>

        <p
          dangerouslySetInnerHTML={{
            __html: data["p" + pId + "_upper_content_text"] ? data["p" + pId + "_upper_content_text"].replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
          }}>
        </p>

      </div>
    </>
  );
}

export default Tos;
