import React from "react";
import { useParams } from "react-router-dom";
import Casino from "./Casino/Casino";

const CasinoPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <Casino lang={lang} />
    </>
  )
}

export default CasinoPage;
