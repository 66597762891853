import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import ButtonWishlist from "../../atom/button-wishlist/ButtonWishlist";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";

const Product = (props) => {
  const context = useContext(AppContext);

  return (
    <Link
      to={{
        pathname: `/${window.currentLang}/product/${props.id}`,
        id: props.id,
      }}
      className="product-link"
    >
      {context.getAppIdOfUser() === "17" ? (
        <div
          className={`product product-gloria product--${props.type} product--${props.bgColor}`}
        >
        <div className={"product__upper"}>
          <div className={"product__image"}>
            {props.isNew && <span className="product__label-new">{t("global.badge.neuheit")}</span>}
            {props.isHighlight && <span className="product__label-highlight">{t("global.badge.highlight")}</span>}
            {props.isLuxury && <span className="product__label-luxus">{t("global.badge.luxus")}</span>}

            <span className={"product__actions"}>
              {props.hasWishAction && (
                <ButtonWishlist
                  isWished={props.isWished}
                  productId={props.id}
                  variantId={props.variantId}
                  isOnWishlistPage={props.isOnWishlistPage}
                />
              )}
            </span>

            {props.isHighlight && (
              <div
                className="image image--fullwidth"
                style={{ backgroundImage: `url(${props.image})` }}
              ></div>
            )}

            {!props.isHighlight && (
              <div className="image image--fullwidth">
                <img src={props.image} alt={props.title} />
              </div>
            )}
          </div>

          <span className={"product__price"}>
            {props.price.toLocaleString()}&nbsp;{t("app.points")}
          </span>
          
          <div className={"product__content"}>
            <span className={"product__title"}>{props.title}</span>
            {props.button && <span className="read-more">{props.button}</span>}

            {props.type === "shop" && (
              <span className={"product__price--shop"}>
                {props.hasMultipleVariants && <span>ab&nbsp;</span>}
                {props.price.toLocaleString()}&nbsp;{t("app.points")}
              </span>
            )}
          </div>
        </div>
        
        </div>
      ) : (        
        <div
          className={`product product--${props.type} product--${props.bgColor}`}
        >
          <div className={"product__upper"}>
            <div className={"product__image"}>
              {props.isNew && <span className={"product__label"}>{t("global.key.new")}</span>}

              <span className={"product__actions"}>
                {props.hasWishAction && (
                  <ButtonWishlist
                    isWished={props.isWished}
                    productId={props.id}
                    variantId={props.variantId}
                    isOnWishlistPage={props.isOnWishlistPage}
                  />
                )}
              </span>

              {props.isHighlight && (
                <div
                  className="image image--fullwidth"
                  style={{ backgroundImage: `url(${props.image})` }}
                ></div>
              )}

              {!props.isHighlight && (
                <div className="image image--fullwidth">
                  <img src={props.image} alt={props.title} />
                </div>
              )}
            </div>
            <div className={"product__content"}>
              <span className={"product__title"}>{props.title}</span>
              {props.button && <span className="read-more">{props.button}</span>}

              {props.type === "shop" && (
                <span className={"product__price--shop"}>
                  {props.hasMultipleVariants && <span>ab&nbsp;</span>}
                  {props.price.toLocaleString()}&nbsp;{t("app.points")}
                </span>
              )}
            </div>
          </div>
          <span className={"product__price"}>
            {props.price.toLocaleString()}&nbsp;{t("app.points")}
          </span>
        </div>
      )}

    </Link>
  );
};

Product.propTypes = {
  type: PropTypes.oneOf(["standard", "short", "shop"]),
  bgColor: PropTypes.oneOf(["white", "darkblue"]),
  title: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.number,
  hasWishAction: PropTypes.bool,
  isWished: PropTypes.bool,
  isNew: PropTypes.bool,
  id: PropTypes.number,
  variantId: PropTypes.number,
};
Product.defaultProps = {
  type: "standard",
  title: "Produktbezeichnung Zweizeilig",
  image: process.env.PUBLIC_URL + "/images/product.jpg",
  price: 0,
  bgColor: "white",
  isNew: false,
  isWished: false,
  isOnWishlistPage: false,
};

export default withRouter(Product);
