import React, { Component } from "react";
import { API_HOST } from "../../../data/config";
import { AppContext } from "../../../data/ContextProvider";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasHeaderPic: true,
    };
  }

  getHeaderPic = () => {
    const headerPic = this.state.hasHeaderPic;
    const project_id = this.context.getUserData().project_id
      ? this.context.getUserData().project_id
      : this.context.getProjectData().id;

    const onError = () => {
      this.setState({
        hasHeaderPic: false,
      });
    };

    return headerPic ? (
      <img
        src={`${API_HOST}/asset/ct_header?pid=${project_id}`}
        alt="header-image"
        onError={onError}
      />
    ) : (
      <div className="compl-blank-image"></div>
    );
  };

  render() {
    return (
      <>
        <div className="header header--compl">
          <h1>{this.props.headline}</h1>
          <div className="image image--default">{this.getHeaderPic()}</div>
        </div>
      </>
    );
  }
}

Header.contextType = AppContext;
export default Header;
