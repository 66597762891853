import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Logo from "../../components/atom/logo/Logo";
import { AppContext } from "../../data/ContextProvider";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import NewPassword from "./Steps/NewPassword";
import PasswordReset from "./Steps/PasswordReset";
import ResetSuccess from "./Steps/ResetSuccess";
import { formatDateToIsoDate, tryToLogInUser, checkPasswordPolicy } from "../../lib/lib";
import { getContentPage, resetPassword, updatePassword, updateUser } from "../../data/api";
import Button from "../../components/atom/button/Button";
import Step3 from "./Steps/Step3";
import Step4 from "./Steps/Step4";
import t from "../../helpers/t9n";
import { API_HOST } from "../../data/config";
import { CONTENT_LOGIN } from "../../data/constants";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: this.props.step ? this.props.step : 1,
      error: "",
      errorVideo: false,
      hasLoginpic: true,
      email: '',
      loading: true,
      password: '',
      resetToken: this.props.resetToken,
      pageText: {}
    };

    this.timer = null;
    this.currentSeconds = 1;
  }

  createTimer() {
    return setInterval(() => this.countdown(), 1000);
  }

  countdown() {
    if (this.currentSeconds >= 10) {
      this.setState({
        errorVideo: false
      })
      this.currentSeconds = 1;
      clearInterval(this.timer);
    } else {
      this.currentSeconds++
    }
  }

  async componentDidMount() {
    const page = await getContentPage(CONTENT_LOGIN, this.context.getProjectData().id, this.context.getProjectData().app_id)

    this.setState({
      pageText: page ? page.text : {},
      loading: false
    })

    if (this.props.resetPassword) {
      this.setState({
        step: 4
      })
    }
  }

  componentWillUnmount() {
    this.timer && clearTimeout(this.timer)
  }

  //TODO: Remove this and do it some other way..
  saveAddressId = (id) => {
    this.setState({
      addressId: id
    })
  }

  handlePasswordReset() {
    this.setState({
      step: 3
    })
  }

  handleSubmit = async (e, step, addressId) => {
    e.preventDefault();

    let data = null

    try {
      switch (step) {
        case 0:
          //Back to Login
          this.setState({
            step: 1
          });
          break;
        case 1:

          let userData, loginError;
          try {
            userData = await tryToLogInUser(this.state.email, this.state.password, this.context.getProjectID())
          } catch (error) {
            loginError = error.data
          }

          if (userData) {
            if (this.state.error) {
              this.setState({ error: "" })
            }

            this.context.saveUserData(userData)
            if (userData.accept_gdpr) {
              //User already has a Billing address, so the user already passed the signup
              this.props.history.push('/')
              window.location.reload();
            } else {
              this.setState({
                userId: userData.id,
                userData: userData,
                step: 6
              })
            }
          } else if (loginError) {
            this.setState({
              error: loginError,
              errorVideo: true
            })
            this.timer = this.createTimer()
          }
          break;
        case 2:
          //Save user data
          if (!this.state.password ||
            !this.state.password_repeat ||
            this.state.password.length === 0 ||
            this.state.password_repeat.length === 0
          ) {
            this.setState({ error: t("login.error.password_is_empty") })
            return
          }

          if (this.state.password !== this.state.password_repeat) {
            this.setState({ error: t("login.error.password_mismatch") })
            return
          }

          if (!checkPasswordPolicy(this.state.password)) {
            this.setState({ error: t("login.error.password_policy") })
            return
          }

          const userToUpdate = this.state.userData
          userToUpdate.accept_gdpr = this.state.accept_gdpr === "accept_gdpr" ? 1 : 0
          userToUpdate.accept_business_terms = this.state.accept_business_terms === "accept_business_terms" ? 1 : 0
          userToUpdate.accept_date = formatDateToIsoDate(new Date())

          try {
            await updateUser(userToUpdate)
            await updatePassword({
              password: this.state.password,
              password_repeat: this.state.password_repeat,
            })
            this.props.history.push('/')
            window.location.reload()
          } catch (error) {
            console.log(error)
          }

          break;
        case 4:
          const password_repeat = this.state.reset_new_password_repeat
          data = {
            new_password: this.state.reset_new_password,
            reset_email: this.state.reset_new_password_email,
            code: this.state.resetToken
          }

          if (!data.new_password || !password_repeat || data.new_password === 0 || password_repeat === 0 ) {
            this.setState({ error: t("login.error.password_is_empty") })
            return
          }

          if (data.new_password !== password_repeat) {
            this.setState({ error: t("login.error.password_mismatch") })
            return
          }

          if (!checkPasswordPolicy(data.new_password)) {
            this.setState({ error: t("login.error.password_policy") })
            return
          }

          try {
            await resetPassword(data)
            this.props.history.push(`/${window.currentLang}/login?s=1`)
            window.location.reload()
          } catch (error) {
            //const errorMsg = (error && error.data && error.data.error) ? error.data.error : "Ihr Passwort konnte nicht gespeichert werden"
            this.props.history.push(`/${window.currentLang}/login?s=1`)
            window.location.reload()
            //this.setState({ error: errorMsg })
          }

          break;
        case 5:
          data = {
            email: this.state.reset_email,
          }

          if (!data.email || data.email === 0) {
            this.setState({ error: t("login.error.mail") })
            return
          }

          try {
            await resetPassword(data)
            this.setState({ step: 5 })
          } catch (error) {
            console.log(error)
          }
          break;
        case 6:
          this.setState({
            step: 7
          });
          break;
        case 7:
          this.setState({
            step: 2
          });

          break;
        default:
          return
      }
    } catch (error) {

      console.log("fatal", error)
      if (error && error.status === 401) {
        this.setState({
          error: error.data
        })
      } else if (error && error.data.error) {
        this.setState({
          error: error.data.error
        })
      } else if (error) {
        this.setState({
          error: "No Backend connection"
        })
      }
    }
  }

  handleChange = (e) => {
    //TODO: disable primary Button in Step2 if Checkbox is unchecked
    // console.log(e.target.name, e.target.value)
    if (e.target) {
      const name = e.target.name
      const value = e.target.value
      const state = this.state
      state[name] = value
      this.setState({ ...state });
    }
  }

  handleUserChange = (e) => {
    if (e.target) {
      const userData = this.state.userData
      userData[e.target.name] = e.target.value
      this.setState({ userData });
    }
  }

  handlePasswordChange = (e) => {
    if (e.target) {
      const state = this.state
      state[e.target.name] = e.target.value
      this.setState({ ...state })
    }
  }

  getCurrentStep() {
    switch (this.state.step) {
      case 1:
        return <Step1 submitFunc={(e, s) => this.handleSubmit(e, s)}
                      handlePasswordReset={() => this.handlePasswordReset()}
                      handleChange={(e) => this.handleChange(e)}
                      error={this.state.error} />;
      case 2:
        return <Step2
                  submitFunc={(e, s) => this.handleSubmit(e, s)}
                  handleChange={(e) => this.handleUserChange(e)}
                  handlePasswordChange={(e) => this.handlePasswordChange(e)}
                  error={this.state.error}
                  saveAddressId={this.saveAddressId}
                  data={this.state.userData}
                />;
      case 3:
        return <PasswordReset submitFunc={(e, s) => this.handleSubmit(e, s)}
                              handleChange={(e) => this.handleChange(e)}
                              error={this.state.error} />;
      case 4:
        return <NewPassword submitFunc={(e, s) => this.handleSubmit(e, s)} error={this.state.error}
                            handleChange={(e) => this.handleChange(e)}
                            resetToken={this.props.resetToken} />;
      case 5:
        return <ResetSuccess submitFunc={(e, s) => this.handleSubmit(e, s)} error={this.state.error} />;
      case 6:
        return <Step3 submitFunc={(e, s) => this.handleSubmit(e, s)} handleChange={(e) => this.handleChange(e)}
                      error={this.state.error} />;
      case 7:
        return <Step4 submitFunc={(e, s) => this.handleSubmit(e, s)} handleChange={(e) => this.handleChange(e)}
                      error={this.state.error} />;

      default:
        return <Step1 submitFunc={(e, s) => this.handleSubmit(e, s)}
                      handlePasswordReset={() => this.handlePasswordReset()}
                      handleChange={(e) => this.handleChange(e)}
                      error={this.state.error} />;
    }
  }

  getVideo() {
    return (
      <video loop autoPlay key={`${this.state.errorVideo ? "error" : "default"}`}>
        <source
          src={`${this.state.errorVideo ? `${process.env.PUBLIC_URL}/videos/Login/bdm-login-city-error.mp4` : `${process.env.PUBLIC_URL}/videos/Login/bdm-login-city-default.mp4`}`}
          type="video/mp4" />Your browser does not support the video tag. Please upgrade your browser.
      </video>
    )
  }

  getLoginPic = () => {
    const hasLoginpic = this.state.hasLoginpic;
    const onError = () => {
      this.setState({
        hasLoginpic: false
      });
    };
    return hasLoginpic ? <img alt="login-pic" src={`${API_HOST}/asset/loginpic/for-domain?domain=${document.domain}`}  onError={onError} /> : this.getVideo();
  }

  render() {
    if (this.state.loading) {
      return "Loading.."
    }

    const text = this.state.pageText

    return (
      <div className={`splitview step--${this.state.step}`}>
        <div className={"splitview--main"}>
          <div className={"login__container--left"}>
            <div className={"login__background-video"}>
              {this.getLoginPic()}
            </div>
            <span className={"login__logo"}>
              <Logo image={`${API_HOST}/asset/logo/for-domain?domain=${window.location.host}`} />
            </span>
            <div className={"login__text-container"}>
              <h1>{text.p41_header_content_headline}</h1>
              <h2>{text.p41_header_content_subline }</h2>
            </div>
            <span className="login__action login__action--mobile">
              <Button type={"primary"} width={"fullwidth"} textColor={"white"} text={"Login"} />
            </span>
          </div>
        </div>
        <div className={"splitview--sidebar"}>
          {this.getCurrentStep()}
        </div>
      </div>
    );
  }
}

Login.contextType = AppContext;
export default withRouter(Login);
