import React, { Component } from "react";
import ProgressCircle from "../../components/element/progress-circle/ProgressCircle";
import { dateDiff, dateExpired} from "../../helpers/utils";
import t from "../../helpers/t9n";


class SectionCountdown extends Component {

	render() {
		const dateEndDiff        =  dateDiff(this.props.end)
		const dateStartToEndDiff =  dateDiff(this.props.end, new Date(this.props.start))
		const expiredDate        =  dateExpired(this.props.end)

		return (
			<div className={"recommendations__section"}>
				<div className={"recommendations"}>
					<h2>{t("app.remaining.time")}</h2>
					<div className={"teamTrophy-performance__box teamTrophy-performance__box--timer"}>
						<span>
							<ProgressCircle
								value={dateEndDiff.days}
								maxValue={dateStartToEndDiff.days}
								isCountDown={true}
								text={t("app.days")}
								expired={expiredDate}
							/>

							<ProgressCircle
								value={dateEndDiff.hours}
								maxValue={24}
								text={t("app.hours")}
								expired={expiredDate}
							/>
							<ProgressCircle
								value={dateEndDiff.minutes}
								maxValue={60}
								text={t("app.mins")}
								expired={expiredDate}
							/>
						</span>
					</div>
				</div>
			</div>
		);
	}
}
export default SectionCountdown;
