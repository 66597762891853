import React, { Component } from "react";
import AppTeamTrophyHeader from "../../components/module/app-team-trophy-header/AppTeamTrophyHeader";
import AppTeamTrophyHome from "../../components/module/app-teamtrophy/AppTeamTrophyHome";
import AppTeamTrophyProfit from "../../components/module/app-teamtrophy/AppTeamTrophyProfit";

import { AppContext } from "../../data/ContextProvider";

import { CONTENT_SPIELREGELN } from "../../data/constants";
import { getContentPage, getAppPrefs } from "../../data/api";
import t from "../../helpers/t9n";

class TeamTrophy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      loading: true,
    }
  }

  handleHeader = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }


  async componentDidMount() {

    const appPrefs = await getAppPrefs()
    const page = await getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)

    this.setState({
      appPrefs: appPrefs.preferences,
      pageText: page.text,
      loading: false
    })
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    return (
      <>
        <div className={"content app teamTrophy"}>

					<AppTeamTrophyHeader
            handleHeader={this.handleHeader}
            isHeaderOpen={this.state.isOpen}
            pageText={this.state.pageText}
            appPrefs={this.state.appPrefs}
          />

          {this.props.view === "home" &&
          <AppTeamTrophyHome
            handleHeader={this.handleHeader}
            isHeaderOpen={this.state.isHeaderOpen}
            start={this.state.appPrefs.start}
            end={this.state.appPrefs.end}
            bonus={this.state.appPrefs.bonus}
          />
          }

          {this.props.view === "gewinn" &&
          <AppTeamTrophyProfit />
          }
        </div>
      </>
    );
  }
}

TeamTrophy.contextType = AppContext
export default TeamTrophy;
