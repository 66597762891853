import React, { Component } from "react";

export default class ETracker extends Component {

  async componentDidMount() {
    const script = document.createElement("script");
    script.id = "_etLoader"
    script.type = 'text/javascript'
    script.src = "//code.etracker.com/code/e.js"
    script.setAttribute("data-secure-code", "iVbduE")
    script.setAttribute("data-respect-dnt", true)
    script.setAttribute("data-block-cookies", true)
    script.async = true
    document.body.appendChild(script)
  }

  render() {
    return(
      <></>
    )
  }
}
