import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import ballons from "../../../assets/lotties/promobooster/anim--ballons";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import Textfield from "../../atom/textfield/Textfield";
import { withRouter } from "react-router-dom";
import ScrollToTop from "../../basics/ScrollToTop/ScrollToTop";
import Checkbox from "../../atom/checkbox/Checkbox";
import Selectfield from "../../atom/selectfield/Selectfield";
import TextfieldGroup from "../../atom/textfield-group/TextfieldGroup";
import { getToken, tryToLogInUser } from "../../../lib/lib";
import { createAddress, signUpUserWithCode, getContentPage } from "../../../data/api";
import { CONTENT_TEILNAHMEBEDINGUNGEN, CONTENT_DATENSCHUTZ } from "../../../data/constants";
import t from "../../../helpers/t9n";

class AppPromoBoosterSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      step: 1,
      isTosAccepted: false,
      isPrivacyAccepted: false,
      codeToRedeem: this.props.promoCode,
      text: {},
      loading: true,
    }
  }

  async componentDidMount() {
    const page = await getContentPage(CONTENT_TEILNAHMEBEDINGUNGEN, this.context.getProjectData().id, this.context.getProjectData().app_id)
    this.setState({
      text: page.text,
      loading: false,
    })
  }

  handleChange = (e) => {
    if (e.target) {
      const name = e.target.name
      const value = e.target.value
      const state = this.state
      state[name] = value
      this.setState({ ...state });
    }
  }

  handleSignUp = async (e) => {
    e.preventDefault()

    const data = {
      "salutation": this.state.salutation,
      "first_name": this.state.first_name,
      "last_name": this.state.last_name,
      "company": this.state.company,
      "street": this.state.street,
      "zip": this.state.zip,
      "city": this.state.city,
      "email": this.state.email,
      "phone": this.state.phone,
      "dob": this.state.dob,
      "delivery": false
    };


    if (this.state.codeToRedeem) {
      //If codeToRedeem != null
      //User used a code, no user account exists
      if (this.state.password !== this.state.password_repeat) {
        this.setState({
          error: t("app.password.not.match")
        })
        return
      } else {
        const signUpData = {
          "user": {
            "salutation": this.state.salutation,
            "first_name": this.state.first_name,
            "last_name": this.state.last_name,
            "password": this.state.password,
            "email": this.state.username,
            "dob": this.state.dob,
            "accept_business_terms": this.state.isTosAccepted,
            "accept_gdpr": this.state.isPrivacyAccepted,
            "accept_date": new Date().toISOString(),
          }
        };

        let userData, loginError;

        if (this.state.error) {
          this.setState({ error: "" })
        }
        //Create user account, then redeem code
        try {
          await signUpUserWithCode(signUpData, this.state.codeToRedeem)
        } catch (e) {
          loginError = e.data
          this.setState({ error: loginError })
          return
        }
        //User is signedUp

        try {
          userData = await tryToLogInUser(this.state.username, this.state.password)
        } catch (error) {
          loginError = error.data
          this.setState({ error: loginError })
          return
        }
        //User is loggedIn
        if (userData) {
          this.context.saveUserData(userData)
        }
      }
    }
    //Either user had an account, or has one by now.
    //Save user address
    const response = createAddress(getToken(), data)

    await response.then(res => {
      if (res.address) {
        // REDIRECT TO PAGE
        this.props.handleAccess()
      }
    });
  }

  handleTosChange() {
    this.setState({
      isTosAccepted: !this.state.isTosAccepted
    })
  }

  handlePrivacyChange() {
    this.setState({
      isPrivacyAccepted: !this.state.isPrivacyAccepted
    })
  }

  getTosForm() {
    return (
      <>
      <ScrollToTop />
      <div className={"login__columns"}>
        <form name={"tos-form"}
              onSubmit={(e) => {
                e.preventDefault();
                this.setState({ step: 2 })
              }}
        >
          <div className={"login__columns-header"}>
            <h3>{t("menu.conditions.participation")}</h3>
            <span className={"login__step-indicator"}>{t("app.sign.up.step.one")}</span>
          </div>
          <div className={"login__columns--wrapper login__columns--wrapper--step3"}>
            <div className={"login__row"}>
              <div className="login__info-container">
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.text["p" + CONTENT_TEILNAHMEBEDINGUNGEN + "_upper_content_text"] ? this.state.text["p" + CONTENT_TEILNAHMEBEDINGUNGEN + "_upper_content_text"].replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                  }}>
                </p>
              </div>
            </div>
            <div className={"login__row"}>
              <div className={"login__col"}>
                <Checkbox
                  name={"tos-accept"}
                  onChange={() => this.handleTosChange()}
                  selected={false}
                  key={"tos-accept"}
                  label={
                    [t("app.sign.up.declare"), <a key={'agb_anchor'}
                                                     href={`/${window.currentLang}/tos`}>{t("menu.conditions.participation")}</a>, t("app.sign.up.agree")]}
                  id={"tos-accept"} />
              </div>
              <div className={"login__col login__col--action"}>
                <Button type={"primary"} textColor={"white"}
                        state={`${this.state.isTosAccepted ? "active" : "inactive"}`} text={t("app.continue")} />
              </div>
            </div>
          </div>
        </form>
      </div>
     </>
    )
  }

  getPrivacyForm() {
    return (
      <>
        <ScrollToTop />
        <div className={"login__columns"}>
          <form name={"privacy-form"} onSubmit={(e) => {
            e.preventDefault();
            this.setState({ step: 3 })
          }}>
            <div className={"login__columns-header"}>
              <h3>{t("app.privacy.policy")}</h3>
              <span className={"login__step-indicator"}>{t("app.sign.up.step.two")}</span>
            </div>
            <div className={"login__columns--wrapper login__columns--wrapper--step3"}>
              <div className={"login__row"}>
                <div className={"login__info-container"}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.text["p" + CONTENT_DATENSCHUTZ + "_upper_content_text"] ? this.state.text["p" + CONTENT_DATENSCHUTZ + "_upper_content_text"].replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                  }}>
                </p>
                </div>
              </div>
              <div className={"login__row"}>
                <div className={"login__col"}>
                  <Checkbox
                    name={"privacy-accept"}
                    onChange={() => this.handlePrivacyChange()}
                    selected={false}
                    key={"privacy-accept"}
                    label={
                      ["Ich erkläre mich mit den ", <a key={'agb_anchor'}
                                                       href={`/${window.currentLang}/privacy`}>{t("app.privacy.policy")}</a>, " von Buben & Mädchen GmbH einverstanden. "]}
                    id={"privacy-accept"} />
                </div>
                <div className={"login__col login__col--action"}>
                  <Button type={"primary"} textColor={"white"}
                          state={`${this.state.isPrivacyAccepted ? "active" : "inactive"}`} text={t("app.continue")} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </>
    )
  }

  getSignUpForm() {
    return (
      <div className={`splitview`}>
        <div className={"splitview--main"}>
          <div className={"login__container--right"}>
            <div className={"login__columns"}>
              <form name={"registration-form"} onSubmit={(e) => {
                e.preventDefault()
              }}>
                <h3>{t("app.personal.data")}</h3>
                <div className={"login__columns--wrapper login__columns--wrapper--step2"}>
                  <input type={"hidden"} name={"address_id"} />

                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield label={t("app.company")} placeholder={t("app.company.placeholder")} name={"company"}
                                 handleChange={(e) => this.handleChange(e)} />
                    </div>
                    <div className={"login__col"}>
                      <Selectfield label={t("app.salutation")} options={{
                        'herr': t("app.mr"),
                        'frau': t("app.ms"),
                        'dr': t("app.dr"),
                      }} name={"salutation"}
                                   placeholder={t("app.salutation")}
                                   onSelect={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield label={t("app.firstname")} placeholder={t("app.firstname.placeholder")}
                                 name={"first_name"} handleChange={(e) => this.handleChange(e)} />
                    </div>
                    <div className={"login__col"}>
                      <Textfield label={t("app.lastname")}
                                 placeholder={t("app.lastname.placeholder")}
                                 name={"last_name"}
                                 handleChange={(e) => this.handleChange(e)} />
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield label={t("app.street")} placeholder={t("app.street.placeholder")} name={"street"}
                                 isRequired={true}
                                 handleChange={(e) => this.handleChange(e)} />
                    </div>
                    <div className={"login__col"}>
                      <TextfieldGroup>
                        <Textfield label={t("app.plz")} placeholder={t("app.plz.placeholder")} name={"zip"}
                                   handleChange={(e) => this.handleChange(e)} />
                        <Textfield label={t("app.city")} placeholder={t("app.city.placeholder")} name={"city"}
                                   handleChange={(e) => this.handleChange(e)} />
                      </TextfieldGroup>
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield label={t("app.birthday")}
                                 placeholder={t("app.birthday.placeholder")}
                                 handleChange={(e) => this.handleChange(e)}
                                 name={"dob"} />
                    </div>
                    <div className={"login__col"}>
                      <Textfield label={t("app.email")} placeholder={t("app.email.placeholder")}
                                 handleChange={(e) => this.handleChange(e)}
                                 name={"email"} />
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col"}>
                      <Textfield label={t("app.phone")}
                                 placeholder={t("app.phone.placeholder")}
                                 handleChange={(e) => this.handleChange(e)}
                                 name={"phone"} />
                    </div>
                  </div>
                  <div className={"login__row"}>
                    <div className={"login__col login__col--action login__col--action--single"}>
                      <Button type={"primary"} textColor={"white"} text={t("app.safe.data")}
                              onClick={(e) => this.handleSignUp(e)} />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className={"splitview--sidebar"}>
          <div className={"login__container--left"}>
            <h3>{t("app.sign.up.complete")}</h3>
            <p>{t("app.sign.up.check.data")}</p>
            <div className={"login__columns"}>
              <div className={"login__row"}>
                <Textfield label={t("login.input.username")}
                           handleChange={(e) => this.handleChange(e)}
                           inputType={"text"}
                           name={"username"} />
              </div>
              <div className={"login__row"}>
                <Textfield label={t("app.new.password")}
                           isRequired={true}
                           handleChange={(e) => this.handleChange(e)}
                           inputType={"password"}
                           errorMsg={this.state.error}
                           name={"password"} />
              </div>
              <div className={"login__row"}>
                <Textfield isRequired={true}
                           label={t("app.new.password.repeat")}
                           handleChange={(e) => this.handleChange(e)}
                           inputType={"password"}
                           errorMsg={this.state.error}
                           name={"password_repeat"} />
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }

  getCurrentStep() {
    const step = this.state.step

    switch (step) {
      case 1:
        return this.getTosForm()
      case 2:
        return this.getPrivacyForm()
      case 3:
        return this.getSignUpForm()
      default:
        break;
    }
  }

  render() {
    if(this.state.loading)
      return t("global.loading")

    return(
      <>
      <div className={"app-header__content"}>
        <div className={"promobooster-header__upper promobooster-header__upper--signup"}>
          {this.getCurrentStep()}
        </div>
      </div>
      <span className={"app-header__box"}>
        <UncontrolledLottie animationData={ballons} />
      </span>
      </>
    );
  }
}

AppPromoBoosterSignup.propTypes = {};
AppPromoBoosterSignup.defaultProps = {};

AppPromoBoosterSignup.contextType = AppContext

export default withRouter(AppPromoBoosterSignup);
