import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import TinySlider from "../../../vendor/tiny-slider-react";
import { AppContext } from "../../../data/ContextProvider";
import { API_HOST } from "../../../data/config";
import { withRouter } from "react-router";
// import { Link } from "react-router-dom";

class AppSliderHeader extends Component {
  state = {
    settings: {
      lazyload: true,
      nav: true,
      mouseDrag: true,
      controls: false
    }
  }

  onGoTo = dir => {
    this.ts.slider.goTo(dir);
  };

  render() {
    return (
      <>
      <div
        className={`app-header__section ${
          this.props.isHeaderOpen ? "app-header__section--open" : ""
        }`}
      >
        <div className="app-header__slider">
          <span
            onClick={() => this.onGoTo("next")}
            className={`app-header__arrow app-header__arrow--right`}
          >
            <Icon icon="arrow" />
          </span>

          <span
            onClick={() => this.onGoTo("prev")}
            className={`app-header__arrow app-header__arrow--left`}
          >
            <Icon icon="arrow" />
          </span>

          <TinySlider
            settings={this.state.settings}
            ref={(ts) => (this.ts = ts)}
          >
            {this.props.headerSlider.map((img, index) => (
              <div
                className={`app-header__item app-header__item--${img.variant}`}
                key={index}
              >
                <div
                  className="app-header__bg"
                  style={{
                    backgroundImage: `url("${API_HOST}/slider/img/${img.id}")`,
                  }}
                ></div>

                <div className="app-header__content">
                  <div className="app-header__left">
                    <span className="app-header__heading">
                      {/* {this.context.getAppIdOfUser() === "17" && 
                        <div className="app-header__icon-logo">
                          <div className="app-header__icon-logo--inner">
                            <Icon icon="app-gloria-rewards" />
                          </div>
                        </div>
                      } */}
                      {img?.title && (
                        <h1>{img.title}</h1>
                      )}
                      {img?.subtitle && <h2>{img.subtitle}</h2>}
                    </span>
                  </div>

                  {/* {this.context.getAppIdOfUser() === "17" && 
                    <div className="app-header__button-bottom">
                      <Link to={`/${window.currentLang}/shop`}>Entdecken</Link>
                    </div>
                  } */}
                </div>
              </div>
            ))}
          </TinySlider>
        </div>
      </div>
      </>
    );
  }
}

AppSliderHeader.contextType = AppContext
export default withRouter(AppSliderHeader);