import React, { Component } from "react";
import { AppContext } from "../../../data/ContextProvider";
import Button from "../../atom/button/Button";

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={this.props.menu ? "navbar__language-switch--menu" : "navbar__language-switch"}>
        <Button
          onClick={() => this.context.setLanguage("de")}
          type={"noOutline"}
          additionalClass=""
          textColor={"white"}
        >
          DE
        </Button>
        /
        <Button
          onClick={() => this.context.setLanguage("fr")}
          type={"noOutline"}
          additionalClass=""
          textColor={"white"}
        >
          FR
        </Button>
      </div>
    );
  }
}

LanguageSwitcher.contextType = AppContext;
export default LanguageSwitcher;
