import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import flag from "../../../assets/lotties/private-home/anim-private-flag";
import { AppContext } from "../../../data/ContextProvider";
import { getAssetSlider, getContentPage } from "../../../data/api";
import { CONTENT_SPIELREGELN } from "../../../data/constants";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";

class SectionPrivateHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isOpen: true,
      headerSlider: []
    }
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
    })
  }

  componentDidMount() {

    getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id).then(res => {
      let text = ""
      if (res.text) {
        text = res.text
      }

      this.setState({
        text: text,
        loading: false,
      })
    })

    this.loadData()
  }

  render() {
    if (this.state.loading) {
      return "loading.."
    }

    return (
      <>
        {this.state.headerSlider.length > 0 &&
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        }

        <div
          className={`app-header__section private-header__section  ${this.state.isOpen ? "private-header__section--open" : ""}`}>
          <video loop autoPlay>
            <source
              src={`${process.env.PUBLIC_URL}/videos/Jackpot/anim--jackpot-bg.mp4`}
              type="video/mp4" />Your browser does not support the video tag. Please upgrade your browser.
          </video>
          <div className={"private-header__content"}>
            <div className={"app-header__left"}>
              <span className={"app-header__heading"}>
                <div>
                  <span>
                    <Icon icon={"app-basisshop"} />
                  </span>
                  <h1>{this.state.text.p33_header_content_headline}</h1>
                </div>
                <h2>{this.state.text.p33_header_content_subline}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: this.state.text.p33_upper_content_text ? this.state.text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                  }}>
                </p>
              </span>
              <span className={"app-header__actions"}>
                <Button type={"link"} text={`${this.state.isOpen ? "Infos schließen" : "Weitere Infos"}`}
                        textColor={"white"} icon={"arrow"} onClick={() => {
                  this.setState({ isOpen: !this.state.isOpen })
                }} />
                <Button type={"secondary"} text={"Die Spielregeln"} textColor={"black"} backgroundColor={"bg-white"}
                        onClick={() => {
                          this.props.history.push(`/${window.currentLang}/rules`)
                        }} />
              </span>
            </div>

            <div className={"app-header__right"}>
              <div className={"jackpot-counter"}>
                <span className={"jackpot-counter__icon"}>
                  <Icon icon={"coins"} />
                </span>
                <h2>MEINE PUNKTE</h2>
                <span className={"jackpot-counter__wrapper"}>
                  <CounterSpawner isSmall={true} amount={Number(this.context.getPointsOfUser())} type={"highlight"} />
                  <h5>PKT</h5>
                </span>
              </div>
            </div>


          </div>
          <div className={"private-header__image"}>
            <UncontrolledLottie animationData={flag} />
          </div>
        </div>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}

SectionPrivateHeader.contextType = AppContext

export default withRouter(SectionPrivateHeader);
