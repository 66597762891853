import React from "react";
import { useParams } from "react-router-dom";
import HerosPlace from "./HerosPlace/HerosPlace";

const HerosPlacePage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <HerosPlace lang={lang} />
    </>
  )
}

export default HerosPlacePage;
