import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Icon from "../../atom/icons/Icon";
import Image from "../../atom/image/Image";
import { AppContext } from "../../../data/ContextProvider";
import { getContentPage } from "../../../data/api";
import { API_HOST } from "../../../data/config";
import { CONTENT_FOOTER_LINKBOXES } from "../../../data/constants";

class AppHighperformanceLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoading: true,
    }
  }

  componentDidMount() {
    getContentPage(CONTENT_FOOTER_LINKBOXES).then(page => {
      this.setState({ data: page.text })
      this.setState({ isLoading: false })
    })
  }


  render() {
    const { data, isLoading } = this.state

    if (isLoading) return 'Loading...'

    return (
      <>
      <div className={`highperformance-links__section`}>
        <div className={"highperformance-links__content"}>
          <div className={"highperformance-links__slider"}>
            {/*{this.state.data.map(news => (*/}
            <div className={"highperformance-links__box"}>
              <div className={"highperformance-links__box-header"}>
                <h3>{data.p40_footer_linkbox_toptitle_1}</h3>
              </div>
              <div className={"highperformance-links__box-image"}>

                <Image type="fullwidth" alt=""
                       image={`${API_HOST}/asset/${data["p40_gallery_img_1"]}?pid=${this.context.user.project_id}`} />

                {/* <Image type={"fullwidth"} alt={"tst"}
                       image={`${process.env.PUBLIC_URL}/images/app/highperformance/img--reise-new-york.jpg`} /> */}

              </div>
              <div className={"highperformance-links__box-lower"}>
                <h3>{data.p40_footer_linkbox_title_1}</h3>
                <p>{data.p40_footer_linkbox_subtitle_1}</p>
                <span className={"highperformance-links__box-readmore"}
                      onClick={() => this.props.history.push(`/${window.currentLang}/gewinnerreise`)}>
                  <Icon icon={"arrow"} />
                </span>
              </div>
            </div>
            <div className={"highperformance-links__box"}>
              <div className={"highperformance-links__box-header"}>
                <h3>{data.p40_footer_linkbox_toptitle_2}</h3>
              </div>
              <div className={"highperformance-links__box-image"}>
                <Image type="fullwidth" alt=""
                       image={`${API_HOST}/asset/${data["p40_gallery_img_2"]}?pid=${this.context.user.project_id}`} />

                {/* <Image type={"fullwidth"} alt={"tst"}
                       image={`${process.env.PUBLIC_URL}/images/app/highperformance/img--ranking-times-square.jpg`} /> */}
              </div>
              <div className={"highperformance-links__box-lower"}>
                <h3>{data.p40_footer_linkbox_title_2}</h3>
                <p>{data.p40_footer_linkbox_title_2}</p>
                <span className={"highperformance-links__box-readmore"}
                      onClick={() => this.props.history.push(`/${window.currentLang}/ranking`)}>
                  <Icon icon={"arrow"} />
                </span>
              </div>
            </div>
            <div className={"highperformance-links__box"}>
              <div className={"highperformance-links__box-header"}>
                <h3>{data.p40_footer_linkbox_toptitle_3}</h3>
              </div>
              <div className={"highperformance-links__box-image"}>
                <Image type="fullwidth" alt=""
                       image={`${API_HOST}/asset/${data["p40_gallery_img_3"]}?pid=${this.context.user.project_id}`} />
                {/* <Image type={"fullwidth"} alt={"tst"}
                       image={`${process.env.PUBLIC_URL}/images/app/highperformance/img--wertung-yellow-cap.jpg`} />  */}
              </div>
              <div className={"highperformance-links__box-lower"}>
                <h3>{data.p40_footer_linkbox_title_3}</h3>
                <p>{data.p40_footer_linkbox_title_3}</p>
                <span className={"highperformance-links__box-readmore"}
                      onClick={() => this.props.history.push(`/${window.currentLang}/wertungskriterien`)}>
                  <Icon icon={"arrow"} />
                </span>
              </div>
            </div>

            {/*))}*/}
          </div>
        </div>
      </div>
    </>
    );
  }
}

AppHighperformanceLinks.contextType = AppContext

export default withRouter(AppHighperformanceLinks);
