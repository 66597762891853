import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "./Content/Content";
import ContentHeader from "../components/element/content-header-section/ContentHeaderSection";
import AppHighperformanceLinks from "../components/module/app-highperformance-links/AppHighperformanceLinks";
import { getContentPage } from "../data/api";
import { CONTENT_INHALTS_SEITE } from "../data/constants";
import t from "../helpers/t9n";

const ContentPage = () => {
  let { lang } = useParams();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getContentPage(CONTENT_INHALTS_SEITE).then(page => {
      console.log(page)
      setData(page.text)
      setLoading(false)
    })
  }, ([]))

  if (loading)
    return t("global.loading")

  return (
    <>
      <ContentHeader heading={data.p1_header_content_headline} subHeading={data.p1_header_content_subline}
                     imageKey={data.p1_header_content_image} />
      <Content lang={lang} data={data} pageId={1} />
      <AppHighperformanceLinks />
    </>
  )
}

export default ContentPage;
