import React, { Component } from "react";
import { AppContext } from "../../../data/ContextProvider";
import { getBookings } from "../../../data/api";
import { formatDate } from "../../../lib/lib";
import PaginationFixed from "../../atom/pagination-fixed/PaginationFixed";
import t from "../../../helpers/t9n";

class SectionAccountHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentPage: 1,
    }
  }

  async componentDidMount() {
    await this.loadData()
  }

  loadData = async () => {
    const bookings = await getBookings({ page: this.state.currentPage })

    this.setState({
      bookings: bookings.bookings,
      meta: bookings.meta
    }, () => {
      this.setState({
        loading: false,
      })
    })
  }

  handlePagination = async (pageNumber) => {
    this.setState({
      currentPage: pageNumber
    }, () => {
      this.loadData()
    })
  }

  render() {

    if (this.state.loading)
      return "Loading..."

    const dataMeta = this.state.meta;

    return (
      <div className={"account-header__section"}>
        <h2>{t("global.account.heading")}</h2>
        <div className={"account-header__content"}>
            <div className={"account__left"}>
              <span className={"account__transaction-labels"}>
                <h3>{t("global.account.date")}</h3>
                <h3>{t("global.account.desc")}</h3>
                <h3>{t("global.account.points")}</h3>
              </span>
              <div className={"account__transaction-container"}>
              {this.state.bookings.length > 0 ?
                this.state.bookings.map((item, i) => {
                  return (
                    <div className={"account__transaction"} key={item.updated_at + "_" + i}>
                    <span>{formatDate(item.updated_at, false)}</span>
                    <span>{item.description}</span>
                    <b>{Number(item.points).toLocaleString()} {t("app.points")}</b>
                  </div>
                  )
                })
                :
                <div className={"account__transaction"}>
                  <h3>{t("global.empty.table")}</h3>
                </div>
              }
            </div>
            <PaginationFixed paginate={this.handlePagination}
                             prevPage={dataMeta.prev_page}
                             nextPage={dataMeta.next_page}
                             currentPage={this.state.currentPage}
                             totalPages={dataMeta.total_pages}
                             color="white" />
          </div>
          <div className={"account__right"}>
            <div className={"account__filter"}>
              <b>{t("global.account.sum")}</b>
              <span>{Number(this.state.meta.sum).toLocaleString()} {t("app.points")}</span>
              <b>{t("global.account.old.points")}</b>
              <h2 className={"product-headline"}>{Number(this.context.getPointsOfUser()).toLocaleString()} {t("app.points")}</h2>
            </div>

            <div className={"account__info--mobile"}>
              <div>
                <b>{t("global.account.sum")}</b>
                <h4>{Number(this.state.meta.sum).toLocaleString()} {t("app.points")}</h4>
              </div>
              <div>
                <b>{t("global.account.old.points")}</b>
                <h4 className={"product-headline"}>{Number(this.context.getPointsOfUser()).toLocaleString()} {t("app.points")}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SectionAccountHeader.contextType = AppContext

export default SectionAccountHeader;
