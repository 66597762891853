import React, { Component } from "react";
import NewsItem from "../../components/element/news-item/NewsItem";
import news from "../../assets/lotties/news/anim--icon-news";
import UncontrolledLottie from "../../components/atom/lottie/UncontrolledLottie";
import { getAllNews } from "../../data/api";
import { Link } from "react-router-dom";
import Button from "../../components/atom/button/Button";
import { formatDate } from "../../lib/lib";
import { AppContext } from "../../data/ContextProvider";
import t from "../../helpers/t9n";


class News extends Component {

  constructor(props) {
    super(props)
    this.state = {
      news: [],
      loading: true
    }
  }

  async componentDidMount() {
    const news = await getAllNews()
    this.setState({
      news: news.news,
      loading: false
    })
  }

  render() {

    if (this.state.loading) {
      return "Loading.."
    }

    return (
      <>
        <div className={"content news"}>
          <span className={"news__header"}>
            <UncontrolledLottie animationData={news} />
          </span>
          <div className={"news__content"}>
            {this.state.news.length > 0 ?
              this.state.news.map(single => (
                <NewsItem heading={single.subject}
                          title={single.title}
                          id={single.id}
                          date={(single.valid_from) ? formatDate(single.valid_from, false) : ""}
                          key={single.id}
                          isRead={single.has_read}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: single.message ? single.message.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                  }}>
                </p>
              </NewsItem>
              ))
              :
              <>
              <div className={`orders__left orders__left--empty`}>
                <div className={"orders__empty news__empty"}>
                  <h2>{t("app.news.no.heading")}</h2>

                  {(this.context.getHasShopAccess() &&
                    <span className={"orders__empty-actions"}>
                      <Link to={`/${window.currentLang}/shop`}>
                        <Button type={"primary"}
                                textColor={"white"}
                                text={t("app.news.no.button")} />
                      </Link>
                    </span>
                  )}

                </div>
              </div>
              </>
            }
          </div>
        </div>
      </>
    );
  }
}

News.contextType = AppContext


export default News;
