import React, { Component } from "react";
import CounterSpawner from "../../components/element/counter-spawner/CounterSpawner";
import ProgressCircle from "../../components/element/progress-circle/ProgressCircle";
import { dateDiff, dateExpired } from '../../helpers/utils'
import t from "../../helpers/t9n";

class AppHerosPlacePerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateEndDiff: dateDiff(this.props.end), //FIXME !!!!!!!!!!!!!!!
      dateStartToEndDiff: dateDiff(this.props.end, new Date(this.props.start)),
      dateExpired: dateExpired(this.props.end), //FIXME !!!!!!!!!!!!!!!
    }
  }

  render() {

    return (
      <div className="heros-performance__section">
        <div className="heros-performance__content">
          <div className="heros-place-header__charts">
            <h3>{t("app.campaign.end")}</h3>
            <span>
              <ProgressCircle
                value={this.state.dateEndDiff.days}
                maxValue={this.state.dateStartToEndDiff.days}
                isCountDown={true}
                text={t("app.days")}
                expired={this.state.dateExpired}
              />
              <ProgressCircle
                value={this.state.dateEndDiff.hours}
                maxValue={24}
                text={t("app.hours")}
                expired={this.state.dateExpired}
              />
              <ProgressCircle
                value={this.state.dateEndDiff.minutes}
                maxValue={60}
                text={t("app.mins")}
                expired={this.state.dateExpired}
              />
            </span>
          </div>

          <div className={"heros-performance__box"}>
            <h3>{t("app.sales.performance")}</h3>
            <div className={"heros-performance__box-container"}>
              <span className={"heros-performance__box-wrapper"}>
                <span>
                  <CounterSpawner amount={this.props.transactions.average_transactions} type={"standard"}
                                  digits={this.props.currencyMode === "currency" ? 7 : null} />
                  <span className={"heros-performance__box-type"}>
                    <h3>&#216; {t("app.sales.national")}</h3>
                  </span>
                </span>
                {this.props.currencyMode === "currency" ?
                  <p>{t("app.sales.per.contract")}</p>
                  :
                  <p>{t("app.contracts.number")}</p>
                }
              </span>
              <span className={"heros-performance__box-wrapper"}>
                <span>
                  <CounterSpawner amount={this.props.transactions.user_transactions} type={"highlight"}
                                  digits={this.props.currencyMode === "currency" ? 7 : null} />
                  <span className={"heros-performance__box-type"}>
                    <h3>{t("app.sales.status")}</h3>
                  </span>
                </span>
                {this.props.currencyMode === "currency" ?
                  <p>{t("app.sales.per.contract")}</p>
                  :
                  <p>{t("app.contracts.number")}</p>
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppHerosPlacePerformance;
