import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";

import { AppContext } from "../../data/ContextProvider";


import Rules from './Pages/Rules';
import Welcome from './Pages/Welcome';
import Conditions from './Pages/Conditions';
import PrivacyComp from './Pages/Privacy';
import Registration from './Pages/Registration';
import Login from './Pages/Login';
import ProfileComp from './Pages/Profile';
import Complete from './Pages/Complete';
import Imprint from './Pages/Imprint';

import LoginLayout from "../../layout/LoginLayout";
import Layout from './Components/Layout';

import ETracker from "../../components/module/eTracker";

import { checkManagerToken } from "../../lib/lib";

class ComplTool extends Component {
  constructor(props) {
    super(props)
    this.state = {
      disabled: false,
    }
  }

  async componentDidMount() {

    try {
      const userData = await checkManagerToken();

      if (userData) {
        this.context.saveManagerData(userData)
      }
    } catch (e) {
    }
  }

  getLoginPage = () => {
    return <LoginLayout>
      <Login />
    </LoginLayout>
  }

  getDisabledPage = () => {
    return <Layout fullHeight={true}>
      <Complete />
    </Layout>
  }

  render() {
    return (
      <>
        { !this.context.isManagerLoggedIn ?
            this.getLoginPage()
          :
            <>
              <Route exact path="/:lang?/compliance-tool/login">
                {this.getLoginPage()}
              </Route>

              { this.context.user.compl_tool_disabled &&
                this.getDisabledPage()
              }

              {(this.context.user.compl_tool_enabled && this.context.isManagerLoggedIn) &&
                <>
                  {this.context.user.tracking_code &&
                    <ETracker />
                  }

                  <Route exact path="/:lang?/compliance-tool/footer-imprint">
                    <Layout fullHeight={true}>
                      <Imprint />
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/footer-conditions">
                    <Layout fullHeight={true}>
                      <Conditions footer_mode={true}/>
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/footer-privacy">
                    <Layout fullHeight={true}>
                      <PrivacyComp footer_mode={true}/>
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/footer-rules">
                    <Layout fullHeight={true}>
                      <Rules footer_mode={true}/>
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/welcome">
                    <Layout fullHeight={true}>
                      <Welcome />
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/rules">
                    <Layout fullHeight={true}>
                      <Rules />
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/conditions">
                    <Layout fullHeight={true}>
                      <Conditions footer_mode={false} />
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/privacy">
                    <Layout fullHeight={true}>
                      <PrivacyComp footer_mode={false} />
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/profile">
                    <Layout fullHeight={true}>
                      <ProfileComp />
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/registration">
                    <Layout fullHeight={true}>
                      <Registration />
                    </Layout>
                  </Route>

                  <Route exact path="/:lang?/compliance-tool/complete">
                    {this.getDisabledPage()}
                  </Route>
                </>
              }
            </>
          }
      </>
    );
  }
}

ComplTool.contextType = AppContext
export default withRouter(ComplTool)