import React from "react";
import { useParams } from "react-router-dom";
import SchaffeIch from "./SchaffeIch/SchaffeIch";

const SchaffeIchPage = () => {
  let { lang } = useParams();

  return (
    <>
      <SchaffeIch lang={lang} />
    </>
  )
}

export default SchaffeIchPage;
