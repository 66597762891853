import t from "./t9n"

const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

const addZeroToNumber = (number) => {
  if (number.length === 1) {
    number = '0' + number
    return number
  }
  return number
}

export function formatShortDate(dateString) {
  const timestamp = Date.parse(dateString)
  const date = new Date(timestamp)

  if (date) {
    const yyyy = date.getFullYear().toString()
    const mm = addZeroToNumber((date.getMonth() + 1).toString())
    const dd = addZeroToNumber(date.getDate().toString())

    return dd + '.' + mm + '.' + yyyy
  }
  return ''
}

const generateQuantity = () => {
  const values = {};

  for (let i = 1; i < 60; ++i) {
    values[i] = i;
    // for (let j = 1; j < 60; ++j)
    //   values[i][j] = 0;
  }
  return values
}

const dateExpired = (date) => {
  date = typeof date === 'object' ? date : new Date(date)
  return (new Date().getTime() > date.getTime())
}

const dateDiff = (dateFutura, dateA = null) => {

  dateA = dateA || new Date()
  dateFutura = typeof dateFutura === 'object' ? dateFutura : new Date(dateFutura)

  if (dateFutura.getTime() >= dateA.getTime()) {

    let delta = Math.abs(dateFutura.getTime() - dateA.getTime()) / 1000

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = delta % 60;  // in theory the modulus is not required

    return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    }
  } else {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }
}

export function renderAvailabilityState(state) {
  if (state === "direct_dispatch") {
    return t("cart.product.available")
  }
  return state
}

const getProductImage = (images, isHighlight=false) => {
  let url = ''

  if (isHighlight) {
    if (images && images.length > 0) {
      images.forEach((image) => {
        if (url.length === 0 && image && image.style === 'highlight_huge') {
          url = image.url
        }
      })
    }
  }

  if (url.length !== 0) {
    return url
  }

  if (images.length > 0) {
    return images[0].url
  } else {
    return process.env.PUBLIC_URL + "/images/img--placeholder-product.jpg"
  }
}

const getProductPrice = (option, project_code) => {

  if (!option || !option.prices) {
    return 0
  }

  let price = option.prices.filter(price => {
    return price.project_code === project_code
  })

  if (price.length > 0) {
    return price[0].price_in_cents
  } else {
    return 0
  }

}

const getMinProductPriceForProject = (options, project_code) => {
  // console.log("@getMinProductPriceForProject proj:", project_code)
  const prices = []
  // Iterate Options to find Option
  options.forEach(option => {
    let optionPrices = option.prices.filter(price => {
      return price.project_code === project_code
    })
    // ggf. hat nur irgendeine Option ein Preis für das aktuelle Projekt
    if (optionPrices.length) {
      prices.push(optionPrices[0])
    }
  });
  if (prices.length > 0) {
    // Mindestpreis für ProductList "ab n PKT"
    return Math.min(...prices.map(item => item.price_in_cents));
  } else {
    return 0
  }
}

/**
 *
 * @param {Array} options
 * @param {String} project_code
 * @param {Number|null} option_id (should only be null when the request came from the productList and therfore unknown)
 */
const getVariantForProject = (options, project_code, option_id) => {
  // makes sure to return the option for project or by given option id
  // console.log('option_id', option_id, options)
  let optionsIndex = -1
  options.forEach((option, index) => {
    let optionPrices = option.prices.filter(price => {
      return price.project_code === project_code
    })
    if (optionPrices.length) {
      if (option_id !== null && option.id === option_id) {
        optionsIndex = index
      } else {
        optionsIndex = index
      }
    }
  });

  // only return a option we have prices for
  if (optionsIndex !== -1) {
    return options[optionsIndex]
  }
  return null
}


/**
 * Set expiration date for local storage
 */
const setWithExpiry = (key, value, ttl) => {
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

/**
 * Get expiration date for local storage
 */
const getWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key)

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date()

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key)
    return null
  }
  return item.value
}

export function renderTitle(item) {
  if (window.currentLang !== 'de' && item.title_en && item.title_en.length > 0) {
    return item.title_en
  }
  return item.title
}

export function renderGroup(item) {
  if (window.currentLang !== 'de' && item.group_en && item.group_en.length > 0) {
    return item.group_en
  }
  return item.group
}

export function renderDescription(item) {
  if (window.currentLang !== 'de' && item.description_en && item.description_en.length > 0) {
    return item.description_en
  }
  return item.description
}

const getDeliveryDate = (msg) => {
  const re = /\d{1,2} – \d{1,2}/
  const reDate = /\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/
  let date = msg.match(reDate)
  let duration = msg.match(re)
  let translation = ""

  if (msg.match(/Wochen/)) {

    // Artikel lieferbar innerhalb von 2 – 4 Wochen
    translation = t("shop.delivery1.msg1") + duration[0] + t("shop.delivery1.msg1.day")

  } else if (msg.match(/sofort lieferbar/)) {

    // Artikel sofort lieferbar
    translation = t("cart.product.available")

  } else if (msg.match(/ist nicht lieferbar/)) {

    // Artikel ist ausverkauft
    translation = t("cart.no.available")

  } else if (msg.match(/ist ausverkauft/)) {

    // Artikel ist ausverkauft
    translation = t("cart.no.available")

  } else if (msg.match(/ist verfügbar ab/)) {

    // Artikel ist verfügbar ab 23.02.2021
    translation = t("cart.product.soon.available") + date[0]

  } else {

    // Artikel lieferbar in 7 – 10 Werktagen
    translation = t("shop.delivery2.msg2") + duration[0] + t("shop.delivery2.msg2.day")
    
  }

  return translation;
}

export {
  range,
  addZeroToNumber,
  generateQuantity,
  dateDiff,
  getProductImage,
  getProductPrice,
  dateExpired,
  getMinProductPriceForProject,
  getVariantForProject,
  setWithExpiry,
  getWithExpiry,
  getDeliveryDate
}