import React from "react";
import { useParams } from "react-router-dom";
import Highperformance from "./Highperformance/Highperformance";

const HighperformancePage = (props) => {
  let { lang } = useParams();
  
  return (
    <>
      <Highperformance lang={lang} view={props.view} />
    </>
  )
}

export default HighperformancePage;
