import React from "react";
import { useParams } from "react-router-dom";
import PromoBooster from "./PromoBooster/PromoBooster";

const PromoBoosterPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <PromoBooster lang={lang} />
    </>
  )
}

export default PromoBoosterPage;
