import React from 'react';
import Icon from "../icons/Icon";

const PaginationFixed = (props) => {
  return (
    <nav>
      <ul className={`pagination pagination--${props.color}`}>
        <span
          className={`pagination__action pagination__action--left ${(props.currentPage > 0) ? "" : "pagination__action--hidden"}`}
          onClick={() => props.paginate(props.currentPage-1)}>
          <Icon icon={"arrow"} />
        </span>
        <span
          className={`pagination__action pagination__action--right ${props.nextPage ? "" : "pagination__action--hidden"}`}
          onClick={() => props.paginate(props.currentPage+1)}>
          <Icon icon={"arrow"} />
        </span>
      </ul>
    </nav>
  );
};

PaginationFixed.defaultProps = {
  color: "black",
};

export default PaginationFixed;