import React, { Component } from "react";
import Button from "../../atom/button/Button";
import ProgressCircle from "../../element/progress-circle/ProgressCircle";
import { dateDiff } from '../../../helpers/utils'
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
import Icon from "../../atom/icons/Icon";
import { AppContext } from "../../../data/ContextProvider";
import { withRouter } from "react-router-dom";
import t from "../../../helpers/t9n";
import { getAssetSlider } from "../../../data/api";

class AppGewinnerreisePerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startNr: 0,
      endNr: ~~this.props.performance.percent,
      data: this.props.performance,
      dateEndDiff: dateDiff(this.props.performance.end),
      dateStartToEndDiff: dateDiff(this.props.performance.end, new Date(this.props.performance.start)),
      reachedGoal: false,
      headerSlider: []
    }
    this.timerHandle = null
    this.loaderHandle = null

    this.loaderIndex = 0;
    this.endingNumber = 0;
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
      loading: false
    })
  }

  componentDidMount() {
    this.loadData()
    let loadingBarValues = []
    if (this.percent)
      if (Math.ceil(Math.log10(this.state.endNr + 1)) >= 3) {
        //3 digits = over 100
        const hundrets = (this.state.endNr / 100)
        for (let i = 1; i <= hundrets; i++) {
          loadingBarValues.push(100)
        }
        loadingBarValues.push(Math.floor(this.state.endNr - (Math.floor(hundrets) * 100)))
        this.loadUp(loadingBarValues, 0)
      } else {
        this.loadUp([this.state.endNr], 0)
      }
  }

  loadUp = (values, count = 0) => {
    this.loader.style.width = count + "%";

    if (this.loaderIndex > 0) {
      this.percent.innerHTML = (count + (this.loaderIndex * 100)) + "%";
    } else {
      this.percent.innerHTML = count + "%";
    }

    this.endingNumber = values[this.loaderIndex]

    if (count < this.endingNumber) {
      this.loaderHandle = setTimeout(() => this.loadUp(values, count + 1), 20)
    } else {

      if (typeof values[this.loaderIndex + 1] !== 'undefined') {
        this.loader.style.width = 0 + "%";
        this.setState({
          reachedGoal: true
        })
        this.loaderIndex += 1;
        this.loadUp(values, 0)
      }
    }
  }

  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle)
    this.loaderHandle && clearTimeout(this.loaderHandle)
  }

  countUp = (count) => {
    this.percent.innerHTML = count + "%";

    if (count < this.state.endNr) {
      this.timerHandle = setTimeout(() => this.countUp(count + 1), 10)
    }
  }

  render() {
    const data = this.state.data


    return (
      <div className={`highperformance-performance__section`}>
        <div className={"app-header__actions app-header__actions--mobile"}>
          <Button type={"secondary"}
                  text={`${this.props.isHeaderOpen ? t("app.less.info") : t("app.more.info")}`}
                  textColor={"black"} backgroundColor={"bg-white"}
                  isClubButton={true}
                  onClick={() => this.props.handleHeader()} />
        </div>
        <div className={"highperformance-performance__content"}>

          <div className={"gewinnerreise-header__center"}>

            <div className={"gewinnerreise-header__center-header"}>
              <h3>{t("app.your.current.rank")}</h3>
            </div>
            <div className={"gewinnerreise-header__center-rank"}>
              <Icon icon={"star"} />
              <h1>{data.current_place}</h1>
              <Icon icon={"star"} />
            </div>
          </div>

          <div className={"gewinnerreise-header__right"}>
            <div className={"gewinnerreise-header__information--mobile"}>
              <h3>{t("menu.welcome.text")} {this.context.getNameOfUser()}</h3>
              <p>{t("app.welcome.to.highperformance")}</p>
            </div>
            <div>
              <Button isClubButton={true} type={"club-link"} backgroundColor={"bg-white"} textColor={"black"}
                      isGold={false}
                      text={t("app.show.rank")} onClick={() => {
                this.props.history.push(`/${window.currentLang}/ranking`)
              }} />
            </div>
          </div>

          <div className={"highperformance-performance__box highperformance-performance__box--timer"}>
            <h3>{t("app.remaining.time")}</h3>
            <span>
              <ProgressCircle value={this.state.dateEndDiff.days} maxValue={this.state.dateStartToEndDiff.days}
                              isCountDown={true} text={t("app.days")} />
              <ProgressCircle value={this.state.dateEndDiff.hours} maxValue={24} text={t("app.hours")} />
              <ProgressCircle value={this.state.dateEndDiff.minutes} maxValue={60} text={t("app.mins")} />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

AppGewinnerreisePerformance.contextType = AppContext

export default withRouter(AppGewinnerreisePerformance);
