import React, { Component } from "react";
import { AppContext } from "../../../data/ContextProvider";
import PropTypes from "prop-types";
import Textfield from "../../atom/textfield/Textfield";
import Button from "../../atom/button/Button";
import { getProducts, redeemPromoCode } from "../../../data/api";
import { getToken } from "../../../lib/lib";
import Product from "../../element/product/Product";
import { getMinProductPriceForProject } from "../../../helpers/utils";
import t from "../../../helpers/t9n";

class AppPromoBoosterLower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridLimit: 8,

      error: ""
    }
  }

  componentDidMount() {
    const products = getProducts(getToken(), 1)

    products.then(products => {
      this.setState({
        products: products && products.products,
        loading: false
      })
    })
  }

  handleChange = (e) => {
    if (e.target) {
      const name = e.target.name
      const value = e.target.value
      const state = this.state
      state[name] = value
      this.setState({ ...state });
    }
  }

  async handleSubmitCode(e) {
    e.preventDefault()
    try {
      const res = await redeemPromoCode(this.state.code)
      if (res.user) {

        window.location.reload();
      }
    } catch (error) {
      this.setState({
        error: error.data.error
      })
      console.log(error)
    }
  }

  render() {
    if (this.state.loading) {
      return t("global.loading")
    }

    const products = this.state.products

    return (
      <>
        <div className={"promobooster-code__section"}>
          <h3>{t("app.award.code")}</h3>
          <Textfield placeholder={t("app.award.code.placeholder")} name={"code"} handleChange={(e) => this.handleChange(e)}
                     errorMsg={this.state.error}
          />
          <Button type={"secondary"}
                  text={t("app.award.code.redeem")}
                  textColor={"white"}
                  onClick={(e) => this.handleSubmitCode(e)} />
        </div>
        <div className={"promobooster-selection__section"}>
          <span className="card-slider__header">
            <h2 className="card-slider__label">
              {t("app.exclusive.award.collection")}
            </h2>
          </span>
          <div className={"promobooster-selection__grid"}>
            <div className={"shop__product-grid"}>
              {products && products.slice(0, this.state.gridLimit).map((p, i) => {
                return <Product type={"shop"}
                                title={p.title}
                                key={p.id}
                                image={p.images[0].url}
                                id={p.id}
                                hasMultipleVariants={(p.options.length > 0)}
                                price={getMinProductPriceForProject(p.options, this.context.getUserData().project_code)}
                                isWished={p.wish}
                                pid={p.number}
                                hasWishAction={true} />
              })}
            </div>
          </div>
        </div>
      </>
    );
  }
}

AppPromoBoosterLower.propTypes = {
  handleHeader: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
};
AppPromoBoosterLower.defaultProps = {
  isHeaderOpen: false,
};

AppPromoBoosterLower.contextType = AppContext

export default AppPromoBoosterLower;
