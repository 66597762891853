import React from "react";
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import Icon from "../../atom/icons/Icon";
import TextLink from "../../atom/textlink/textlink";

const OrderItem = props => {
  
  return (
    <div className={`order-item`}>
      <div className={"order-item__wrapper"}>
        <span className={"order-item__image"}>
          <Link to={`/${window.currentLang}/shop/product`}>
            <Icon icon={`${props.isDetailView ? "shipment-package-open" : "shipment-package"}`} />
          </Link>
        </span>
        
        <div className={"order-item__content"}>
          <span className={"order-item__row"}>
            <span className={"order-item__title"}>Bestell-NR.: {props.orderNr}</span>
          </span>
          <span className={"order-item__row"}>
            <span className={"order-item__shipping"}>Bestelldatum: {props.orderDate}</span>
          </span>
        </div>
      </div>
      {!props.isDetailView &&
      <div className={"order-item__details"}>
        <span className={"order-item__price"}>{props.orderPrice} PKT</span>
        <TextLink url={`/${window.currentLang}/orders/${props.orderId}`} text={"Bestellung ansehen"} iconPlacement={"after"} />
      </div>
      }
    </div>
  );
}

OrderItem.propTypes = {
  isDetailView: PropTypes.bool
};
OrderItem.defaultProps = {
  isDetailView: false
};

export default OrderItem;
