import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
import jackpot from "../../../assets/lotties/jackpot/anim--jackpot-gift";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import { getContentPage, getAppPrefs, getAssetSlider } from "../../../data/api";
import { withRouter } from "react-router-dom";
import { AppContext } from "../../../data/ContextProvider";
import { CONTENT_SPIELREGELN } from "../../../data/constants";
import t from "../../../helpers/t9n";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";

class AppJackpotHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      loading: true,
      jackpot: 0,
      headerSlider: []
    }
  }

  async componentDidMount() {
    const appPrefs = await getAppPrefs()
    const page = await getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)
    const headerSlider = await getAssetSlider('header')

    this.setState({
      pageText: page.text,
      jackpot: appPrefs.preferences,
      loading: false,
      headerSlider: headerSlider.slider
    })
  }

  handleHeader() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    const text = this.state.pageText

    return (
      <>
        {this.state.headerSlider.length > 0 &&
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        }

        <>
          <div className={`app-header__section ${this.state.isOpen ? "app-header__section--open" : ""}`}>
            <video loop autoPlay className={"oldie-hide"}>
              <source
                src={`${process.env.PUBLIC_URL}/videos/Jackpot/anim--jackpot-bg.mp4`}
                type="video/mp4" />{t("app.video.no.support")}
            </video>
            <div className={"app-header__content"}>
              <div className={"app-header__left"}>
                <span className={"app-header__heading"}>
                  <div>
                    <span>
                      <Icon icon={"app-praemienjackpot"} />
                    </span>
                    <h1>{text.p33_header_content_headline}</h1>
                  </div>
                  <h2>{text.p33_header_content_subline}</h2>
                </span>

                <p
                  dangerouslySetInnerHTML={{
                    __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                  }}>
                </p>

                {/*<h3>Willkommen im PrämienJackpot..</h3>*/}
                {/*<p>Hier erwartet Sie eine wunderbare Auswahl von über 3.000 tollen Sachprämien. Jeden Umsatz/Auftrag den Sie mit einem Aktionsprodukt machen, belohnen wir mit wertvollen Prämienpunkten, die Sie dann in Prämien eintauschen können. Im Jackpot-Counter sehen Sie, wie viele Punkte noch unter allen Teilnehmern ausgeschüttet werden können. Beeilen Sie sich mit dem Punkte sammeln, denn jedes Mal, wenn Punkte auf ein Konto gebucht werden, verringert sich der Jackpot! Ist der Counter auf Null, ist die Aktion vorbei.</p>*/}

                <span className={"app-header__actions"}>
                  <Button type={"link"} text={`${this.state.isOpen ? t("global.less.info") : t("global.open.info")}`}
                          textColor={"white"} icon={"arrow"} onClick={() =>
                    this.handleHeader()
                  } />
                  <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                          onClick={() => {
                            this.props.history.push(`/${window.currentLang}/rules`)
                          }} />
                </span>
              </div>
              <div className={"app-header__right"}>
                <div className={"jackpot-counter"}>
                  <span className={"jackpot-counter__icon"}>
                    <UncontrolledLottie animationData={confetti} />
                  </span>
                  <h2>{t("app.jackpot.counter")}</h2>
                  <span className={"jackpot-counter__wrapper"}>
                    <CounterSpawner amount={~~Number(this.state.jackpot.amount)} type={"highlight"} />
                    <h5>{t("app.points")}</h5>
                  </span>

                  <h5>{t("app.still.in.jackpot")}</h5>
                  <div className={"jackpot-counter__bank"}>
                    <h5>{t("app.your.point.status")}</h5>
                    <h2><b>{Number(this.context.getPointsOfUser()).toLocaleString()} {t("app.points")}</b></h2>
                  </div>
                </div>
              </div>
            </div>
            <span className={"app-header__box"}>
              <UncontrolledLottie animationData={jackpot} />
            </span>
          </div>
          <span className={"app-header__actions app-header__actions--mobile"}>
            <Button type={"secondary"} text={`${this.state.isOpen ? t("app.less.info") : t("app.more.info")}`}
                    textColor={"black"} backgroundColor={"bg-white"}
                    onClick={() => this.handleHeader()}
            />
          </span>
        </>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}

AppJackpotHeader.contextType = AppContext

export default withRouter(AppJackpotHeader);
