import React, { Component } from "react";
import Button from "../../atom/button/Button";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import Icon from "../../atom/icons/Icon";
import ProgressCircle from "../../element/progress-circle/ProgressCircle";
import missingTarget from "../../../assets/lotties/missing-target/icon--anim-missing-target.json";
import bullsEye from "../../../assets/lotties/bullseye/icon--anim-bullseye.json";
import { withRouter } from "react-router-dom";
import t from "../../../helpers/t9n";

class AppSchaffeIchRoom extends Component {

  constructor(props) {
    super(props);
    this.state = {
      prefs: this.props.prefs,
      betStarted: false,
      selectedBet: this.props.selectedBet,
      text: this.props.pageText
    }
    this.timerHandle = null

    this.endingNumber = 0;
  }

  handlePreSelectBet = (id) => {
    let newBet = id.toString()
    this.setState({
      selectedBet: newBet
    })
  }

  componentDidMount() {
    if (this.percent)
      this.loadUp(~~this.props.performance.percent, 0)
  }

  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle)
  }

  loadUp = (value, count = 0) => {
    this.loader.style.width = count + "%";
    this.loader2.style.width = count + "%";
    this.percent.innerHTML = count + "%";
    this.percent2.innerHTML = count + "%";
    this.endingNumber = value

    if (count < this.endingNumber) {
      this.timerHandle = setTimeout(() => this.loadUp(this.endingNumber, count + 1), 20)
    }
  }

  getChallengeSelectSection = () => {
    return (
      <>
      {!this.props.dateExpired &&
      <span className={"schaffe-ich-room__headline"}>
          <h2>{t("app.choose.challenge")}</h2>
        </span>
      }
        <div className={"schaffe-ich-room__boxes"}>
        {this.props.bets.map((bet, i) => (
          <div className={"schaffe-ich-room__box--wrapper"} key={"challenge_" + (i + 1)}>
            <h2>{t("app.challenge")} {i + 1}</h2>
            <div className={"schaffe-ich-room__box"}>
              <div className={"schaffe-ich-room__box-container"}>
                <span className={"jackpot-counter__icon"}>
                  <Icon icon={"3-stars"} />
                </span>
                <div
                  className={`schaffe-ich-room__bet ${this.props.selectedBet === (i + 1).toString() ? "schaffe-ich-room__bet--selected" : ""}`}>
                  <h4>{t("app.target.value")}</h4>
                  <h1>{Number(bet.target).toLocaleString()} €</h1>
                  <span className={"schaffe-ich-room__bet-banderole"}>
                    <h5>{t("app.your.profit")}</h5>
                    <h2>{Number(bet.betwin).toLocaleString()} PKT</h2>
                  </span>
                  <div className={"schaffe-ich-room__bet-action"}>
                    {this.state.selectedBet === (i + 1).toString() ?
                      <span className={"schaffe-ich-room__bet-circle-ticked"}
                            onClick={() => this.handlePreSelectBet(i + 1)}>
                        <Icon icon={"check-circle"} />
                      </span>
                      :
                      <div className={"schaffe-ich-room__bet-circle"}
                           onClick={() => this.handlePreSelectBet(i + 1)} />
                    }
                    <div className={"schaffe-ich-room__bet-info"}>
                      <h3>{t("app.schaffeich")}</h3>
                      <p>{t("app.this.challenge")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      </>
    )
  }

  getChallengeProgressSection = () => {
    return (
      <React.Fragment>
      <span className={"schaffe-ich-room__headline"}>
        <h2>{t("app.personal.challenge")}</h2>
      </span>
      <div className={"schaffe-ich-room__boxes"}>
        <div className={"schaffe-ich-room__challenge"}>
          <div className={"schaffe-ich-room__challenge-goal"}>
            <h4>{t("app.target.achievement")}</h4>
            <div className={`schaffe-ich-room__loader`}>
              <span ref={loader => (this.loader = loader)} className={"schaffe-ich-room__loader-indicator"} />
            </div>
            <h2 className={"schaffe-ich-room__loader-percent"} ref={percent => (this.percent = percent)}>
              0 %
            </h2>
          </div>
          <div className={"schaffe-ich-room__challenge-center"}>
            <div className={"schaffe-ich-room__challenge-current"}>
              <div className={"schaffe-ich-room__challenge-circle"}>
                <UncontrolledLottie animationData={missingTarget} />
              </div>
              <h4>{t("app.actual.value")}</h4>
              <h2>{Number(this.props.performance.performance).toLocaleString()}</h2>
              <h4>{t("app.currency")}</h4>
              <div className={"schaffe-ich-room__challenge-current-triangle"} />
            </div>
            <div className={"schaffe-ich-room__challenge-current-goal"}>
              <div className={"schaffe-ich-room__challenge-circle"}>
                <UncontrolledLottie animationData={bullsEye} />
              </div>
              <h4>{t("app.target.value.challenge")}</h4>
              <h2>{Number(this.props.performance.target).toLocaleString()}</h2>
              <h4>{t("app.currency")}</h4>
            </div>
          </div>
          <div className={"schaffe-ich-room__challenge-win"}>
            <h4>{t("app.win.target.achievement")}</h4>
            <h2>+{Number(this.props.bets.win).toLocaleString()} <sup>{t("app.points")}</sup></h2>
          </div>
        </div>

        <div className={"schaffe-ich-room__challenge schaffe-ich-room__challenge--tablet"}>
          <div className={"schaffe-ich-room__challenge-center"}>
            <div className={"schaffe-ich-room__challenge-current"}>
              <div className={"schaffe-ich-room__challenge-circle"}>
                <UncontrolledLottie animationData={missingTarget} />
              </div>
              <div className={"schaffe-ich-room__challenge-circle"}>
                <UncontrolledLottie animationData={bullsEye} />
              </div>
              <h4>{t("app.actual.value")}</h4>
              <h2>{Number(this.props.performance.performance).toLocaleString()}</h2>
              <h4>{t("app.currency")}</h4>
              <div className={"schaffe-ich-room__challenge-current-triangle"} />
            </div>
            <div className={"schaffe-ich-room__challenge-current-goal"}>
              <h4>{t("app.target.value")}</h4>
              <h2>{Number(this.props.performance.target).toLocaleString()}</h2>
              <h4>{t("app.currency")}</h4>
            </div>
          </div>

          <div className={"schaffe-ich-room__challenge-goal"}>
            <h4>{t("app.target.achievement")}</h4>
            <div className={`schaffe-ich-room__loader`}>
              <span ref={loader2 => (this.loader2 = loader2)} className={"schaffe-ich-room__loader-indicator"} />
            </div>
            <h2 className={"schaffe-ich-room__loader-percent"} ref={percent2 => (this.percent2 = percent2)}>
              0 %
            </h2>
          </div>

          <div className={"schaffe-ich-room__challenge-win"}>
            <h4>{t("app.win.target.achievement")}</h4>
            <h2>+{Number(this.props.bets.win).toLocaleString()} <sup>{t("app.points")}</sup></h2>
          </div>
        </div>

        <div className={"schaffe-ich-room__actions"}>
          <Button type={"secondary"} textColor={"white"} text={t("global.points.account")}
                  onClick={() => {
                    this.props.history.push(`/${window.currentLang}/account`)
                  }} />

          <Button type={"secondary"} textColor={"white"} text={t("menu.my.contracts")}
                  onClick={() => {
                    this.props.history.push(`/${window.currentLang}/performancedata`)
                  }} />
        </div>

      </div>
      </React.Fragment>
    )
  }


  render() {

    return (
      <div className={`schaffe-ich-room__section`}>
        <div className={"app-header__actions app-header__actions--mobile"}>
          <Button type={"secondary"}
                  text={`${this.props.isHeaderOpen ? t("app.less.info") : t("app.more.info")}`}
                  textColor={"black"} backgroundColor={"bg-white"}
                  onClick={() => this.props.handleHeader()} />
        </div>
        <div className={"schaffe-ich-room__content"}>
          <div className={"schaffe-ich-room__wrapper"}>

            <div className={"schaffe-ich-room__information"}>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.text.p33_upper_content_text ? this.state.text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                }}>
              </p>
              <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                      onClick={() => {
                        this.props.history.push(`/${window.currentLang}/rules`)
                      }} />
            </div>

            {!this.props.dateExpired &&
            <div className={"schaffe-ich-room__timer-wrapper"}>
                <h2>{this.props.hasCampaignStarted ? t("app.promotional.period.ends") : t("app.time.choose.challenge")}</h2>
                <div className={"salespush-performance__box salespush-performance__box--timer"}>
                  <span>
                    <ProgressCircle
                      value={this.props.dateEndDiff.days}
                      maxValue={this.props.dateStartToEndDiff.days}
                      isCountDown={true} text={t("app.days")}
                      expired={this.props.dateExpired}
                    />
                    <ProgressCircle
                      value={this.props.dateEndDiff.hours}
                      maxValue={24}
                      text={t("app.hours")}
                      expired={this.props.dateExpired}
                    />
                    <ProgressCircle
                      value={this.props.dateEndDiff.minutes}
                      maxValue={60}
                      text={t("app.mins")}
                      expired={this.props.dateExpired}
                    />
                  </span>
                </div>
              </div>
            }

            {this.props.hasCampaignStarted ? this.getChallengeProgressSection() : this.getChallengeSelectSection()}

            {!this.props.hasCampaignStarted && !this.props.dateExpired &&
            <div className={"schaffe-ich-room__actions"}>
              <Button text={t("app.save.challenge")} textColor={"white"} type={"secondary"}
                      onClick={() => this.props.handleSelectBet(this.state.selectedBet)} />
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AppSchaffeIchRoom);
