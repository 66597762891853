import React from 'react';
import PropTypes from "prop-types";

const Pagination = (props) => {
  const pageNumbers = [];
  //TODO: FONT SIZE AND OBJECT SIZE BIGGER

  // const paginate = pageNumber => this.setState({ currentPage: pageNumber });

  for (let i = 1; i <= Math.ceil(props.totalItems / props.itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className='pagination'>
        {pageNumbers.length < 8 ? pageNumbers.map(number => (
            <li key={number}
                className={`pagination__item ${number === props.currentPage ? "pagination__item--current" : ""}`}>
            <span onClick={() => props.paginate(number)}
                  className={`pagination__link`}>
              {number}
            </span>
          </li>
          ))
          :
          pageNumbers.map((number, i) => {
            if (i > 3 && i < 7) {
              return (
                <li key={number}
                    className={`pagination__item pagination__item--placeholder `}>
                  <span className={`pagination__link`}>
                    .
                  </span>
                </li>
              )
            } else if (i <= 3 && i >= pageNumbers.length - 3) {
              return (
                <li key={number}
                    className={`pagination__item ${number === props.currentPage ? "pagination__item--current" : ""}`}>
                  <span onClick={() => props.paginate(number)}
                        className={`pagination__link`}>
                    {number}
                  </span>
                </li>
              )
            }
            return false
          })
        }
      </ul>
    </nav>
  );
};


Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number
};

Pagination.defaultProps = {};

export default Pagination;