import React, { Component } from "react";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import CardSlider from "../../components/module/card-slider/CardSlider";
import { getBet, selectBet, getBetDefaultsForUser, getContentPage } from "../../data/api";
import { AppContext } from "../../data/ContextProvider";
import AppSchaffeIchHeader from "../../components/module/app-schaffe-ich-header/AppSchaffeIchHeader";
import AppSchaffeIchRoom from "../../components/module/app-schaffe-ich-room/AppSchaffeIchRoom";
import SectionProductSearch from "../../components/module/section-product-search/SectionProductSearch";
import { dateDiff, dateExpired } from "../../helpers/utils";
import { CONTENT_SPIELREGELN } from "../../data/constants";
import t from "../../helpers/t9n";

class SchaffeIch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      prefs: {},
      isHeaderOpen: true,
      selectedBet: null,
      isBetChoiceTime: false,
      isActionTime: false,
    }
  }

  loadData = async () => {

    const bet = await getBet()
    const pageText = await getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)

    if (bet.bet && bet.is_action_time) {
      this.setState({
        data: bet.bet,
        prefs: bet.prefs,
        performance: bet.betrankings,
        isBetChoiceTime: false,
        isActionTime: true,
        loading: false,
        text: pageText.text,
        dateEndDiff: dateDiff(bet.prefs.end),
        dateStartToEndDiff: dateDiff(bet.prefs.end, new Date(bet.prefs.start)),
        dateExpired: dateExpired(bet.prefs.betchoice_end),
        dateActionExpired: dateExpired(bet.prefs.end)
      })
    } else if (bet.is_betchoice_time) {
      const betDefaults = await getBetDefaultsForUser()

      this.setState({
        data: betDefaults.bet_defaults,
        prefs: bet.prefs,
        selectedBet: (bet.bet) ? bet.bet.challengename : "",
        isBetChoiceTime: true,
        isActionTime: false,
        loading: false,
        text: pageText.text,
        dateEndDiff: dateDiff(bet.prefs.betchoice_end),
        dateStartToEndDiff: dateDiff(bet.prefs.betchoice_end, new Date(bet.prefs.betchoice_start)),
        dateExpired: dateExpired(bet.prefs.betchoice_end),
        dateActionExpired: dateExpired(bet.prefs.end)
      })
    } else {
      this.setState({
        data: [],
        prefs: bet.prefs,
        selectedBet: (bet.bet) ? bet.bet.challengename : "",
        isBetChoiceTime: true,
        isActionTime: false,
        loading: false,
        text: pageText.text,
        dateEndDiff: dateDiff(bet.prefs.betchoice_end),
        dateStartToEndDiff: dateDiff(bet.prefs.betchoice_end, new Date(bet.prefs.betchoice_start)),
        dateExpired: dateExpired(bet.prefs.betchoice_end),
        dateActionExpired: dateExpired(bet.prefs.end)
      })
    }
  }

  async componentDidMount() {
    await this.loadData()
  }

  handleSelectBet = async (challengeName) => {
    const selectedBet = this.state.data[challengeName - 1]

    let betData = {
      "win": selectedBet.betwin,
      "target": selectedBet.target,
      "challengename": challengeName
    }

    this.setState({
      selectedBet: challengeName
    }, async () => {
      await selectBet(betData)
      await this.loadData()
    })
  }

  handleHeader = () => {
    this.setState({
      isHeaderOpen: !this.state.isHeaderOpen
    })
  }

  render() {

    if (this.state.loading) {
      return t("global.loading")
    }

    console.log("......", this.state)

    return (
      <>
        <div className={"content app schaffeich"}>
          <AppSchaffeIchHeader handleHeader={this.handleHeader}
                               hasBetStarted={this.state.isBetChoiceTime}
                               hasCampaignStarted={this.state.isActionTime}
                               isHeaderOpen={this.state.isHeaderOpen}
                               dateEndDiff={this.state.dateEndDiff}
                               dateStartToEndDiff={this.state.dateStartToEndDiff}
                               pageText={this.state.text}
                               dateExpired={(this.state.dateExpired || this.state.dateActionExpired)}
          />
          <AppSchaffeIchRoom handleHeader={this.handleHeader}
                             hasBetStarted={this.state.isBetChoiceTime}
                             hasCampaignStarted={this.state.isActionTime}
                             bets={this.state.data}
                             prefs={this.state.prefs}
                             pageText={this.state.text}
                             performance={this.state.performance}
                             selectedBet={this.state.selectedBet}
                             isHeaderOpen={this.state.isHeaderOpen}
                             handleSelectBet={this.handleSelectBet}
                             dateEndDiff={this.state.dateEndDiff}
                             dateStartToEndDiff={this.state.dateStartToEndDiff}
                             dateExpired={this.state.dateActionExpired}
          />
          <SectionRecommendations label={t("app.award.highlights")} />

          <SectionProductSearch />

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>
        </div>
      </>
    );
  }
}

SchaffeIch.contextType = AppContext

export default SchaffeIch;
