import React, { Component } from "react";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import CardSlider from "../../components/module/card-slider/CardSlider";

import AppHerosPlaceHeader from "./AppHerosPlaceHeader";
import AppHerosPlacePerformance from "./AppHerosPlacePerformance";
import AppHerosPlaceRanking from "./AppHerosPlaceRanking";

import {
  getContentPage,
  getTransactionsForHerosPlace,
  getAppPrefs,
  getRankingLevels,
  getRankings,
  getAssetSlider
 } from "../../data/api";

 import { CONTENT_SPIELREGELN  } from "../../data/constants";

import { AppContext } from "../../data/ContextProvider";
import t from "../../helpers/t9n";

class HerosPlace extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: true
    }
  }

  async componentDidMount() {
    const appPrefs      = await getAppPrefs()
    const rankinglevels = await getRankingLevels()
    const page          = await getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)
    const transactions  = await getTransactionsForHerosPlace()
    const rankings      = await getRankings()
    const headerSlider  = await getAssetSlider('header')

    this.setState({
      transactions: transactions.transactions,
      appPrefs: appPrefs.preferences,
      rankingLevels: rankinglevels.rankinglevels,
      headerSlider: headerSlider.slider,
      rankings: rankings,
      pageText: page.text,
      loading: false
    })
  }

  render() {
    if (this.state.loading) {
      return t("global.loading")
    }

    return (
      <>
        <div className="content app jackpot">

          <AppHerosPlaceHeader
            text={this.state.pageText}
            headerSlider={this.state.headerSlider}
          />

          <AppHerosPlacePerformance
            transactions={this.state.transactions}
            currencyMode={this.state.appPrefs.currency_mode}
            start={this.state.appPrefs.start}
            end={this.state.appPrefs.end}
          />

          <AppHerosPlaceRanking
            user_transactions={this.state.transactions.user_transactions}
            ranking_levels={this.state.rankingLevels}
            currencyMode={this.state.appPrefs.currency_mode}
            ranking={this.state.rankings.ranking}
            user_rank={this.state.rankings.user_rank}
            heroTitle={this.state.appPrefs.hero_bonus_title}
            heroDescription={this.state.appPrefs.hero_bonus_desc}
            heroBonus={this.state.appPrefs.hero_bonus}
          />

          <SectionRecommendations label={t("app.award.highlights")} />

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>
        </div>
      </>
    );
  }
}

HerosPlace.contextType = AppContext

export default HerosPlace;
