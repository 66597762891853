import React from 'react'
import { useParams } from "react-router-dom";
import GloriaRewardsHome from './Pages/GloriaRewardsHome';

const GloriaRewards = () => {
  let { lang } = useParams();

  return (
    <div className="gloria-rewards gloria-rewards__page">
      <GloriaRewardsHome lang={lang} />
    </div>
  )
}

export default GloriaRewards;
