import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import Icon from "../../atom/icons/Icon";
import Selectfield from "../../atom/selectfield/Selectfield";
import Textfield from "../../atom/textfield/Textfield";
import TextfieldGroup from "../../atom/textfield-group/TextfieldGroup";
import t, { countriesOptions } from "../../../helpers/t9n";

class SectionProfileAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isEditMode: false,
      editAddress: {},
    };
  }

  handleModal() {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
    });
  }

  resetModal() {
    this.setState({
      isModalVisible: false,
      isEditMode: false,
      editAddress: {},
    });
  }

  handleEditAddress(address) {
    this.setState({
      editAddress: address,
      isEditMode: true,
      isModalVisible: true,
    });
  }

  render() {
    const data = this.props.data;
    const showAddAddress = data.length === 0;

    const editAddress = this.state.editAddress;

    const countries = countriesOptions()
    const selectedCountry = countries[data.country_code || "DE"]

    return (
      <div className="profile-address__section">
        <h2>{t("app.profile.all.adresses")}</h2>
        <div className="profile-address__container">
          {showAddAddress && (
            <div
              className={"profile-address__item profile-address__add-new"}
              onClick={() => this.handleModal()}
            >
              <div className={"profile-address__content"}>
                <span className={"profile-address__icon"}>
                  <Icon icon={"adding"} />
                </span>
                <b>{t("app.profile.add.adress")}</b>
              </div>
            </div>
          )}

          {data.map((adr) => {
            return (
              <div className={"profile-address__item"} key={adr.id}>
                <div className={"profile-address__content"}>
                  <b>
                    {adr.salutation} {adr.first_name} {adr.last_name}
                  </b>
                  <span>{adr.company}</span>
                  <span>{adr.street}</span>
                  <span>
                    {adr.zip} {adr.city}
                  </span>
                  <br />
                  <span>
                    {t("app.profile.adress.phone")} {adr.phone}
                  </span>
                  <span>
                    {t("app.profile.adress.mail")} {adr.email}
                  </span>
                </div>
                <span className={"profile-address__actions"}>
                  <Button
                    type={"noOutline"}
                    text={t("app.profile.adress.settings")}
                    textColor={"black"}
                    onClick={() => this.handleEditAddress(adr)}
                  />
                  <span>|</span>
                  <Button
                    type={"noOutline"}
                    text={t("app.profile.adress.remove")}
                    textColor={"black"}
                    onClick={() => this.props.handleDeleteAddress(adr.id)}
                  />
                </span>
              </div>
            );
          })}
        </div>

        {this.state.isModalVisible && (
          <div className={"profile-address__add-new-modal"}>
            <div className={"profile__personal-data"}>
              <span
                className={"profile-address__add-new-modal-close"}
                onClick={() => this.resetModal()}
              >
                <Icon icon={"close"} />
              </span>
              {this.state.isEditMode ? (
                <h2>{t("app.profile.adress.change")}</h2>
              ) : (
                <h2>{t("app.profile.adress.new")}</h2>
              )}
              <form
                name={"registration-form"}
                onSubmit={(e) => {
                  e.preventDefault();
                  this.handleModal();
                  if (this.state.isEditMode) {
                    this.props.handleUpdateAddress(editAddress.id);
                  } else {
                    this.props.handleCreateAddress(e);
                  }
                }}
              >
                <div className="login__columns--wrapper login__columns--wrapper--step2">
                  <div className="login__row">
                    <div className="login__col">
                      <Selectfield
                        label={t("app.salutation")}
                        options={{
                          herr: t("app.mr"),
                          frau: t("app.ms"),
                        }}
                        name="salutation"
                        defaultValue={
                          editAddress.salutation
                            ? editAddress.salutation.toLowerCase()
                            : "herr"
                        }
                        onSelect={(e) =>
                          this.props.handleChange(
                            e,
                            editAddress.id,
                            this.state.isEditMode
                          )
                        }
                        isRequired={true}
                        placeholder={t("app.salutation")}
                      />
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.firstname")}
                        placeholder={t("app.firstname.placeholder")}
                        value={editAddress.first_name}
                        isRequired={true}
                        name="first_name"
                        handleChange={(e) =>
                          this.props.handleChange(
                            e,
                            editAddress.id,
                            this.state.isEditMode
                          )
                        }
                      />
                    </div>
                    <div className="login__col">
                      <Textfield
                        label={t("app.lastname")}
                        isRequired={true}
                        placeholder={t("app.lastname.placeholder")}
                        value={editAddress.last_name}
                        name="last_name"
                        handleChange={(e) =>
                          this.props.handleChange(
                            e,
                            editAddress.id,
                            this.state.isEditMode
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.street")}
                        placeholder={t("app.street.placeholder")}
                        name="street"
                        value={editAddress.street}
                        isRequired={true}
                        handleChange={(e) =>
                          this.props.handleChange(
                            e,
                            editAddress.id,
                            this.state.isEditMode
                          )
                        }
                      />
                    </div>
                    <div className="login__col">
                      <TextfieldGroup>
                        <Textfield
                          label={t("app.plz")}
                          placeholder={t("app.plz.placeholder")}
                          name="zip"
                          value={editAddress.zip}
                          isRequired={true}
                          handleChange={(e) =>
                            this.props.handleChange(
                              e,
                              editAddress.id,
                              this.state.isEditMode
                            )
                          }
                        />
                      </TextfieldGroup>
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.city")}
                        placeholder={t("app.city.placeholder")}
                        name="city"
                        value={editAddress.city}
                        isRequired={true}
                        handleChange={(e) =>
                          this.props.handleChange(
                            e,
                            editAddress.id,
                            this.state.isEditMode
                          )
                        }
                      />
                    </div>
                    <div className="login__col">
                      <Selectfield
                        label={t("app.country")}
                        options={countries}
                        name="country_code"
                        defaultValue={selectedCountry}
                        isRequired={true}
                        placeholder={t("app.country")}
                        onSelect={
                          (e) =>
                            this.props.handleChange(
                              e,
                              editAddress.id,
                              this.state.isEditMode
                            )
                        }
                      />
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.email")}
                        placeholder={t("app.email.placeholder")}
                        handleChange={(e) =>
                          this.props.handleChange(
                            e,
                            editAddress.id,
                            this.state.isEditMode
                          )
                        }
                        isRequired={true}
                        value={editAddress.email}
                        name="email"
                        inputType="email"
                      />
                    </div>
                    <div className="login__col">
                      <Textfield
                        label={t("app.phone")}
                        placeholder={t("app.phone.placeholder")}
                        name="phone"
                        value={editAddress.phone}
                        handleChange={(e) =>
                          this.props.handleChange(
                            e,
                            editAddress.id,
                            this.state.isEditMode
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="login__row">
                    <div className="login__col">
                      <Textfield
                        label={t("app.handy")}
                        placeholder={t("app.handy.placeholder")}
                        name="mobile"
                        value={editAddress.mobile}
                        isRequired={false}
                        handleChange={(e) =>
                          this.props.handleChange(
                            e,
                            editAddress.id,
                            this.state.isEditMode
                          )
                        }
                      />
                    </div>
                    <div className="login__col">
                      <Textfield
                        label={t("app.customer")}
                        placeholder={t("app.customer.placeholder")}
                        name="company"
                        value={editAddress.company}
                        handleChange={(e) =>
                          this.props.handleChange(
                            e,
                            editAddress.id,
                            this.state.isEditMode
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <span className={"profile-header__actions"}>
                  <Button
                    type={"primary"}
                    text={`${
                      this.state.isEditMode
                        ? t("app.profile.adress.changes.safe")
                        : t("app.profile.adress.new.safe")
                    }`}
                    textColor={"white"}
                  />
                </span>
              </form>
            </div>
          </div>
        )}
        <div
          className={`main-menu__overlay ${
            this.state.isModalVisible ? "main-menu__overlay--visible" : ""
          }`}
        ></div>
      </div>
    );
  }
}

SectionProfileAddress.contextType = AppContext;
export default SectionProfileAddress;
