import React, { Component } from 'react'
import { getContentPage } from '../../../data/api';
import { CONTENT_SPIELREGELN } from '../../../data/constants';
import { AppContext } from '../../../data/ContextProvider';
import t from '../../../helpers/t9n';
import Button from '../../atom/button/Button';
import { withRouter } from "react-router-dom";

class AppAkkordeonHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      loading: true
    };
  }

  handleButton = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  async componentDidMount() {
    const page = await getContentPage(CONTENT_SPIELREGELN, this.context.getUserData().project_id, this.context.getUserData().app_id)

    this.setState({
      pageText: page.text,
      loading: false
    })
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    const text = this.state.pageText

    return (
      <div className="header__akkordeon">
        <div 
          className={this.state.isOpen 
            ? "header__akkordeon-inner header__akkordeon-inner--active" 
            : "header__akkordeon-inner"
          }>
          <div className="header__akkordeon__left">
            <h2>Herzlich Willkommen {this.context.getNameOfUser()}</h2>
            
            <p
              dangerouslySetInnerHTML={{
                __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
              }}>
            </p>
          </div>
          
        </div>

        <div className="header__akkordeon__right">
          {/* <h4>Ihre Applikationen</h4> */}
          
          <div className="header__akkordeon__box">
            <h3>{text.p33_header_content_headline}</h3>
            <Button
              type="primary"
              text={t("global.rules")}
              textColor="white"
              icon="arrow"
              additionalClass="button--icon--right"
              onClick={() => {
                this.props.history.push(`/${window.currentLang}/rules`)
              }}
            />

            {/* <h3>Salesattack</h3>
            <Button
              type="primary"
              text="Zu den Spielregeln"
              textColor="white"
              icon="arrow"
              additionalClass="button--icon--right"
            />

            <h3>Prämienshop</h3>
            <Button
              type="primary"
              text="Zu den Spielregeln"
              textColor="white"
              icon="arrow"
              additionalClass="button--icon--right"
            /> */}
          </div>
        </div>

        <div className="header__akkordeon__menu">
          <Button
            type={this.state.isOpen ? "secondary" : "primary"}
            text={this.state.isOpen ? "Infos schließen" : "Weitere Infos"}
            textColor={this.state.isOpen ? "white" : "black"}
            onClick={() => {this.handleButton()}}
            icon="arrow"
            additionalClass={this.state.isOpen ? "button--icon-up" : "button--icon-down"}
          />
        </div>
      </div>
    )
  }
}

AppAkkordeonHeader.contextType = AppContext;
export default withRouter(AppAkkordeonHeader);