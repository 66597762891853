import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { Link } from "react-router-dom";
import { getAllNews } from "../../../data/api";
import { formatDate } from "../../../lib/lib";
import t from "../../../helpers/t9n";

class AppHappyPraemienNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      news: [],
      loading: true
    }
  }

  async componentDidMount() {
    const news = await getAllNews()

    this.setState({
      news: news.news,
      loading: false
    })
  }

  render() {
    const { news, loading } = this.state

    if (loading) {
      return t("global.loading")
    }

    return (
      <>
      <div className={`happy-praemien-news__section`}>
        <div className={"happy-praemien-news__header"}>
          <h2>{t("app.news.award.club")}</h2>
        </div>

        <div className={"happy-praemien-news__content"}>
          <div className={"happy-praemien-news__slider"}>
            {news.map(obj => (
              <div
                key={obj.id}
                className={`happy-praemien-news__box ${!obj.has_read ? "happy-praemien-news__box--new" : ""}`}
              >
                {!obj.has_read &&
                <div className={"happy-praemien-news__new"}>
                  <h3>{t("app.new")}</h3>
                </div>
                }
                <span className={"happy-praemien-news__box-date"}>
                  {formatDate(obj.updated_at, false)}
                </span>
                <h3>{obj.subject}</h3>
                <Link to={`/${window.currentLang}/news/`}>
                  <Button text={t("app.read.on")} type={"primary"} isClubButton={true}
                          textColor={`${obj.has_read ? "white" : "black"}`}
                          backgroundColor={`${!obj.has_read ? "bg-white" : "bg-black"}`} />
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className={"happy-praemien-news__actions"}>
          <Link to={`/${window.currentLang}/news`}>
            <Button text={t("app.read.all.news")} type={"secondary"} textColor={"white"} />
          </Link>
        </div>
      </div>
    </>
    );
  }
}

export default AppHappyPraemienNews;
