import React, { Component } from "react";
import SectionRecommendations from "../../components/module/section-recommendations/SectionRecommendations";
import CardSlider from "../../components/module/card-slider/CardSlider";
import AppJackpotHeader from "../../components/module/app-jackpot-header/AppJackpotHeader";
import t from "../../helpers/t9n";

class Jackpot extends Component {
  render() {
    return (
      <>
        <div className={"content app jackpot"}>

          <AppJackpotHeader />
          <SectionRecommendations label={t("app.award.highlights")} />

          <div className={"section__card-slider"}>
            <CardSlider sliderLabel={t("app.award.news")} isNewProductsSlider={true} />
          </div>
        </div>
      </>
    );
  }
}

export default Jackpot;
