import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import { withRouter } from "react-router-dom";

class AppHighperformanceRanking extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      isInfoVisible: false
    }
  }

  toggleInfo = () => {
    this.setState({
      isInfoVisible: !this.state.isInfoVisible
    })
  }

  render() {
    const { data } = this.state
    const { places } = data
    const group = data.segment

    return (
      <>
      <div className={`highperformance-placement__section`}
           ref={this.props.rankingRef}>
        <div className={"highperformance-placement__header"}>
          <h2>RANKING - MEINE PLATZIERUNG</h2>
        </div>
        {group && group.title && (
          <div className={"highperformance-placement__header-sub"}>
            <div>Sie gehören zur Gruppe</div>
            <div><h2>{group.title}</h2></div>
          </div>
        )}
      </div>
      <div className={`highperformance-ranking__section`}>
        <div className={"highperformance-ranking__content"}>
          <div className={"highperformance-ranking__list"}>
            <div className={"highperformance-ranking__list-header"}>
              <ul>
                <li>Rang</li>
                <li>Name</li>
                <li>Rankingpunkte</li>
                <li>Zielerreichung</li>
              </ul>
            </div>
            <div className={"highperformance-ranking__list-ranks"}>
              <ul>
                {places.map((entry, i) => (
                  <li key={entry.place + "_" + i + "_" + entry.amount}>
                    <div className={`highperformance-ranking__list-entry
                                    ${entry.place <= 3 && "highperformance-ranking__list-entry--top"}
                                    ${entry.place === data.current_place && "highperformance-ranking__list-entry--current"}`}>
                      <span>{entry.place}</span>
                      <span>{(entry.user && this.context.getUserData().uid === entry.user_id) ? <>{entry.user.first_name} {entry.user.last_name}</> : "-"}</span>
                      <span>{Number(entry.amount).toLocaleString()}</span>
                      <span>{this.props.isPointsMode ? entry.amount + " PKT" : entry.percent + " %"}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={"highperformance-ranking__actions"}>
            <Button type={"secondary"} textColor={"black"} text={"Wertungskriterien"}
                    isClubButton={true}
                    onClick={() => {
                      this.props.history.push(`/${window.currentLang}/wertungskriterien`)
                    }} />
          </div>
        </div>
      </div>
      </>
    );
  }
}

AppHighperformanceRanking.contextType = AppContext

export default withRouter(AppHighperformanceRanking);
