import React, { Component } from "react";

import Button from "../../atom/button/Button";
import { Link } from "react-router-dom";
import CardSlider from "../card-slider/CardSlider";
import { getHighlights } from "../../../data/api";
import { SHOP_HIGHLIGHTS } from "../../../data/constants"
import t from "../../../helpers/t9n";

class SectionRecommendations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      cards: [],
    };
    this.shopURL = `/${window.currentLang}/shop`
  }

  async componentDidMount() {
    const highlights = await getHighlights();

    this.setState({
      cards: highlights.products.slice(0, 3),
      loading: false
    })

    if (this.props.shopParams === SHOP_HIGHLIGHTS) {
      this.shopURL = `/${window.currentLang}/shop?highlights=1`
    }
  }

  render() {
    if (this.state.loading) {
      return t("global.loading")
    }

    const cards = this.state.cards;

    if (cards.length > 0) {
      return (
        <div className="recommendations__section">
          <div className="recommendations">
            <CardSlider
              productType="standard"
              itemAmount={3}
              cards={cards}
              showButtonLink={false}
              sliderLabel={t("global.recommendation")}
              isNewProductSlider={false}
              isRecommendationsSlider={true}
              hasWishAction={false}
            />
          </div>

          <span className="recommendations__actions">
            <Link to={this.shopURL}>
              <Button type="secondary" textColor="white" text={t("global.award.overview")} />
            </Link>
          </span>
        </div>
      );
    } else {
      return (
        <div></div>
      )
    }
  }
}


export default SectionRecommendations;
