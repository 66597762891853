import React from "react";
import Button from "../../components/atom/button/Button";
import withRouter, { Link } from "react-router-dom";
import notFound from "../../assets/lotties/404-page/anim--404.json";
import ControlledLottie from "../../components/atom/lottie/ControlledLottie";
import t from "../../helpers/t9n";

const NotFound = (props) => {

  return (
    <>
      <div className={"not-found"}>
        <div className={"not-found__container"}>
          <div className={"not-found__lottie"}>
            <ControlledLottie animationData={notFound} loop={false} isStopped={false} isPaused={false} />
          </div>
          <div className="not-found__content">
            <h1>{t("page.404.headline")}</h1>
            <h2>{t("page.404.subline")}</h2>
            <p>{t("page.404.info")}</p>
            {props.isSubDomainError &&
            <>
              <h4>{t("page.404.domain.headline")}</h4>
              <p>{t("page.404.domain.info")}</p>
              <span><span>www.</span><span>firma.</span><span>aktionsname.</span>de</span>
            </>
            }
            {!props.isSubDomainError &&
            <div className="not-found__actions">
              <Link to={"/"}>
                <Button icon={"arrow"} text={t("page.404.action.home")} type={"secondary"} />
              </Link>
              <Link to={"/contact"}>
                <Button icon={"news-new"} text={t("page.404.action.contact")} type={"primary"} textColor={"white"}
                        backgroundColor={"bg-black"} />
              </Link>
            </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
