import React, { Component } from "react";
import Button from "../../atom/button/Button";
import ProgressCircle from "../../element/progress-circle/ProgressCircle";
import { dateDiff, dateExpired } from '../../../helpers/utils'
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti";
import Icon from "../../atom/icons/Icon";
import { AppContext } from "../../../data/ContextProvider";
import { Link, withRouter } from "react-router-dom";
import { getAssetSlider } from "../../../data/api";

class AppHighperformancePerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startNr: 0,
      endNr: ~~this.props.performance.percent,
      data: this.props.performance,
      dateEndDiff: dateDiff(this.props.performance.end),  //FIXME !!!!!!!!!!!!!!!
      dateStartToEndDiff: dateDiff(this.props.performance.end, new Date(this.props.performance.start)), //FIXME !!!!!!!!!!!!!!!
      dateExpired: dateExpired(this.props.performance.end), //FIXME !!!!!!!!!!!!!!!
      reachedGoal: false,
      text: this.props.pageText,
      headerSlider: []
    }
    this.timerHandle = null
    this.loaderHandle = null

    this.loaderIndex = 0;
    this.endingNumber = 0;
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
      loading: false
    })
  }

  componentDidMount() {
    let loadingBarValues = []

    if (this.percent) {
      if (Math.ceil(Math.log10(this.state.endNr + 1)) >= 3) {
        //3 digits = over 100
        const hundrets = (this.state.endNr / 100)
        for (let i = 1; i <= hundrets; i++) {
          loadingBarValues.push(100)
        }
        loadingBarValues.push(Math.floor(this.state.endNr - (Math.floor(hundrets) * 100)))
        this.loadUp(loadingBarValues, 0)
      } else {
        this.loadUp([this.state.endNr], 0)
      }
    }

    this.loadData()
  }

  loadUp = (values, count = 0) => {
    this.loader.style.width = count + "%";

    if (this.loaderIndex > 0) {
      this.percent.innerHTML = (count + (this.loaderIndex * 100)) + "%";
    } else {
      this.percent.innerHTML = count + "%";
    }

    this.endingNumber = values[this.loaderIndex]

    if (count < this.endingNumber) {
      this.loaderHandle = setTimeout(() => this.loadUp(values, count + 1), 20)
    } else {

      if (typeof values[this.loaderIndex + 1] !== 'undefined') {
        this.loader.style.width = 0 + "%";
        this.setState({
          reachedGoal: true
        })
        this.loaderIndex += 1;
        this.loadUp(values, 0)
      }
    }
  }

  componentWillUnmount() {
    this.timerHandle && clearTimeout(this.timerHandle)
    this.loaderHandle && clearTimeout(this.loaderHandle)
  }

  countUp = (count) => {
    this.percent.innerHTML = count + "%";

    if (count < this.state.endNr) {
      this.timerHandle = setTimeout(() => this.countUp(count + 1), 10)
    }
  }

  render() {
    const data = this.state.data

    return (
      <div className={`highperformance-performance__section`}>
        <div className={"app-header__actions app-header__actions--mobile"}>
          <Button type={"secondary"}
                  text={`${this.props.isHeaderOpen ? "Weniger Informationen" : "Mehr Informationen"}`}
                  textColor={"black"} backgroundColor={"bg-white"}
                  isClubButton={true}
                  onClick={() => this.props.handleHeader()} />
        </div>
        <div className={"highperformance-performance__content"}>

          <div className={"highperformance-header__center"}>
            <span className={"highperformance-header__caesar"}>
              <Icon icon={"caesar-thing"} />
            </span>
            <span className={"highperformance-header__caesar"}>
              <Icon icon={"caesar-thing"} />
            </span>

            <div className={"highperformance-header__center-header"}>
              <span className={"highperformance-header__circle"}>
                <Icon icon={"3-stars-gold"} />
              </span>
              <span className={"highperformance-header__circle"}>
                <Icon icon={"3-stars-gold"} />
              </span>
              <span className={"highperformance-header__circle"}>
                <Icon icon={"3-stars-gold"} />
              </span>
              <h3>Ihr aktueller Premium-Rang</h3>
            </div>
            <div className={"highperformance-header__center-rank"}>
              <Icon icon={"star"} />
              <h1>{data.current_place}</h1>
              <Icon icon={"star"} />
            </div>
          </div>

          <div className={"highperformance-header__right"}>
            <div className={"highperformance-header__information--mobile"}>
              <h3>Hallo {this.context.getNameOfUser()}</h3>
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.text.p33_upper_content_text ? this.state.text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                }}>
              </p>
            </div>
            <div>
              <Link to={{ pathname: `/${window.currentLang}/wertungskriterien`, hash: '#wertung' }}>
                <Button isClubButton={true} isGold={true} type={"club-link"} backgroundColor={"bg-white"}
                        textColor={"black"}
                        text={"Wertungskriterien"} />
              </Link>
              <Button isClubButton={true} isGold={true} type={"club-link"} backgroundColor={"bg-white"}
                      textColor={"black"}
                      text={"Ranking anzeigen"} onClick={() => {
                this.props.history.push(`/${window.currentLang}/ranking`)
              }} />
              <Button isClubButton={true} isGold={true} type={"club-link"} backgroundColor={"bg-white"}
                      textColor={"black"}
                      text={"Gewinnerreise"} onClick={() => {
                this.props.history.push(`/${window.currentLang}/gewinnerreise`)
              }} />
            </div>
          </div>

          {data.performance_mode === "target_achievement" &&
          <div className={"highperformance-performance__box highperformance-performance__box--percent"}>
            <h3>Deine Zielerreichung</h3>
            <div className={"highperformance-performance__box-container"}>
              <div>
                <div
                  className={`highperformance-performance__loader ${this.state.reachedGoal ? "highperformance-performance__loader--second" : ""}`}>
                  <span ref={loader => (this.loader = loader)}
                        className={"highperformance-performance__loader-indicator"} />
                </div>
                <span className={"highperformance-performance__box-percent"} ref={percent => (this.percent = percent)}>
                  0%
                </span>

                {this.state.endNr >= 100 &&
                <span className={"neukunden-performance__congrats"}>
                  <UncontrolledLottie animationData={confetti} />
                </span>
                }
              </div>
              {(data.remaining_target > 0) && (
                <p>Auf geht’s, Dir fehlen noch <b>{Number(data.remaining_target).toLocaleString()} Verträge</b> zur nächsten Gewinnstufe.</p>)}
              {(data.percent > 100) && (
                <p>Herzlich Glückwunsch, Du hast deine Zielvorgabe erreicht! Starke Leistung, weiter so!</p>) || (
                <br />)}
            </div>
          </div>
          }

          <div className={"highperformance-performance__box highperformance-performance__box--timer"}>
            <h3>Restliche Zeit bis zum Aktionsende</h3>
            <span>
              <ProgressCircle
                value={this.state.dateEndDiff.days}
                maxValue={this.state.dateStartToEndDiff.days}
                isCountDown={true}
                text={"Tage"}
                expired={this.state.dateExpired}
              />
              <ProgressCircle
                value={this.state.dateEndDiff.hours}
                maxValue={24}
                text={"Stunden"}
                expired={this.state.dateExpired}
              />
              <ProgressCircle
                value={this.state.dateEndDiff.minutes}
                maxValue={60}
                text={"Minuten"}
                expired={this.state.dateExpired}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

AppHighperformancePerformance.contextType = AppContext

export default withRouter(AppHighperformancePerformance);
