import React from "react";
import { useParams } from "react-router-dom";
import SalesAttack from "./SalesAttack/SalesAttack";

const SalesAttackPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <SalesAttack lang={lang} />
    </>
  )
}

export default SalesAttackPage;
