import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import PropTypes from "prop-types";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import planes from "../../../assets/lotties/sales-push/anim--salespush-race";
import { withRouter } from "react-router-dom";
import t from "../../../helpers/t9n";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import { getAssetSlider } from "../../../data/api";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";

class AppSalesPushHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      headerSlider: []
    }
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    const text = this.props.text

    return (
      <>
        {this.state.headerSlider.length > 0 &&
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        }
        
        <div
          className={`app-header__section salespush-header__section ${this.props.isHeaderOpen ? "app-header__section--open" : ""}`}>
          <video loop autoPlay data-object-fit="cover">
            <source
              src={`${process.env.PUBLIC_URL}/videos/SalesPush/anim--sp-red-dessert-bg.mp4`}
              type="video/mp4" />
            {t("app.video.no.support")}
          </video>
          <div className={"app-header__content"}>
            <div className={"app-header__left"}>
              <span className={"app-header__heading"}>
                <div>
                  <span>
                    <Icon icon={"app-salespush"} />
                  </span>
                  <h1>{text.p33_header_content_headline}</h1>
                </div>
                <h2>{text.p33_header_content_subline}</h2>
              </span>

              <p
                dangerouslySetInnerHTML={{
                  __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                }}>
              </p>

              {/*<h3>Willkommen beim SalesPush – dem Highspeed-Incentive</h3>*/}
              {/*<p>Anschnallen und Gas geben ist angesagt! Ihre Vorjahresleistung ist Ihre aktuelle Bestleistung, die es bis zum Ende dieser Aktion zu schlagen gilt. Also, je stärker Sie Ihre Vorjahresleistung toppen, umso mehr Prämienpunkte landen auf Ihrem persönlichen Konto. Am Ende des Rennens können Sie dann alle Punkte in tolle Prämien eintauschen. In unserem SalesPush-Prämienkatalog haben. Sie die Wahl aus über 3.000 phantastischen Prämien. Viel Spaß!</p>*/}
              <span className={"app-header__actions"}>
                {this.props.isHeaderOpen ?
                  <Button type={"link"} text={t("global.close.info")} textColor={"white"} icon={"arrow"}
                          onClick={() => this.props.handleHeader()} />
                  :
                  <Button type={"link"} text={t("global.open.info")} textColor={"white"} icon={"arrow"}
                          onClick={() => this.props.handleHeader()} />
                }
                <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                        onClick={() => {
                          this.props.history.push(`/${window.currentLang}/rules`)
                        }} />
              </span>
            </div>
          </div>
          <span className={"app-header__box"}>
            <UncontrolledLottie animationData={planes} />
          </span>
        </div>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}

AppSalesPushHeader.propTypes = {
  handleHeader: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
};
AppSalesPushHeader.defaultProps = {
  isHeaderOpen: false,
};

AppSalesPushHeader.contextType = AppContext

export default withRouter(AppSalesPushHeader);
