import React, { Component } from "react";
import AppPromoBoosterHeader from "../../components/module/app-promobooster-header/AppPromoBoosterHeader";
import { getContentPage } from "../../data/api";
import { CONTENT_SPIELREGELN } from "../../data/constants";

class PromoBooster extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isHeaderOpen: false
    }
  }

  handleHeader = () => {
    this.setState({
      isHeaderOpen: !this.state.isHeaderOpen
    })
  }

  render() {

    return (
      <>
        <div className={"content app promobooster"}>
          <AppPromoBoosterHeader handleHeader={this.handleHeader} isHeaderOpen={this.state.isHeaderOpen} />
        </div>
      </>
    );
  }
}

export default PromoBooster;
