import React, { Component } from "react";

import Logo from "../../../components/atom/logo/Logo";
import Button from "../../../components/atom/button/Button";


import { logoutManager } from "../../../lib/lib";
import { API_HOST } from "../../../data/config";

import { AppContext } from "../../../data/ContextProvider";
import { withRouter } from "react-router-dom";


class Navbar extends Component {

  handleLoginLogout() {
    logoutManager()
  }

  handleProfile = (path) => {
    this.props.history.push(`/${window.currentLang}/` + path)
  }

  render() {

    const project_id = this.context.getUserData().project_id ? this.context.getUserData().project_id : this.context.getProjectData().id

    return (
      <>
        <nav className="navbar__container navbar__container--black navbar__container--compl">
          <div className={"navbar__header"}>
            <Logo image={`${API_HOST}/asset/logo?pid=${project_id}`} />
          </div>

          <div className="navbar__user linked">
            <Button
              onClick={() => this.handleProfile("compliance-tool/profile")}
              type="noOutline"
              additionalClass="navbar__logout-btn"
              textColor="white"
              icon="single-neutral-actions"
              text={this.context.getNameOfUser()}
              additionalClass="button--icon--right"
            />

            <Button
              onClick={() => this.handleLoginLogout()}
              type="noOutline"
              additionalClass="navbar__logout-btn"
              textColor="white"
              icon="logout"
            />
          </div>
        </nav>
      </>
    );
  }
}


Navbar.contextType = AppContext;
export default withRouter(Navbar);
