import React, { Component } from "react";

import Content from "../Components/Content";
import Header from "../Components/Header";
import Stepper from "../Components/Stepper";

import { getTextForKey } from "../../../data/ct-api";
import t from "../../../helpers/t9n";
import { AppContext } from "../../../data/ContextProvider";

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isDisabledActive: true
    }
  }

  async componentDidMount() {
    try {
      const pageText = await getTextForKey("privacy")

      this.setState({
        pageText: pageText,
        loading: false,
      })
    } catch (error) {
      this.setState({
        pageText: "",
        loading: false,
      })
      console.log(error)
    }
  }

  handleCheckbox = (e) => {
    this.setState({ isDisabledActive: !this.state.isDisabledActive });
  };

  render() {

    if (this.state.loading) return t("global.loading");

    const showCheckbox = !this.props.footer_mode
    const nextButton = (!this.props.footer_mode) ? "Weiter zur Verkäufer Registrierung" : null
    const prevButton = (!this.props.footer_mode) ? "Zurück" : null

    return (
      <div className="compl-tool">

        <Header headline="" />

        {!this.props.footer_mode &&
          <Stepper activeStep="4" />
        }

        {this.props.footer_mode &&
          <div className="spacer"></div>
        } 

        <Content
          title={this.state.pageText.headline}
          text={this.state.pageText.body}
          nextButton={nextButton}
          nextButtonPath={`/${window.currentLang}/compliance-tool/profile`}
          prevButton={prevButton}
          prevButtonPath={`/${window.currentLang}/compliance-tool/conditions`}
          showCheckbox={showCheckbox}
          checkboxLabel="Datenschutzbestimmungen bestätigen"
          handleCheckbox={this.handleCheckbox}
          isDisabledActive={this.state.isDisabledActive}
        />
      </div>

    )
  }
}

Privacy.contextType = AppContext;
export default Privacy;
