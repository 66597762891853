import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import CounterSpawner from "../../element/counter-spawner/CounterSpawner";
import trophy from "../../../assets/lotties/team-trophy/anim--trophy";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import { formatShortDate } from "../../../helpers/utils";
import t from "../../../helpers/t9n";
import { getAssetSlider } from "../../../data/api";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";
import { AppContext } from "../../../data/ContextProvider";

class AppTeamTrophyHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamTrophy: 0,
      headerSlider: []
    }
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    const text = this.props.pageText

    return (
      <>
        {this.state.headerSlider.length > 0 &&
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        } 

        <>
          <div className={`app-header__section ${this.props.isHeaderOpen ? "app-header__section--open" : ""}`}>
            <video loop autoPlay>
              <source
                src={`${process.env.PUBLIC_URL}/videos/Neukunden/anim--bg-blue-mountains.mp4`}
                type="video/mp4" />{t("app.video.no.support")}
            </video>
            <div className={"app-header__content"}>
              <div className={"app-header__left"}>
                <span className={"app-header__heading"}>
                  <div>
                    <span>
                      <Icon icon={"app-teamtrophy"} />
                    </span>
                    <h1>{text.p33_header_content_headline}</h1>
                  </div>
                  <h2>{text.p33_header_content_subline}</h2>
                </span>

                <p
                  dangerouslySetInnerHTML={{
                    __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                  }}>
                </p>

                <span className={"app-header__actions"}>
                  <Button type={"link"} text={`${this.props.isHeaderOpen ? t("global.less.info") : t("global.open.info")}`}
                          textColor={"white"} icon={"arrow"} onClick={() =>
                    this.props.handleHeader()
                  } />
                  <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                          onClick={() => {
                            this.props.history.push(`/${window.currentLang}/rules`)
                          }} />
                </span>
              </div>
              <span className={"teamTrophy-counter__icon"}>
                <UncontrolledLottie animationData={trophy} />
              </span>
              <div className={"app-header__right"}>
                <div className={"teamTrophy-counter"}>
                    <h3>{t("app.our.sales.target")}</h3>
                    <h3>{t("app.until.the")}{formatShortDate(this.props.appPrefs.end)}</h3>
                    <span className={"teamTrophy-counter__wrapper"}>
                        <CounterSpawner amount={this.props.appPrefs.target} type={"light"} />
                      <h5>{t("app.currency")}</h5>
                    </span>

                    <h5>{t("app.current.sales")}</h5>
                    <div className={"teamTrophy-counter__bank"}>
                        <h2><b>{Number(this.props.appPrefs.amount).toLocaleString()} {t("app.currency.icon")}</b></h2>
                    </div>
                  </div>
                </div>
            </div>

          </div>
          <span className={"app-header__actions app-header__actions--mobile"}>
            <Button type={"secondary"} text={t("app.information")} textColor={"black"} backgroundColor={"bg-white"}
                    onClick={() => this.props.handleHeader()}
            />
          </span>
        </>

        {this.context.user.welcome_tool_enabled &&
          <AppAkkordeonHeader />
        }
      </>
    );
  }
}


AppTeamTrophyHeader.contextType = AppContext
export default withRouter(AppTeamTrophyHeader);
