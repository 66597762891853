import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Textfield from "../../../components/atom/textfield/Textfield";
import Button from "../../../components/atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import t from "../../../helpers/t9n";

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: this.props.resetToken
    };
  }

  render() {
    return (
      <div className={"login__container--right"}>
        <h2>{t("app.new.password_short")}</h2>
        <form name="new-pass-form" className="new-pass-form" onSubmit={(e) => this.props.submitFunc(e, 4)}>
          <Textfield label={t("app.email_short")}
                     isRequired={true}
                      inputType="email"
                     handleChange={(e) => this.props.handleChange(e)}
                     name="reset_new_password_email"
                     placeholder={"Mustermann@email.de"} />
          <Textfield label={t("app.new.password")}
                     name="reset_new_password"
                     isRequired={true}
                     handleChange={(e) => this.props.handleChange(e)}
                     placeholder="Passwort"
                     inputType="password"
                     errorMsg={this.props.error} />
          <Textfield label={t("app.new.password.repeat")}
                     name="reset_new_password_repeat"
                     isRequired={true}
                     handleChange={(e) => this.props.handleChange(e)}
                     placeholder="Passwort"
                     inputType="password"
                     errorMsg={this.props.error} />
          <Button type="primary" textColor="white" text={t("app.new.password_save")} />
        </form>
        <p className="login__help-text">
          {t("login.password_policy")}
        </p>

        <span className="login__links">
          <a href={`/${window.currentLang}`}>{t("menu.home")}</a>
          <a href={`/${window.currentLang}/privacy`}>{t("login.data.protection")}</a>
          <a href={`/${window.currentLang}/imprint`}>{t("login.imprint")}</a>
        </span>
      </div>
    );
  }
}

NewPassword.contextType = AppContext;

export default withRouter(NewPassword);
