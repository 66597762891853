import React, { Component } from "react";
import Button from "../../atom/button/Button";
import { Link } from "react-router-dom";
import { getAllNews } from "../../../data/api";
import { formatDate } from "../../../lib/lib";

class AppHighperformanceNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      loading: true
    }
  }

  componentDidMount() {
    const news = getAllNews()
    news.then(news => {
      this.setState({
        news: news.news,
        loading: false
      })
    })
  }

  render() {
    const { news, loading } = this.state

    if (loading)
      return "Loading.."

    return (
      <>
      <div className={`highperformance-news__section`}>
        <div className={"highperformance-news__header"}>
          <h2>News aus dem Prämien-Club</h2>
        </div>

        <div className={"highperformance-news__content"}>
          <div className={"highperformance-news__slider"}>
            {news.map(obj => (
              <div
                key={obj.id}
                className={`highperformance-news__box ${!obj.has_read ? "highperformance-news__box--new" : ""}`}
              >
                {!obj.has_read &&
                <div className={"highperformance-news__new"}>
                  <h2>NEU</h2>
                </div>
                }
                <span className={"highperformance-news__box-date"}>
                  {formatDate(obj.updated_at, false)}
                </span>
                <h3>{obj.subject}</h3>
                <Link to={`/${window.currentLang}/news/`}>
                  <Button text={"weiterlesen"} type={"primary"} isClubButton={true}
                          textColor={`${obj.has_read ? "white" : "black"}`}
                          backgroundColor={`${!obj.has_read ? "bg-white" : "bg-black"}`} />
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className={"highperformance-news__actions"}>
          <Link to={`/${window.currentLang}/news`}>
            <Button text={"Alle News lesen"} type={"secondary"} isClubButton={true} textColor={"black"} />
          </Link>
        </div>
      </div>
  </>
    );
  }
}

export default AppHighperformanceNews;
