import React, { Component } from "react";
import PropTypes from "prop-types";
import UncontrolledLottie from "../../components/atom/lottie/UncontrolledLottie";
import confetti from "../../assets/lotties/confetti/anim--icon-confetti.json";
import { formatShortDate } from "../../helpers/utils";
import { AppContext } from "../../data/ContextProvider";
import { getContentPage } from "../../data/api";
import { CONTENT_START_SEITE } from "../../data/constants";
import t from "../../helpers/t9n";


class SectionPromise extends Component {

	constructor(props) {
		super(props);
		this.state = {
			data: {},
			openAccordion: "",
			loading: true,
		}
	}

	componentDidMount() {
		getContentPage(CONTENT_START_SEITE, this.context.getUserData().project_id, this.context.getUserData().app_id).then(res => {
			let text = ""
			if (res.text) {
				text = res.text
			} else {
				text = {}
			}

			text.p0_upper_content_headline = (text.p0_upper_content_headline) ? text.p0_upper_content_headline : ""
			text.p0_upper_content_text = (text.p0_upper_content_text) ? text.p0_upper_content_text.replace('{End}', '<strong>' + formatShortDate(this.props.end) + '</strong>') : ""
			// text.p0_upper_content_text = text.p0_upper_content_text.replace('{Bonus}', '<strong>' + this.props.bonus + ' </strong>')
			// text.p0_upper_content_text = text.p0_upper_content_text.replace('{Points}', '<strong>' + this.props.points + '</strong>')

			this.setState({
				text: text,
				loading: false,
			})
		})
	}

	render() {
		if (this.state.loading) {
			return t("global.loading")
		}

		return (
			<div className={"promise__section"}>
				<div className={"promise__counter"}>
						<span className={"counter__icon"}>
							<UncontrolledLottie animationData={confetti} />
						</span>
					<h2>{this.state.text.p0_upper_content_headline}</h2>
					<p
						dangerouslySetInnerHTML={{
							__html: this.state.text.p0_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />')
						}}>
					</p>
				</div>
				<div className="promise-info__container">
					<div className="promise-info__content">
						<div className="bubble bubble-top-left bubble-m-top-center">{t("app.plus")}</div>
						<div className={"item"}>{t("app.bonus.ammount")}</div>
						<span className={"value value-large"}>{Number(this.props.bonus).toLocaleString()} {t("app.currency.icon")}</span>
					</div>
					<div className="promise-info__content">
						<div className={"item"}>{t("app.deadline")}</div>
						<span className={"value"}>{formatShortDate(this.props.end)}</span>
					</div>
				</div>
			</div>
		);
	}
}

SectionPromise.propTypes = {
	end: PropTypes.any,
	bonus: PropTypes.any,
};

SectionPromise.defaultProps = {
	end: 0,
	bonus: 0,
};

SectionPromise.contextType = AppContext

export default SectionPromise;
