import React from "react";
import { useParams } from "react-router-dom";
import Feuerfrei from "./Feuerfrei/Feuerfrei";

const FeuerfreiPage = () => {
  let { lang } = useParams();
  
  return (
    <>
      <Feuerfrei lang={lang} />
    </>
  )
}

export default FeuerfreiPage;
