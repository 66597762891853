import React, { Component } from "react";
import SimplePagination from "../../../components/atom/pagination/SimplePagination";

export default class TableSeller extends Component {
  render() {
    return (
      <>
        <div className="table-seller">
          {/*
          <ul>
            <li>Händlernummer: 123456</li>
            <li>Händlername: Musterstadt GmbH</li>
          </ul>
          */}
          <table className="table">
            <thead>
              <tr>
                <td>Anrede</td>
                <td>Vorname</td>
                <td>Nachname</td>
                <td>E-Mail-Adresse</td>
                <td>Autohaus</td>
              </tr>
            </thead>
            <tbody>
            {this.props.users.map((user, index)=> {
              return <tr key={index}>
                <td>{user.salutation}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.email}</td>
                <td>{user.company}</td>
              </tr>
            })}
            </tbody>
          </table>

        </div>
        <div className="compl__pagination">
          <SimplePagination
            currentPage={this.props.pager.current_page}
            totalPages={this.props.pager.total_pages}
            paginate={this.props.paginate}
          />
        </div>
      </>
    );
  }
}
