import React, { Component } from 'react'
import GloriaRewardsContent from '../Components/GloriaRewardsContent'
import GloriaRewardsHeader from '../Components/GloriaRewardsHeader'

class GloriaRewardsHome extends Component {
  render() {
    return (
      <>
        <GloriaRewardsHeader />
        <GloriaRewardsContent />
      </>
    )
  }
}

export default GloriaRewardsHome;