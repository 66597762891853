import React, { Component } from "react";
import Icon from "../../atom/icons/Icon";
import Button from "../../atom/button/Button";
import { AppContext } from "../../../data/ContextProvider";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import t from "../../../helpers/t9n";
import AppSliderHeader from "../app-slider-header/AppSliderHeader";
import { getAssetSlider } from "../../../data/api";
import AppAkkordeonHeader from "../app-akkordeon-header/AppAkkordeonHeader";

class AppCasinoHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      headerSlider: []
    }
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider('header')

    this.setState({
      headerSlider: headerSlider.slider,
    })
  }

  async componentDidMount() {
    await this.loadData()
  }

  render() {
    if (this.state.loading)
      return t("global.loading")

    const text = this.props.pageText

    return (
      <>
        {this.state.headerSlider.length > 0 && 
          <AppSliderHeader headerSlider={this.state.headerSlider} />
        } 
        
        <div
          className={`app-header__section casino-header__section ${this.props.isHeaderOpen ? "app-header__section--open" : ""}`}>
          <video loop autoPlay>
            <source
              src={`${process.env.PUBLIC_URL}/videos/Casino/vid--casino.mp4`}
              type="video/mp4" />{t("app.video.no.support")}
          </video>
          <div className={"app-header__content"}>
            <div className={"app-header__left"}>
              <span className={"app-header__heading"}>
                <div>
                  <span>
                    <Icon icon={"app-praemiencasino"} />
                  </span>
                  <h1>{text.p33_header_content_headline}</h1>
                </div>
                <h2>{text.p33_header_content_subline}</h2>
              </span>

              <p
                dangerouslySetInnerHTML={{
                  __html: text.p33_upper_content_text ? text.p33_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
                }}>
              </p>

              <span className={"app-header__actions"}>
                {this.props.isHeaderOpen ?
                  <Button type={"link"} text={t("global.close.info")} textColor={"white"} icon={"arrow"}
                          onClick={() => this.props.handleHeader()} />
                  :
                  <Button type={"link"} text={t("global.open.info")} textColor={"white"} icon={"arrow"}
                          onClick={() => this.props.handleHeader()} />
                }
                <Button type={"secondary"} text={t("global.rules")} textColor={"black"} backgroundColor={"bg-white"}
                        onClick={() => {
                          this.props.history.push(`/${window.currentLang}/rules`)
                        }} />
              </span>

              <div className={"casino-room__slots-wrapper"}>
                <div className={"casino-room__slots-available"}>
                  <svg className={"casino-room__dotted-circle"} viewBox="0 0 100 100">
                    <circle cx="50" cy="50" strokeDasharray=".001, 8" strokeWidth="4" fill="none"
                            strokeLinecap="round" stroke="white" r="48" />
                  </svg>
                  <div className={navigator.userAgent.indexOf('Mac') > 0 ? "casino-room__slots casino-room__slots--mac" : "casino-room__slots"}>
                    <h1>{this.props.slots}</h1>
                  </div>
                </div>
                <div className={"casino-room__slots-lower"}>
                  <div className={"casino-room__slots-headline"}>
                    <h2>{t("app.available.games")}</h2>
                  </div>
                  <div className={"casino-room__slots-subline"}>
                    <p>{t("app.equals.closed.contracts")}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      {this.context.user.welcome_tool_enabled &&
        <AppAkkordeonHeader />
      }
      </>
    );
  }
}

AppCasinoHeader.propTypes = {
  handleHeader: PropTypes.func,
  isHeaderOpen: PropTypes.bool,
};
AppCasinoHeader.defaultProps = {
  isHeaderOpen: false,
};

AppCasinoHeader.contextType = AppContext

export default withRouter(AppCasinoHeader);
