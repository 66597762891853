import React, { useContext, useEffect, useState } from "react";
import { getContentPage } from "../../data/api";
import { AppContext } from "../../data/ContextProvider";
import Header from "../../components/element/header-section/HeaderSection";
import t from "../../helpers/t9n";

const RulesPage = () => {

  const context = useContext(AppContext)
  const [data, setData] = useState({});
  const [pId] = useState(33);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userData = context.getUserData()

    getContentPage(pId, userData.project_id).then(page => {
      setData(page.text)
      setLoading(false)
    })
  }, ([pId, context]))

  if (loading)
    return "Loading.."

  return (
    <>
      <Header heading={t("global.rules.short")} hasImage={true} isRed={true} />
      <div className={"content"}>
        <div className={"content--rules"}>

          <p
            dangerouslySetInnerHTML={{
              __html: data["p" + pId + "_upper_content_text"] ? data["p" + pId + "_upper_content_text"].replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
            }}>
          </p>

          <p
            dangerouslySetInnerHTML={{
              __html: data["p" + pId + "_lower_content_text"] ? data["p" + pId + "_lower_content_text"].replace(/(?:\r\n|\r|\n)/g, '<br />') : ""
            }}>
          </p>

        </div>
      </div>
    </>
  );
}

export default RulesPage;
