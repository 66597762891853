import React, { Component } from 'react'
import AppAkkordeonHeader from '../../../components/module/app-akkordeon-header/AppAkkordeonHeader'
import AppSliderHeader from '../../../components/module/app-slider-header/AppSliderHeader'
import { getAssetSlider } from '../../../data/api';
import t from '../../../helpers/t9n';
import GloriaRewardsIntro from './GloriaRewardsIntro';

class GloriaRewardsHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      headerSlider: [],
    };
  }

  loadData = async () => {
    const headerSlider = await getAssetSlider("header");

    this.setState({
      headerSlider: headerSlider.slider,
    });
  };

  async componentDidMount() {
    await this.loadData();
  }

  render() {
    if (this.state.loading) return t("global.loading");

    return (
      <>
        <AppSliderHeader headerSlider={this.state.headerSlider} />
        <GloriaRewardsIntro />
      </>
    )
  }
}

export default GloriaRewardsHeader;