import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import UncontrolledLottie from "../../atom/lottie/UncontrolledLottie";
import confetti from "../../../assets/lotties/confetti/anim--icon-confetti.json";
import { AppContext } from "../../../data/ContextProvider";
import { getContentPage } from "../../../data/api";
import { CONTENT_INHALTS_SEITE } from "../../../data/constants";
// import { formatShortDate } from "../../../helpers/utils";
import t from "../../../helpers/t9n";


class AppTeamTrophyProfit extends Component {

  constructor(props) {
		super(props);
		this.state = {
			data: {},
			openAccordion: "",
			loading: true,
		}
	}

	componentDidMount() {
		getContentPage(CONTENT_INHALTS_SEITE, this.context.getUserData().project_id, this.context.getUserData().app_id).then(res => {
			let text = ""
			if (res.text) {
				text = res.text
			} else {
				text = {}
			}

			text.p1_header_img_1 = (text.p1_header_img_1) ? text.p1_header_img_1 : ""
			text.p1_upper_content_headline = (text.p1_upper_content_headline) ? text.p1_upper_content_headline : ""
			text.p1_upper_content_text = (text.p1_upper_content_text) ? text.p1_upper_content_text : ""

			this.setState({
				text: text,
				loading: false,
			})
		})
	}


  render() {
		if (this.state.loading) {
			return t("global.loading")
		}

    return (
      <>
				<div className={"profit-section"}>
					<div className="col profit-section__image">
              {/* <img src={`url(${API_HOST}/asset/${this.state.text["p1_header_img_1"]}?pid=${this.context.user.project_id})`} /> */}
              <span className={"profit-section__icon"}>
                <UncontrolledLottie animationData={confetti} />
              </span>
					</div>
					<div className="col profit-section__text">

					<h3>{this.state.text.p1_upper_content_headline}</h3>
					<p
						dangerouslySetInnerHTML={{
							__html: this.state.text.p1_upper_content_text.replace(/(?:\r\n|\r|\n)/g, '<br />')
						}}>
					</p>
					</div>
				</div>
			</>
    );
  }
}

AppTeamTrophyProfit.contextType = AppContext

export default withRouter(AppTeamTrophyProfit);
